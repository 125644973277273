import React from 'react';
import Container from '@material-ui/core/Container';
import ErrorComponent from './components/ErrorComponent';
import Footer from './components/Footer';
import Login from './components/Login';
import Navbar from './components/Navbar';
import Spinner from './components/Spinner';
import { ErrorHandlerProvider } from './services/ErrorHandler';
import { switchByRole } from './Switches';
import useStyle from './appStyle';
import { useGeneralContext } from './providers/GeneralContext';

const NotAuthView = () => {
  const title = 'No autorizado';
  const description = (
    <span>
      Esta aplicación es accesible únicamente para Graduados de Henry. <br />{' '}
      ¿Aún no eres parte? Conocenos en{' '}
      <a
        style={{
          color: 'black',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        href="https://soyhenry.com"
        target="_blank"
        rel="noreferrer"
      >
        soyhenry.com
      </a>
    </span>
  );
  return <ErrorComponent title={title} description={description} />;
};

function App() {
  const classes = useStyle();

  const { selectedRole, isLoading, isAuthenticated, isBackoffice, isGraduate } =
    useGeneralContext();

  const Switch = switchByRole[selectedRole];
  return (
    <ErrorHandlerProvider>
      {isAuthenticated ? (
        <>
          <Navbar />
          <Container
            maxWidth={false}
            disableGutters
            className={classes.containerAndFooter}
          >
            <Container maxWidth="xl" className={classes.container}>
              {/* selectedRole late more to change than isBackoffice or isGraduate */}
              {/* I am sure about that if isGraduate or isBackoffice, selectedRole will have a value, and the second comparation will be true */}
              {/* Without this, shows 'No autorizado' per a few ms */}
              {isLoading || ((isBackoffice || isGraduate) && !selectedRole) ? (
                <Spinner />
              ) : selectedRole ? (
                <Switch />
              ) : (
                <NotAuthView />
              )}
            </Container>
            <Footer />
          </Container>
        </>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <Login />
      )}
    </ErrorHandlerProvider>
  );
}

export default App;
