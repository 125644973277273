import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BackofficeDashboard from '../components/BackofficeDashboard';
import DDJJForm from '../components/DDJJForm';
import DDJJReview from '../components/DDJJReview';
import DetailPanel from '../components/DetailPanel';
import ErrorComponent from '../components/ErrorComponent';
import GraduateDashboard from '../components/GraduateDashboard';
import Profile from '../components/Profile';
import Spinner from '../components/Spinner';
import { useGetUserInfo } from '../services/collections.service';
import { Role } from '../common/enums';
import { useGeneralContext } from '../providers/GeneralContext';

const ErrorRoute = () => (
  <Route
    component={() => (
      <ErrorComponent
        title="404"
        description="No pudimos encontrar esta página"
      />
    )}
  />
);

export const GraduateSwitch = () => {
  const { isModal, previousLocation, location } = useGeneralContext();
  // I want to use useGraduateContext, but this makes infinite loop getting graduate table
  // TODO: implement better cache service to not have to repeat this request
  const { error: errorGraduateData, loading: loadingGraduateData } =
    useGetUserInfo();

  const modalRoutes = [
    <Route exact path="/declaration/:id/form" component={DDJJForm} />,
    <Route exact path="/declaration/:id" component={DetailPanel} />,
  ];

  if (loadingGraduateData) {
    return <Spinner />;
  }

  if (errorGraduateData) {
    return (
      <>
        {errorGraduateData?.response?.status === 401 ? (
          <ErrorComponent
            title="No autorizado"
            description="No encontramos tu perfil de Graduado."
          />
        ) : (
          <ErrorComponent
            title="Error"
            description="No pudimos cargar tu perfil de Graduado."
          />
        )}
      </>
    );
  }

  return (
    <>
      <Switch location={isModal ? previousLocation : location}>
        <Route exact={!isModal} path="/" component={GraduateDashboard} />
        <Route exact={!isModal} path="/profile" component={Profile} />
        {!isModal && modalRoutes.map((Route) => Route)}
        <ErrorRoute />
      </Switch>
      {isModal && modalRoutes.map((Route) => Route)}
    </>
  );
};

export const BackofficeSwitch = () => {
  return (
    <Switch>
      <Route exact path="/" component={BackofficeDashboard} />
      <Route exact path="/declaration/:id/review" component={DDJJReview} />
      <ErrorRoute />
    </Switch>
  );
};

export const switchByRole = {
  [Role.graduate]: GraduateSwitch,
  [Role.backoffice]: BackofficeSwitch,
};
