import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import { Input, InputProps } from '@soyhenry/commons';
import { DeclarationType } from '../../common/enums';

export default function MobileNewDeclaration({
  onChange,
  onSubmit,
  onClose,
  submitDisabled,
}: {
  onChange: InputProps['onChange'];
  onSubmit: () => void;
  onClose: () => void;
  submitDisabled: boolean;
}): JSX.Element {
  return (
    <Paper>
      <Box
        width="100%"
        height="100%"
        p={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box mb={0.5} width="100%" display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box mb={2} textAlign="center">
          <Typography variant="h6" style={{ fontSize: 16 }}>
            ¿Que tipo de declaración realizarás?
          </Typography>
        </Box>
        <Input
          type="buttons"
          name="declarationType"
          onChange={onChange}
          allowDeselect
          options={[
            {
              text: 'Nuevo empleo',
              value: DeclarationType.NEW_EMPLOYMENT,
            },
            {
              text: 'Baja de empleo',
              value: DeclarationType.UNEMPLOYMENT,
            },
          ]}
        />
        <Box mt={3} display="flex" width="100%" justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ padding: 8 }}
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            Crear declaración
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
