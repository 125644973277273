import { createStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lastDeclarationsContainer: {
      width: '100%',
      overflowY: 'auto',
      minHeight: '300px',
      height: 'calc(100vh - 550px)',
    },
  })
);

export default useStyles;
