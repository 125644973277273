import { createStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes up': {
      '0%': { transform: 'translateY(0)', opacity: 1 },
      '50%': { transform: 'translateY(-10%)', opacity: 0 },
      '51%': { transform: 'translateY(10%)', opacity: 0 },
      '100%': { transform: 'translateY(0)', opacity: 1 },
    },
    '@keyframes down': {
      '0%': { transform: 'translateY(0)', opacity: 1 },
      '50%': { transform: 'translateY(10%)', opacity: 0 },
      '51%': { transform: 'translateY(-10%)', opacity: 0 },
      '100%': { transform: 'translateY(0)', opacity: 1 },
    },
    up: {
      animation: '$up 300ms',
      overflow: 'hidden',
    },
    down: {
      animation: '$down 300ms',
      overflow: 'hidden',
    },
  })
);

export default useStyles;
