// where is JSSFontface type?
export const fontsAvenir: any[] = [
  {
    fontFamily: 'Avenir Black',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
       url('https://henry-social-resources.s3-sa-east-1.amazonaws.com/fonts/Avenir/Avenir-Black-03-968e784956ff9b6a05c29cc3be18cad0.ttf')
       format('truetype');
       `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  },
  {
    fontFamily: 'Avenir Heavy',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
       url('https://henry-social-resources.s3-sa-east-1.amazonaws.com/fonts/Avenir/Avenir-Heavy-05-ff80b6894f8375c59a197caa24aac91c.ttf')
       format('truetype');
      `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  },
  {
    fontFamily: 'Avenir Book',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
        url('https://henry-social-resources.s3-sa-east-1.amazonaws.com/fonts/Avenir/Avenir-Book-01-995b34ab56b423abffe48e64bf8b8df1.ttf')
        format('truetype');
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  },
  {
    fontFamily: 'Avenir Light',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
      url('https://henry-social-resources.s3-sa-east-1.amazonaws.com/fonts/Avenir/Avenir-Light-07-33f6570a328d84cb7d06e829a0c7ca1b.ttf')
      format('truetype');
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  },
  {
    fontFamily: 'Avenir Medium',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
      url('https://henry-social-resources.s3-sa-east-1.amazonaws.com/fonts/Avenir/Avenir-Medium-09-7d635ea94b4460e86cd678e9e754a643.ttf')
      format('truetype');
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  },
  {
    fontFamily: 'Avenir Roman',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
      url('https://henry-social-resources.s3-sa-east-1.amazonaws.com/fonts/Avenir/Avenir-Roman-12-c6d37b26297461a78d10f169dd5cf46d.ttf')
      format('truetype');
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  },
];
