import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ImageIcon from '@material-ui/icons/ImageOutlined';

function IncomeDocumentButton({
  onClick,
  disabled,
  tooltip,
}: {
  onClick: () => void;
  disabled: boolean;
  tooltip: string;
}) {
  return (
    <Tooltip arrow title={tooltip}>
      <span>
        <IconButton disabled={disabled} onClick={onClick}>
          <ImageIcon style={{ fontSize: '2rem' }} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default IncomeDocumentButton;
