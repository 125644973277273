import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Step } from 'react-joyride';
import { markAsSeenTour } from '../../../services/collections.service';
import { useAuth } from '../../../providers/Auth';
import { useGraduateContext } from '../../../providers/GraduateContext';
import CustomJoyride from '../../CustomJoyride';

export default function GraduateDashboardTour() {
  const { getAccessToken } = useAuth();
  const {
    handleRunJoyride,
    runJoyride,
    graduateData,
    refreshGraduateData,
    declarations,
  } = useGraduateContext();

  const steps: Step[] = [
    {
      target: '#declarations-table',
      content: (
        <Typography>
          <strong>Esta es la Tabla de Tus Declaraciones</strong>
          <br /> Aquí podras ver la lista de tus declaraciones
        </Typography>
      ),
      placementBeacon: 'top',
    },
    {
      target: '.MuiTableBody-root .MuiTableRow-root',
      content: (
        <Typography>
          <strong>Tu declaración se ve así</strong>
          <br /> Todos los meses te acercamos una nueva declaración vacía para
          que nos cuentes como continúa tu situación
        </Typography>
      ),
      placementBeacon: 'top',
    },
    {
      target: '#access-declaration',
      content: (
        <Typography>
          Podrás completarlas, ver sus correcciones u observaciones
        </Typography>
      ),
      placementBeacon: 'top',
    },
    {
      target: '#new-declaration-button',
      content: (
        <Typography>
          <strong>Con el Botón de Empleo Nuevo</strong>
          <br /> Podrás declarar un nuevo empleo
        </Typography>
      ),
      placementBeacon: 'top-end',
    },
    {
      target: '#profile-avatar',
      content: (
        <Typography>
          <strong>Aquí podrás acceder a los datos de tu perfil</strong>
          <br /> Te pedimos que por favor completes los datos faltantes.
        </Typography>
      ),
      placementBeacon: 'top',
    },
    {
      target: '#expandNotifications',
      content: (
        <Typography>
          <strong>Esta es tu campana de notificaciones</strong>
          <br /> si ocurre algo nuevo con respecto a tus declaraciones o tu
          situación laboral, serás notificado aquí
        </Typography>
      ),
      placementBeacon: 'top',
    },
  ];

  return (
    <CustomJoyride
      onClose={() => {
        handleRunJoyride({ name: 'graduateDashboard', run: false });
        if (!graduateData.tours.graduateDashboard) {
          markAsSeenTour(getAccessToken, 'graduateDashboard').then(
            refreshGraduateData
          );
        }
      }}
      run={
        !graduateData.tours?.graduateDashboard || runJoyride.graduateDashboard
      }
      steps={
        declarations.length
          ? steps
          : steps.filter(
              (step) =>
                step.target !== '.MuiTableBody-root .MuiTableRow-root' &&
                step.target !== '#access-declaration'
            )
      }
    />
  );
}
