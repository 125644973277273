import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FiltersPanel from './FiltersPanel';
import Table from './TableComponent';

function BackofficeDashboard() {
  return (
    <Box height="100%" width="100%" alignItems="center" mt={4}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <FiltersPanel />
        </Grid>
        <Grid item xs={10}>
          <Table />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BackofficeDashboard;
