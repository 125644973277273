import React, { CSSProperties } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { DeclarationStatus } from '../../../common/enums';
import Edit from '../../../icons/Edit';
import SeeDocument from '../../../icons/SeeDocument';
import WithTooltip from '../../WithTooltip';

const fillDeclarationTooltip = 'Llenar DJ';
const seeDeclarationTooltip = 'Ver más';

const iconByStatus = {
  [DeclarationStatus.ACCEPTED]: <SeeDocument />,
  [DeclarationStatus.ACCEPTED_WITH_CORRECTIONS]: <SeeDocument />,
  [DeclarationStatus.IN_REVIEW]: <SeeDocument />,
  [DeclarationStatus.PRESENTED]: <SeeDocument />,
  [DeclarationStatus.RESUBMITTED]: <SeeDocument />,
  [DeclarationStatus.DRAFT]: <Edit />,
  [DeclarationStatus.EMPTY]: <Edit />,
  [DeclarationStatus.ACCEPTED_WITH_PENDINGS]: <Edit />,
  [DeclarationStatus.REJECTED]: <Edit />,
};

const tooltipByStatus = {
  [DeclarationStatus.ACCEPTED]: seeDeclarationTooltip,
  [DeclarationStatus.ACCEPTED_WITH_CORRECTIONS]: seeDeclarationTooltip,
  [DeclarationStatus.IN_REVIEW]: seeDeclarationTooltip,
  [DeclarationStatus.PRESENTED]: seeDeclarationTooltip,
  [DeclarationStatus.RESUBMITTED]: seeDeclarationTooltip,
  [DeclarationStatus.DRAFT]: fillDeclarationTooltip,
  [DeclarationStatus.EMPTY]: fillDeclarationTooltip,
  [DeclarationStatus.ACCEPTED_WITH_PENDINGS]: fillDeclarationTooltip,
  [DeclarationStatus.REJECTED]: fillDeclarationTooltip,
};

function ActionIcon({
  id,
  status,
  customIcon,
  iconButtonStyle,
  customTooltip,
  disabled,
  onClick,
}: {
  id?: string;
  status?: DeclarationStatus;
  customIcon?: React.ReactNode;
  customTooltip?: string | React.ReactNode;
  iconButtonStyle?: CSSProperties;
  disabled?: boolean;
  onClick: () => void;
}) {
  const tooltip = disabled
    ? 'No hay datos necesarios en este tipo de DJ'
    : customTooltip || tooltipByStatus[status];
  return (
    <WithTooltip tooltip={tooltip} withTooltip>
      <IconButton
        id={id}
        disabled={disabled}
        style={iconButtonStyle}
        onClick={onClick}
      >
        {customIcon || iconByStatus[status]}
      </IconButton>
    </WithTooltip>
  );
}

export default ActionIcon;
