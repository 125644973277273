import React from 'react';
import Box from '@material-ui/core/Box';
import { Input } from '@soyhenry/commons';
import { declarationTypeNames, useQuery } from '../../../../common/functions';
import { DeclarationType } from '../../../../common/enums';
import FilterLabel from '../FilterLabel';

export default function TypeFilter() {
  const { query, changeQuery } = useQuery();

  return (
    <Box mb={0.5}>
      <FilterLabel>Tipo DJ</FilterLabel>
      <Input
        type="select"
        variant="outlined"
        style={{ width: '100%' }}
        InputProps={{ style: { borderRadius: 10 } }}
        name="type"
        options={Object.values(DeclarationType).map((type) => ({
          text: declarationTypeNames[type],
          value: type,
        }))}
        value={query.type || ''}
        onChange={({ value }) =>
          changeQuery({
            name: 'type',
            value: String(value),
          })
        }
      />
    </Box>
  );
}
