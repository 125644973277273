import React from 'react';
import Box from '@material-ui/core/Box';
import { useGetCountResume } from '../../../../services/collections.service';
import { useQuery } from '../../../../common/functions';
import { DeclarationStatus } from '../../../../common/enums';
import FilterLabel from '../FilterLabel';
import RadioItem from '../RadioItem';
import RadioList from '../RadioList';

export default function StatusFilter() {
  const { query, changeQuery } = useQuery();
  const { data: countResume } = useGetCountResume();

  return (
    <Box mb={0.5}>
      <FilterLabel>Status</FilterLabel>
      <RadioList>
        <RadioItem
          text={`Presentada (${
            countResume?.[DeclarationStatus.PRESENTED] || 0
          })`}
          value={query.status === DeclarationStatus.PRESENTED}
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !== DeclarationStatus.PRESENTED
                  ? DeclarationStatus.PRESENTED
                  : undefined,
            })
          }
        />
        <RadioItem
          text={`Presentada corregida (${
            countResume?.[DeclarationStatus.RESUBMITTED] || 0
          })`}
          value={query.status === DeclarationStatus.RESUBMITTED}
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !== DeclarationStatus.RESUBMITTED
                  ? DeclarationStatus.RESUBMITTED
                  : undefined,
            })
          }
        />
        <RadioItem
          text={`En revisión (${
            countResume?.[DeclarationStatus.IN_REVIEW] || 0
          })`}
          value={query.status === DeclarationStatus.IN_REVIEW}
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !== DeclarationStatus.IN_REVIEW
                  ? DeclarationStatus.IN_REVIEW
                  : undefined,
            })
          }
        />
        <RadioItem
          text={`Aceptada (${countResume?.[DeclarationStatus.ACCEPTED] || 0})`}
          value={query.status === DeclarationStatus.ACCEPTED}
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !== DeclarationStatus.ACCEPTED
                  ? DeclarationStatus.ACCEPTED
                  : undefined,
            })
          }
        />
        <RadioItem
          text={`Aceptada con pendientes (${
            countResume?.[DeclarationStatus.ACCEPTED_WITH_PENDINGS] || 0
          })`}
          value={query.status === DeclarationStatus.ACCEPTED_WITH_PENDINGS}
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !== DeclarationStatus.ACCEPTED_WITH_PENDINGS
                  ? DeclarationStatus.ACCEPTED_WITH_PENDINGS
                  : undefined,
            })
          }
        />
        <RadioItem
          text={`Aceptada con corrección (${
            countResume?.[DeclarationStatus.ACCEPTED_WITH_CORRECTIONS] || 0
          })`}
          value={query.status === DeclarationStatus.ACCEPTED_WITH_CORRECTIONS}
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !== DeclarationStatus.ACCEPTED_WITH_CORRECTIONS
                  ? DeclarationStatus.ACCEPTED_WITH_CORRECTIONS
                  : undefined,
            })
          }
        />
        <RadioItem
          text={`Rechazada (${countResume?.[DeclarationStatus.REJECTED] || 0})`}
          value={query.status === DeclarationStatus.REJECTED}
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !== DeclarationStatus.REJECTED
                  ? DeclarationStatus.REJECTED
                  : undefined,
            })
          }
        />
        <RadioItem
          text={`No presentadas (${
            (countResume?.[DeclarationStatus.EMPTY] || 0) +
            (countResume?.[DeclarationStatus.DRAFT] || 0)
          })`}
          value={
            query.status ===
            `${DeclarationStatus.EMPTY},${DeclarationStatus.DRAFT}`
          }
          onChange={() =>
            changeQuery({
              name: 'status',
              value:
                query.status !==
                `${DeclarationStatus.EMPTY},${DeclarationStatus.DRAFT}`
                  ? `${DeclarationStatus.EMPTY},${DeclarationStatus.DRAFT}`
                  : undefined,
            })
          }
        />
      </RadioList>
    </Box>
  );
}
