import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DeclarationType } from '../../common/enums';

const titles = {
  newEmployment: 'Confirma tu nuevo empleo',
  unemployment: 'Confirma la baja de tu empleo',
  default: 'Confirma tu declaración jurada',
};

const descriptions = {
  default: (
    <span>
      Asegurate de que todos los datos sean correctos. <br /> Una vez que la
      presentes no podrás volver a modificarla.
    </span>
  ),
  stayUnemployment:
    'Harás una declaración jurada indicando que continúas desempleado.',
};

const titleByType: { [x in DeclarationType]?: string } = {
  [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: titles.newEmployment,
  [DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT]: titles.unemployment,
  [DeclarationType.NEW_EMPLOYMENT]: titles.newEmployment,
  [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]: titles.default,
  [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]: titles.default,
  [DeclarationType.STAY_UNEMPLOYMENT]: titles.default,
  [DeclarationType.UNEMPLOYMENT]: titles.unemployment,
  // this type never has a final confirm
  [DeclarationType.STAY_EMPLOYED]: null,
};

const descriptionByType: { [x in DeclarationType]?: string | React.ReactNode } =
  {
    [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: descriptions.default,
    [DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT]: descriptions.default,
    [DeclarationType.NEW_EMPLOYMENT]: descriptions.default,
    [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]: descriptions.default,
    [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]: descriptions.default,
    [DeclarationType.STAY_UNEMPLOYMENT]: descriptions.stayUnemployment,
    [DeclarationType.UNEMPLOYMENT]: descriptions.default,
    // this type never has a final confirm
    [DeclarationType.STAY_EMPLOYED]: null,
  };

function Header({ declarationType }: { declarationType: DeclarationType }) {
  return (
    <>
      <Box mb={1}>
        {(props) => (
          <Typography variant="h5" {...props}>
            {titleByType[declarationType]}
          </Typography>
        )}
      </Box>
      <Typography variant="subtitle1" color="textSecondary">
        {descriptionByType[declarationType]}
      </Typography>
    </>
  );
}

export default Header;
