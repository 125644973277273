import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import removeCookies from '../../utils/removeCookies';
import {
  AuthContext,
  AuthState,
  DecodedAccessToken,
  DecodedIdToken,
} from './types';

export * from './types';

const Context = createContext<AuthContext | undefined>(undefined);

export interface AuthProviderProps {
  children: ReactNode;
  authUrl: string;
  redirectUri?: string;
}

export const INITIAL_STATE: AuthState = {
  isLoading: true,
  isAuthenticated: false,
  accessTokenExpiration: null,
  accessToken: null,
  idToken: null,
  user: null,
};

const AUTH = {
  URL: process.env.REACT_APP_AUTH_URL,
  EXPIRATION_MULTIPLIER: 1000,
  REDIRECT_PARAM: 'redirect_url',
  LOGIN_PATH: '/',
};

export function AuthProvider({
  children,
  authUrl,
  redirectUri,
}: AuthProviderProps) {
  const [state, setState] = useState<AuthState>({
    isLoading: true,
    isAuthenticated: false,
    accessTokenExpiration: null,
    accessToken: null,
    idToken: null,
    user: null,
  });

  useEffect(() => {
    const cookiesCredentials = {
      accessToken: cookies.get('accessToken'),
      idToken: cookies.get('idToken'),
    };

    const decodedAccessToken = cookiesCredentials.accessToken
      ? jwtDecode<DecodedAccessToken>(cookiesCredentials.accessToken)
      : null;
    const decodedIdToken = cookiesCredentials.idToken
      ? jwtDecode<DecodedIdToken>(cookiesCredentials.idToken)
      : null;

    const accessTokenExpiration =
      decodedAccessToken &&
      new Date(decodedAccessToken.exp * AUTH.EXPIRATION_MULTIPLIER);

    if (
      !cookiesCredentials.accessToken ||
      !cookiesCredentials.idToken ||
      !accessTokenExpiration ||
      new Date() > accessTokenExpiration
    ) {
      setState({ ...INITIAL_STATE, isLoading: false });
    } else {
      setState({
        isLoading: false,
        isAuthenticated: true,
        accessTokenExpiration,
        accessToken: cookiesCredentials.accessToken,
        idToken: cookiesCredentials.idToken,
        user: decodedIdToken,
      });
    }
  }, []);

  const logIn: AuthContext['logIn'] = useCallback(
    (options: { redirectUri: string }) => {
      const loginUrl = new URL('/', AUTH.URL);

      const redirect =
        options?.redirectUri || redirectUri || window.location.href;
      loginUrl.searchParams.append(AUTH.REDIRECT_PARAM, redirect);
      window.location.replace(loginUrl.toString());
    },
    [redirectUri]
  );

  const logOut: AuthContext['logIn'] = useCallback(() => {
    setState({ ...INITIAL_STATE, isLoading: false });
    removeCookies();
  }, [setState]);

  const getAccessToken: AuthContext['getAccessToken'] = useCallback(() => {
    if (
      !state.accessToken ||
      !state.accessTokenExpiration ||
      new Date() > state.accessTokenExpiration
    ) {
      return Promise.resolve(null);
    }
    return Promise.resolve(state.accessToken);
  }, [state]);

  return (
    <Context.Provider
      value={{
        isLoading: state.isLoading,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        logIn,
        logOut,
        getAccessToken,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useAuth = (): AuthContext => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useAuth has to be inside a AuthProvider');
  }
  return context;
};
