import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    logoutButton: {
      width: '100%',
      justifyContent: 'left',
    },
    missingProfileDataIcon: {
      fill: theme.palette.error.main,
      marginTop: 5,
    },
    exitIcon: {
      marginRight: '1rem',
    },
  })
);
