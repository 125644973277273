import React from 'react';
import { TypographyProps } from '@material-ui/core/Typography';
import { FieldValue } from '../QuestionItem/interfaces';

export interface UpdateInputProps {
  value: FieldValue;
  questionName: string;
  defaultValue?: FieldValue;
  remoteOptions?: {
    [questionName: string]: { text: string; value?: any }[];
  };
  error?: string;
  label?: string;
  tooltip?: string | React.ReactNode;
  tooltipIcon?: React.ReactNode;
  onChange: (value) => void;
}

export enum ReviewValue {
  NOT_SELECTED = 'notSelected',
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
}

export interface UpdatableFieldProps extends FieldProps, UpdateInputProps {
  isCorrectionOpen: boolean;
}

export interface UpdateFieldButtonsProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export interface FieldProps {
  value: FieldValue;
  label?: string;
  tooltip?: string | React.ReactNode;
  tooltipIcon?: React.ReactNode;
  editable?: boolean;
  onClickEdit?: () => void;
  color?: TypographyProps['color'];
}
