import React from 'react';
import Box from '@material-ui/core/Box';
import { Input, InputProps, Typography } from '@soyhenry/commons';

const Item = ({
  title,
  value,
  edit,
}: {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
  edit?: {
    isEditing: boolean;
    onChange: InputProps['onChange'];
    name: string;
    label: string;
    value: string | number;
    type: InputProps['type'];
    error: InputProps['error'];
    options?: InputProps['options'];
    disableNewOptions?: InputProps['disableNewOptions'];
  };
}) => {
  return (
    <>
      {edit && edit.isEditing ? (
        <Box mt={1.5}>
          <Input {...edit} variant="outlined" fullWidth />
        </Box>
      ) : (
        <>
          <Typography
            style={{ fontSize: 16 }}
            variant="body"
            color="textSecondary"
          >
            {title}:
          </Typography>
          <Box>
            <Typography variant="h5" style={{ fontSize: 17 }}>
              {value || '-'}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default Item;
