import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import NavigateNext from '@material-ui/icons/NavigateNext';

function Controller({
  page,
  totalPages,
  onBackPage,
  onNextPage,
}: {
  page: number;
  totalPages: number;
  onBackPage: () => void;
  onNextPage: () => void;
}) {
  return (
    <Box display="flex" justifyContent="space-between">
      <IconButton disabled={page === 1} onClick={onBackPage}>
        <ArrowBackIos />
      </IconButton>
      <p>
        {page} de {totalPages}
      </p>
      <IconButton disabled={page === totalPages} onClick={onNextPage}>
        <NavigateNext />
      </IconButton>
    </Box>
  );
}

export default Controller;
