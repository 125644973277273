import React from 'react';
import { Redirect } from 'react-router-dom';
import { Wizard } from '@soyhenry/commons';
import moment from 'moment';
import useErrorHandler from '../../services/ErrorHandler';
import { useGetFlowSteps } from './functions';
import {
  DeclarationStatus,
  DeclarationType,
  GraduateStatus,
} from '../../common/enums';
import { DeclarationInstance, Graduate } from '../../common/interfaces';
import { useGraduateContext } from '../../providers/GraduateContext';
import countries from '../../utils/countries';
import Spinner from '../Spinner';
import Stepper from '../Stepper';
import { useSteps, initialStepByStatus } from './steps';

function Form({
  declaration,
  forceStartStep,
  userInfo,
  onSubmit,
  onNextStep,
  onPreviousStep,
  onGetContinueStep,
  onClose,
}: {
  declaration: DeclarationInstance;
  forceStartStep?: string;
  userInfo: Graduate;
  onSubmit: (step: string, values: unknown) => void;
  onNextStep: (step: string, values: unknown) => void;
  onPreviousStep: (step: string, values: unknown) => void;
  onGetContinueStep: (step: string) => void;
  onClose?: () => void;
}) {
  const { apiCatcher } = useErrorHandler();
  const steps = useSteps();
  const { getFlowSteps } = useGetFlowSteps();

  const {
    DDJJContext,
    loadingDDJJContext: loading,
    errorDDJJContext: error,
  } = useGraduateContext();

  const isRejectedStayUnemploymentType =
    declaration?.reviewData?.status === DeclarationStatus.REJECTED &&
    declaration?.reviewData?.type === DeclarationType.STAY_UNEMPLOYMENT;

  // when there are forceStartStep, graduate is declaring a new job and don't need last declaration data in hidden values
  const needLastDeclarationData =
    forceStartStep ||
    declaration.type === DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT
      ? false
      : ![
          DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT,
          DeclarationType.UNEMPLOYMENT,
          DeclarationType.STAY_UNEMPLOYMENT,
        ].includes(userInfo?.lastDeclarations?.[0]?.type);

  const lastDeclaration: DeclarationInstance =
    userInfo?.status === GraduateStatus.MULTIEMPLOYMENT
      ? userInfo?.lastDeclarations[declaration?.declaredData?.companyName]
      : userInfo?.lastDeclarations[Object.keys(userInfo?.lastDeclarations)[0]];

  const latestValues = needLastDeclarationData
    ? {
        incomeAmount:
          lastDeclaration?.reviewData?.incomeAmount?.correction ||
          lastDeclaration?.declaredData?.incomeAmount,
        incomeCurrency:
          lastDeclaration?.reviewData?.incomeCurrency?.correction ||
          lastDeclaration?.declaredData?.incomeCurrency,
        contactEmail:
          lastDeclaration?.reviewData?.contactEmail?.correction ||
          lastDeclaration?.declaredData?.contactEmail,
        contactName:
          lastDeclaration?.reviewData?.contactName?.correction ||
          lastDeclaration?.declaredData?.contactName,
        companyName:
          lastDeclaration?.reviewData?.companyName?.correction ||
          lastDeclaration?.declaredData?.companyName,
        companyCountry:
          lastDeclaration?.reviewData?.companyCountry?.correction ||
          lastDeclaration?.declaredData?.companyCountry,
        modality:
          lastDeclaration?.reviewData?.modality?.correction ||
          lastDeclaration?.declaredData?.modality,
        typeOfService:
          lastDeclaration?.reviewData?.typeOfService?.correction ||
          lastDeclaration?.declaredData?.typeOfService,
        startDate:
          lastDeclaration?.reviewData?.startDate?.correction ||
          lastDeclaration?.declaredData?.startDate,
        incomeDocumentType:
          lastDeclaration?.reviewData?.incomeDocumentType?.correction ||
          lastDeclaration?.declaredData?.incomeDocumentType,
      }
    : {};

  const hiddenValues = {
    id: declaration?.id,
    type: declaration?.type,
    periodId: declaration?.period?.id,
    period: declaration?.period,
    lastCompanyName:
      lastDeclaration?.company?.name ||
      lastDeclaration?.declaredData?.companyName,
    periods: DDJJContext?.period,
    ...latestValues,
  };

  const getContinueStep = () => {
    let continueStep;
    if (isRejectedStayUnemploymentType) {
      continueStep = 'notDeclaredFirstStep';
    } else if (declaration?.declaredData && userInfo) {
      const flowSteps = getFlowSteps(userInfo.status, declaration.type);
      const uncompletedStep =
        declaration.type === DeclarationType.STAY_EMPLOYED
          ? 'incomeChangeStep'
          : flowSteps &&
            Object.entries(flowSteps).find(([stepName, step]) => {
              if (
                stepName === 'finalConfirm' ||
                stepName === 'continueFinalConfirm'
              ) {
                return step;
              }
              return step.questions?.some(
                ({ name }) =>
                  !{
                    ...declaration.declaredData,
                    type: declaration.type,
                    periodId: declaration.period?.id,
                  }?.[name]
              );
            })?.[0];
      continueStep = uncompletedStep;
    }
    continueStep = continueStep || initialStepByStatus[userInfo.status];
    onGetContinueStep(continueStep);
    return continueStep;
  };

  const handleNextStep = (step, values) => {
    return onNextStep(step, values);
  };

  const handleSubmit = (step, values) => {
    return onSubmit(step, values);
  };

  const initialValues = {
    ...declaration?.declaredData,
    // remove type when reset form resubmitting DJ
    type:
      declaration.status === DeclarationStatus.REJECTED &&
      declaration.type === DeclarationType.STAY_UNEMPLOYMENT
        ? null
        : declaration?.type,
    periodId: declaration?.period?.id,
  };

  if (loading) return <Spinner />;

  if (error) {
    apiCatcher('severe')(error);
    return <Redirect to="/" />;
  }

  return (
    <Wizard
      steps={steps}
      onSubmit={handleSubmit}
      onNextStep={handleNextStep}
      onPreviousStep={onPreviousStep}
      startStep={
        forceStartStep ||
        (isRejectedStayUnemploymentType
          ? 'notDeclaredFirstStep'
          : initialStepByStatus[userInfo.status])
      }
      hiddenValues={hiddenValues}
      continueValues={{
        companyCountry: countries[0],
        startDate: moment(),
        endDate: moment(),
        startDateNewIncome: moment(),
        ...initialValues,
      }}
      catcher={{
        incomeDocumentFile: apiCatcher('severe'),
      }}
      continueStep={
        declaration.status !== DeclarationStatus.EMPTY && getContinueStep()
      }
      submitButtonText="Presentar"
      nextButtonText="Siguiente"
      previousButtonText="Atrás"
      CustomStepper={({ ...props }) => <Stepper {...props} onClose={onClose} />}
    />
  );
}

export default Form;
