import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderBottom: {
      '&.MuiTableCell-root': {
        borderBottom: '1px solid #DBDBDB',
      },
    },
    tableCellBody: {
      maxWidth: 125,
      padding: '18px 12px',
      fontSize: 16,
      backgroundColor: theme.palette.common.white,
      '&.MuiTableCell-root': {
        maxHeight: 75,
      },
      [theme.breakpoints.down('xs')]: {
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
        marginBottom: 10,
        padding: '16px 6px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        fontWeight: 900,
      },
      [theme.breakpoints.down(400)]: {
        fontSize: 10,
        fontWeight: 900,
      },
    },
    tableCellBodyTypography: {
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        fontWeight: 900,
      },
      [theme.breakpoints.down(400)]: {
        display: 'block',
      },
    },
  })
);

export default useStyles;
