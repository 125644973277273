// Based on https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4
import { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig, AxiosPromise, AxiosError } from 'axios';
import { useAuth } from '../providers/Auth';
import { useGeneralContext } from '../providers/GeneralContext';

const fetchWithAuth = <Payload = any>(
  accessToken: string,
  fetchOptions: AxiosRequestConfig
): AxiosPromise<Payload> => {
  return axios({
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const callAPI = async <Payload = any>(
  getAccessToken,
  fetchOptions: AxiosRequestConfig
) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetchWithAuth<Payload>(accessToken, fetchOptions);
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    if (error.error !== 'login_required') console.error(error);
    throw error;
  }
};

export const useAPI = <Payload = any>(
  fetchOptions: AxiosRequestConfig,
  hookDeps: Array<any> = []
): {
  loading: boolean;
  error: AxiosError;
  isAuthenticated: boolean;
  data: Payload;
  refresh: () => Promise<void>;
} => {
  const { isAuthenticated, isLoading: loadingAuth } = useGeneralContext();
  const { getAccessToken } = useAuth();
  const [state, setState] = useState({
    loading: true,
    error: null,
    isAuthenticated,
    data: null,
  });
  const [refreshIndex, setRefreshIndex] = useState(0);
  const [promiseResolve, setPromiseResolve] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const accessToken = await getAccessToken();
          const response = await fetchWithAuth(accessToken, fetchOptions);

          setState((state) => ({
            ...state,
            data: response.data,
            error: null,
            isAuthenticated,
            loading: false,
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          if (error.error !== 'login_required') console.error(error);
          setState((state) => ({
            ...state,
            error,
            loading: false,
          }));
        }
      })();
      if (promiseResolve) {
        promiseResolve();
      }
    } else {
      setState((_state) => ({
        loading: loadingAuth,
        error: null,
        isAuthenticated,
        data: null,
      }));
      if (promiseResolve) {
        promiseResolve();
      }
    }
    // eslint-disable-next-line
  }, [refreshIndex, loadingAuth, isAuthenticated, ...hookDeps]);

  return {
    ...state,
    isAuthenticated,
    refresh: () => {
      return new Promise((resolve) => {
        setRefreshIndex(refreshIndex + 1);
        setPromiseResolve(resolve);
      });
    },
  };
};
