import React, { useEffect, useReducer, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import DoneAll from '@material-ui/icons/DoneAll';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import { useGetBackofficeDeclarationHistory } from '../../services/collections.service';
import { isIncomeDocumentFile } from '../../common/functions';
import {
  getDraftInitialReview,
  getInitialReview,
  reviewReducer,
} from './functions';
import { DeclarationStatus, DeclarationType } from '../../common/enums';
import { DeclarationInstance, QuestionName } from '../../common/interfaces';
import { ReviewValue } from '../Field/interfaces';
import { FieldValue } from '../QuestionItem/interfaces';
import { useBackofficeContext } from '../../providers/BackofficeContext';
import DialogComponent from '../Dialog';
import Spinner from '../Spinner';
import ButtonsContainer from './ButtonsContainer';
import GeneralObservation from './GeneralObservation';
import Header from './Header';
import QuestionItems from './QuestionItems';
import TabsPanel from './TabsPanel';
import useStyles from './styles';

function Review({
  declaration,
  onSubmit,
}: {
  declaration: DeclarationInstance;
  onSubmit: (
    values: {
      id: number;
      backofficeNote: string | undefined;
      [x: string]:
        | {
            value: ReviewValue;
            correction?: FieldValue;
            observation?: string;
          }
        | string
        | number;
    },
    status: DeclarationStatus
  ) => Promise<void> | Promise<unknown> | void;
}) {
  const classes = useStyles();

  const { DDJJContext, loadingDDJJContext } = useBackofficeContext();
  const { data: declarationHistory, loading: loadingDeclarationHistory } =
    useGetBackofficeDeclarationHistory(declaration.id, [declaration]);

  const loading = loadingDDJJContext || loadingDeclarationHistory;

  const hasIncomeDocument = declaration?.declaredData?.incomeDocumentFile;

  const isStayUnemploymentType =
    declaration?.type === DeclarationType.STAY_UNEMPLOYMENT;

  const areReviewData =
    declaration?.reviewData &&
    Object.keys(declaration?.reviewData).length &&
    declaration?.reviewData?.type !== DeclarationType.STAY_UNEMPLOYMENT;

  const [review, handleReview] = useReducer(
    reviewReducer,
    declaration && !isStayUnemploymentType && getInitialReview(declaration)
  );

  const [isEditingReviewed, setIsEditingReviewed] = useState<boolean>(false);

  const [backofficeNote, setBackofficeNote] = useState<{
    value: string;
    isEditing: boolean;
  }>({
    value: '',
    isEditing: false,
  });

  const [observation, setObservation] = useState<{
    value: string;
    isOpen: boolean;
  }>({
    value: '',
    isOpen: false,
  });

  const [selectedTab, setSelectedTab] = useState<
    | 'incomeDocument'
    | 'backofficeNote'
    | 'declarationHistory'
    | 'lastDeclarations'
  >(hasIncomeDocument ? 'incomeDocument' : 'lastDeclarations');

  const [confirmation, setConfirmation] = useState<{
    title: string;
    selectedButtonType: 'accept' | 'reject';
  }>(null);

  const [selectedIndexHistory, setSelectedIndexHistory] =
    useState<number>(null);
  const [historyDirection, setHistoryDirection] = useState<
    'up' | 'down' | undefined
  >(undefined);

  const isSelectedDeclarationHistory = selectedTab === 'declarationHistory';

  const selectedHistoryItem = declarationHistory?.history[selectedIndexHistory];

  useEffect(() => {
    const shouldGetDraftReview =
      !!declaration && !isStayUnemploymentType && areReviewData;

    handleReview({
      type: 'set',
      newReview: shouldGetDraftReview
        ? getDraftInitialReview(declaration.reviewData)
        : getInitialReview(declaration),
    });

    if (isStayUnemploymentType) {
      setObservation({
        ...observation,
        value: declaration?.reviewData?.observation || '',
      });
    }

    setBackofficeNote({
      ...backofficeNote,
      value: declaration?.backofficeNote,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [declaration, DDJJContext, areReviewData, isStayUnemploymentType]);

  useEffect(() => {
    setSelectedIndexHistory(0);
  }, [declarationHistory]);

  const statusValues =
    review &&
    declaration &&
    Object.entries(review).reduce(
      (values, [questionName, { value }]) => ({
        ...values,
        [questionName]: value,
      }),
      {}
    );

  const areUnansweredQuestions =
    statusValues &&
    Object.values(statusValues).some(
      (value) => value === ReviewValue.NOT_SELECTED
    );
  const areRejectedQuestions =
    statusValues &&
    Object.values(statusValues).some((value) => value === ReviewValue.REJECTED);

  const areCorrections =
    review &&
    Object.entries(review).some(
      ([questionName, { correction }]) =>
        review[questionName].value === ReviewValue.ACCEPTED && !!correction
    );
  const areSlideMovements =
    review &&
    Object.values(review).some(
      ({ value }) =>
        value !== ReviewValue.NOT_SELECTED && value !== ReviewValue.PENDING
    );
  const arePendingQuestions =
    review &&
    Object.values(review).some(({ value }) => value === ReviewValue.PENDING);

  const missingObservations =
    review &&
    statusValues &&
    Object.entries(review)
      .filter(([questionName]) =>
        Object.keys(statusValues).some((key) => key === questionName)
      )
      .filter(([_key, { value }]) => value === ReviewValue.REJECTED)
      .some(([_key, { observation }]) => !observation);

  const isAcceptedDeclaration = [
    DeclarationStatus.ACCEPTED,
    DeclarationStatus.ACCEPTED_WITH_CORRECTIONS,
    DeclarationStatus.ACCEPTED_WITH_PENDINGS,
  ].includes(declaration?.status);
  const isRejectedDeclaration =
    declaration?.status === DeclarationStatus.REJECTED;

  const isReviewed = isRejectedDeclaration || isAcceptedDeclaration;

  const acceptButtonText = `Aceptar${
    arePendingQuestions
      ? ' con pendientes'
      : areCorrections
      ? ' con corrección'
      : ''
  }`;
  const rejectButtonText = 'Rechazar';

  const statusByButtonType = {
    reject: DeclarationStatus.REJECTED,
    accept: arePendingQuestions
      ? DeclarationStatus.ACCEPTED_WITH_PENDINGS
      : areCorrections
      ? DeclarationStatus.ACCEPTED_WITH_CORRECTIONS
      : DeclarationStatus.ACCEPTED,
    save: DeclarationStatus.IN_REVIEW,
  };

  const shouldRenderGeneralObservation =
    isSelectedDeclarationHistory &&
    !!selectedHistoryItem?.reviewData?.observation;

  const handleOpenFieldObservations = (
    questionName: QuestionName,
    value: boolean
  ) => {
    handleReview({ type: 'openFieldObservation', questionName, value });
  };

  const handleObservations = (questionName: QuestionName, value: string) => {
    handleReview({ type: 'fieldObservation', questionName, value });
  };

  const handleStatusValues = (
    questionName: QuestionName,
    value: ReviewValue
  ) => {
    handleReview({ type: 'statusValues', questionName, value });
  };

  const handleCorrections = (questionName: QuestionName, value: string) => {
    handleReview({ type: 'correction', questionName, value });
  };

  const handleSubmit = (buttonType: 'reject' | 'accept' | 'save') => {
    const payload: {
      id: number;
      backofficeNote: string;
      [x: string]:
        | {
            value: ReviewValue;
            correction?: FieldValue;
            observation?: string;
          }
        | string
        | number;
    } = {
      id: declaration?.id,
      backofficeNote: backofficeNote.value || '',
      observation: observation.value || undefined,
    };

    Object.keys(review).forEach((questionName) => {
      if (questionName === 'type') {
        return;
      }
      const { value, correction, observation } = review[questionName];
      // corrections of incomeDocumentFile are coming as object with key & url
      let localCorrection = correction;
      if (
        questionName === 'incomeDocumentFile' &&
        localCorrection &&
        typeof localCorrection === 'object' &&
        isIncomeDocumentFile(localCorrection)
      ) {
        localCorrection = localCorrection.key;
      }
      payload[questionName] = {
        value,
        correction:
          (value === ReviewValue.ACCEPTED && localCorrection) || undefined,
        observation: observation || undefined,
      };
    });

    const status = statusByButtonType[buttonType];
    const onSubmitResult = onSubmit(payload, status);

    if (typeof onSubmitResult === 'object') {
      onSubmitResult
        .then(() => {
          setIsEditingReviewed(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  };

  const handleButtonsChangeAll = (type: 'accept' | 'reject') => {
    handleReview({ type: `${type}ValuesAll` });
  };

  const handleEditReviewed = () => {
    if (isReviewed) {
      setIsEditingReviewed(!isEditingReviewed);
    }
  };

  const reviewableDeclaration =
    !isSelectedDeclarationHistory && (!isReviewed || isEditingReviewed);

  const handleBackofficeNote = (value: string) => {
    setBackofficeNote({
      isEditing: false,
      value,
    });
  };

  const handleChangeTab = (newValue: 'backofficeNote' | 'incomeDocument') => {
    setSelectedTab(newValue);
  };

  const handleObservation = (name, value) => {
    setObservation({ ...observation, value });
  };

  const closeConfirmation = () => {
    setConfirmation(null);
  };

  const openConfirmation = (type: 'accept' | 'reject') => {
    setConfirmation({
      title: `¿Estas seguro de que quieres ${
        type === 'accept' ? 'aceptar' : 'rechazar'
      } esta DJ?`,
      selectedButtonType: type,
    });
  };

  let actionButtons = [
    [
      {
        onClick: () => handleSubmit('save'),
        disabled:
          (isReviewed && !isEditingReviewed) ||
          (backofficeNote?.value === declaration?.backofficeNote &&
            !observation.value &&
            (isStayUnemploymentType || !areSlideMovements)),
        dataTestId: 'saveBtn',
        icon: <Save style={{ fontSize: 36 }} />,
        tooltip: 'Guardar',
      },
    ],
    [
      {
        dataTestId: 'rejectAllBtn',
        tooltip: 'Rechazar todo',
        onClick: () => handleButtonsChangeAll('reject'),
        icon: <CancelOutlined style={{ fontSize: 36 }} />,
        disabled: isReviewed && !isEditingReviewed,
      },
      {
        dataTestId: 'acceptAllBtn',
        tooltip: 'Aceptar todo',
        onClick: () => handleButtonsChangeAll('accept'),
        icon: <DoneAll style={{ fontSize: 36 }} />,
        disabled: isReviewed && !isEditingReviewed,
      },
    ],
  ];
  const editBtn = {
    onClick: isSelectedDeclarationHistory
      ? () => {
          setSelectedTab('backofficeNote');
        }
      : handleEditReviewed,
    disabled: isSelectedDeclarationHistory
      ? false
      : !isReviewed || isEditingReviewed,
    dataTestId: 'editBtn',
    icon: <Edit style={{ fontSize: 36 }} />,
    tooltip: 'Editar',
  };
  if (isReviewed) {
    actionButtons[0].unshift(editBtn);
  }
  if (isSelectedDeclarationHistory) {
    actionButtons = [[editBtn]];
  }

  const triggerAnimationHistory = (
    direction: 'up' | 'down',
    selectedIndex: number
  ) => {
    if (selectedIndex !== selectedIndexHistory) {
      setHistoryDirection(direction);
      setTimeout(() => {
        setHistoryDirection(undefined);
      }, 300);
      setTimeout(() => {
        setSelectedIndexHistory(selectedIndex);
      }, 150);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Grid container spacing={3}>
      <Box width="100%" p={3} py={2}>
        <Header
          actionButtons={actionButtons}
          declaration={
            isSelectedDeclarationHistory
              ? {
                  ...declaration,
                  reviewData: {
                    ...declaration.reviewData,
                    // reviewer should change in each item of history
                    reviewer: selectedHistoryItem?.reviewData?.reviewer,
                  },
                }
              : declaration
          }
        />
      </Box>
      <Grid item xs={5} container>
        <TabsPanel
          selectedTab={selectedTab}
          onChangeTab={handleChangeTab}
          backofficeNote={{
            value: backofficeNote.value,
            disabled: !backofficeNote.isEditing,
            isEditing: backofficeNote.isEditing,
            onClickEdit: () => {
              setBackofficeNote({ ...backofficeNote, isEditing: true });
            },
            onCancelEdit: () => {
              setBackofficeNote({ ...backofficeNote, isEditing: false });
            },
            onConfirmEdit: handleBackofficeNote,
            ableToEdit:
              (isEditingReviewed || !isReviewed) && !backofficeNote.isEditing,
          }}
          incomeDocument={{
            src:
              (typeof review?.incomeDocumentFile?.correction === 'string' &&
                review?.incomeDocumentFile?.correction) ||
              (typeof declaration.reviewData?.incomeDocumentFile?.correction ===
                'string' &&
                declaration.reviewData?.incomeDocumentFile?.correction) ||
              declaration.declaredData?.incomeDocumentFile,
          }}
          declarationHistory={{
            ...declarationHistory,
            createdByUser: declarationHistory?.deletable,
            onClickItem: (selectedIndex) => {
              triggerAnimationHistory(
                selectedIndex > selectedIndexHistory ? 'up' : 'down',
                selectedIndex
              );
            },
            selectedIndex: selectedIndexHistory,
          }}
          graduateId={declaration.graduate?.id}
        />
      </Grid>
      <Grid item xs={7} container>
        {isSelectedDeclarationHistory ? (
          <>
            {selectedHistoryItem && (
              <Box
                className={
                  historyDirection &&
                  (historyDirection === 'down' ? classes.down : classes.up)
                }
              >
                <QuestionItems
                  declaration={{
                    ...declaration,
                    reviewData: selectedHistoryItem.reviewData,
                    declaredData: selectedHistoryItem.declaredData,
                  }}
                  review={selectedHistoryItem.reviewData}
                  reviewableDeclaration={false}
                  showTooltipIcon
                />
              </Box>
            )}
          </>
        ) : (
          <QuestionItems
            declaration={declaration}
            review={review}
            reviewableDeclaration={reviewableDeclaration}
            onStatusValuesChange={handleStatusValues}
            onCorrectionChange={handleCorrections}
            onObservationChange={handleObservations}
            onIsObservationOpenChange={handleOpenFieldObservations}
          />
        )}
        {shouldRenderGeneralObservation && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <GeneralObservation
              observation={selectedHistoryItem.reviewData.observation}
            />
          </Box>
        )}
      </Grid>
      {reviewableDeclaration && (
        <ButtonsContainer
          generalObservation={{
            name: 'observation',
            value: observation.value,
            open: observation.isOpen,
            tooltip: 'Observación general',
            onChange: handleObservation,
            onIsOpenChange: () => {
              setObservation({ ...observation, isOpen: !observation.isOpen });
            },
            onClose: () => {
              setObservation({ ...observation, isOpen: false });
            },
            onCleanValue: () => {
              setObservation({ ...observation, value: '' });
            },
          }}
          onClick={openConfirmation}
          buttonText={{
            accept: acceptButtonText,
            reject: rejectButtonText,
          }}
          disabled={{
            accept:
              !isStayUnemploymentType &&
              (areRejectedQuestions ||
                areUnansweredQuestions ||
                !!observation.value),
            reject: isStayUnemploymentType
              ? !observation.value
              : (!areRejectedQuestions || areUnansweredQuestions) &&
                (missingObservations || !observation.value),
          }}
          helperText={
            isStayUnemploymentType && !observation
              ? 'Debes realizar una observación para rechazar esta declaración.'
              : missingObservations &&
                'Debes corregir o realizar observaciones de todos los campos rechazados, o agregar una observación general.'
          }
        />
      )}
      <DialogComponent
        title={confirmation?.title}
        close={closeConfirmation}
        isOpen={!!confirmation}
        buttons={[
          {
            action: closeConfirmation,
            text: 'Cancelar',
            color: 'primary',
            variant: 'outlined',
          },
          {
            action: () => {
              handleSubmit(confirmation?.selectedButtonType);
              closeConfirmation();
            },
            text: 'Confirmar',
            color: 'primary',
            variant: 'contained',
          },
        ]}
      />
    </Grid>
  );
}

export default Review;
