import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Rockent from '../../icons/Rockent';
import useIsThisScreen from '../../utils/useIsThisScreen';

function ErrorComponent({
  title,
  description,
}: {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
}) {
  const isXsScreen = useIsThisScreen('xs');
  const isSmScreen = useIsThisScreen('sm');
  const isMdScreen = useIsThisScreen('md');
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      pt={2}
    >
      <Typography variant="h2">{title || 'Error'}</Typography>
      <Box mt={3} mb={6}>
        <Rockent
          width={isXsScreen ? 300 : isSmScreen ? 350 : isMdScreen ? 400 : 607}
        />
      </Box>
      {description && <Typography variant="h5">{description}</Typography>}
    </Box>
  );
}

export default ErrorComponent;
