import { DeclarationType } from '../../common/enums';

export const getFinalConfirmFields = (
  declarationType: DeclarationType
): string[] => {
  const fields = {
    newEmployment: [
      'companyName',
      'companyCountry',
      'modality',
      'typeOfService',
      'startDate',
      'incomeCurrency',
      'incomeAmount',
      'incomeDocumentFile',
      'incomeDocumentType',
      'contactName',
      'contactEmail',
    ],
    unemployment: ['companyName', 'endDate'],
    stayEmployed: {
      incomeChanged: [
        'incomeCurrency',
        'incomeAmount',
        'startDateNewIncome',
        'incomeDocumentFile',
        'incomeDocumentType',
      ],
      incomeUnchanged: ['incomeDocumentFile', 'incomeDocumentType'],
    },
    stayUnemployment: [],
  };
  const fieldsByType: { [x in DeclarationType]?: string[] } = {
    [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: fields.newEmployment,
    [DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT]: fields.unemployment,
    [DeclarationType.NEW_EMPLOYMENT]: fields.newEmployment,
    [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]:
      fields.stayEmployed.incomeChanged,
    [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]:
      fields.stayEmployed.incomeUnchanged,
    [DeclarationType.UNEMPLOYMENT]: fields.unemployment,
    [DeclarationType.STAY_UNEMPLOYMENT]: fields.stayUnemployment,
    // this type does not have finalConfirm
    [DeclarationType.STAY_EMPLOYED]: null,
  };
  return fieldsByType[declarationType];
};
