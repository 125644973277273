import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) =>
  createStyles({
    input: {
      '& .MuiOutlinedInput-root': {
        minHeight: 44,
        padding: 0,
        borderRadius: 8,
        borderColor: '#2E2E2E',
      },
      '& .MuiOutlinedInput-input': {
        padding: '15px 8px 9px !important',
      },
    },
  })
);
