import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import InfoSharp from '@material-ui/icons/InfoSharp';
import {
  parseFieldValue,
  useGetQuestionLabel,
} from '../QuestionItem/functions';
import { getFinalConfirmFields } from './functions';
import { DeclarationStatus, DeclarationType } from '../../common/enums';
import { DeclarationInstance, ReviewData } from '../../common/interfaces';
import { ReviewValue } from '../Field/interfaces';
import { FieldValue } from '../QuestionItem/interfaces';
import { useGraduateContext } from '../../providers/GraduateContext';
import useIsThisScreen from '../../utils/useIsThisScreen';
import GeneralObservation from '../DDJJReview/GeneralObservation';
import Field from '../Field';
import UpdateInput from '../Field/UpdateInput';
import Spinner from '../Spinner';

interface FeedbackSectionProps {
  declaration: {
    declaredData?: Omit<DeclarationInstance['declaredData'], 'date'> & {
      type?: DeclarationType;
    };
    status?: DeclarationStatus;
    type?: DeclarationType;
    reviewData?: ReviewData;
  };
  corrections: {
    [questionName: string]: FieldValue;
  };
  errors?: {
    [questionName: string]: string;
  };
  onChange: (questionName: string, value: FieldValue) => void;
  fieldsToRender?: string[];
  isWatchingHistory?: boolean;
}

export default function FeedbackSection({
  declaration,
  corrections,
  errors,
  fieldsToRender,
  isWatchingHistory = false,
  onChange,
}: FeedbackSectionProps) {
  const theme = useTheme();
  const isXsScreen = useIsThisScreen('xs');
  const { DDJJContext, loadingDDJJContext: loading } = useGraduateContext();
  const remoteOptions = {
    companyName: DDJJContext?.companyName,
  };

  const getQuestionLabel = useGetQuestionLabel();

  const [isReviewableDeclaration, setIsReviewableDeclaration] = useState(false);

  useEffect(() => {
    setIsReviewableDeclaration(
      declaration?.status === DeclarationStatus.ACCEPTED_WITH_PENDINGS ||
        declaration?.status === DeclarationStatus.REJECTED ||
        declaration?.status === DeclarationStatus.ACCEPTED_WITH_CORRECTIONS
    );
  }, [declaration]);

  const isStayUnemploymentType =
    declaration.type === DeclarationType.STAY_UNEMPLOYMENT;

  const fieldsOrderByQuestionName = [
    'companyName',
    'companyCountry',
    'incomeCurrency',
    'incomeAmount',
    'incomeDocumentType',
    'incomeDocumentFile',
    'modality',
    'typeOfService',
    'contactName',
    'contactEmail',
    'startDate',
    'endDate',
    'startDateNewIncome',
  ];

  const fields =
    fieldsToRender ||
    getFinalConfirmFields(
      declaration.type || declaration.declaredData?.type
    )?.sort(
      (a, b) =>
        fieldsOrderByQuestionName.indexOf(a) -
        fieldsOrderByQuestionName.indexOf(b)
    );

  const getTooltip = (
    review: {
      value: 'rejected' | 'pending' | 'accepted';
      observation?: string;
      correction?: FieldValue;
    },
    declaredValue: FieldValue
  ) => {
    if (!review) {
      return null;
    }
    if (review.value === 'pending') {
      return <span>Es necesario completar este valor.</span>;
    }
    if (review.value === 'rejected') {
      return (
        <span>
          Es necesario corregir este valor. <br /> Observaciones:{' '}
          {review.observation}
        </span>
      );
    }
    if (review.value === 'accepted' && !!review.correction) {
      return (
        <span>
          Este valor fue corregido. <br /> Valor anterior:{' '}
          {parseFieldValue(declaredValue)}.
        </span>
      );
    }
    if (review.observation) {
      return (
        <span>
          Este campo fue observado. <br /> Observaciones: {review.observation}
        </span>
      );
    }
    return null;
  };

  if (
    (declaration.status === DeclarationStatus.ACCEPTED_WITH_PENDINGS ||
      declaration.status === DeclarationStatus.REJECTED) &&
    loading
  ) {
    return <Spinner />;
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {fields?.map((questionName) => {
            const declaredValue = declaration.declaredData?.[questionName];
            const review = declaration.reviewData?.[questionName];
            const correction =
              review && typeof review === 'object' && review.correction;
            const renderValue = correction || declaredValue;
            const tooltip =
              isReviewableDeclaration && getTooltip(review, declaredValue);
            const shouldRenderField =
              isWatchingHistory ||
              (review?.value !== ReviewValue.REJECTED &&
                review?.value !== ReviewValue.PENDING) ||
              review?.correction;
            return (
              <Grid item xs={12} sm={6} key={questionName}>
                <Box
                  style={{
                    borderBottom: shouldRenderField && '.5px solid #DBDBDB',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      marginBottom: shouldRenderField && 11,
                    }}
                  >
                    {getQuestionLabel(questionName)}
                  </Typography>
                  {shouldRenderField ? (
                    <Field
                      value={renderValue || 'Pendiente'}
                      tooltip={tooltip}
                      color={!renderValue ? 'textSecondary' : 'primary'}
                    />
                  ) : (
                    <UpdateInput
                      value={corrections?.[questionName]}
                      questionName={questionName}
                      onChange={(value) => onChange(questionName, value)}
                      error={errors[questionName]}
                      tooltip={tooltip}
                      tooltipIcon={
                        <InfoSharp style={{ fill: theme.palette.error.main }} />
                      }
                      remoteOptions={remoteOptions}
                    />
                  )}
                </Box>
              </Grid>
            );
          })}
          {isStayUnemploymentType && (
            <Box p={4} px={1.5}>
              <Typography>
                No hay campos necesarios en este tipo de DJ.
              </Typography>
            </Box>
          )}
          {declaration.reviewData?.observation && (
            <Box
              width="100%"
              flexDirection="column"
              alignItems="center"
              display="flex"
            >
              <GeneralObservation
                width={isXsScreen ? '100%' : undefined}
                observation={declaration.reviewData?.observation}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
