import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import useIsThisScreen from '../../utils/useIsThisScreen';
import useStyles from './styles';

const mobileLogo = 'https://assets.soyhenry.com/assets/LOGO-HENRY-03.png';
const desktopLogo =
  'https://assets.soyhenry.com/henry-landing/assets/Henry/logo-white.png';

function HenryLogo({ className }: { className?: string }) {
  const classes = useStyles();
  const isXsScreen = useIsThisScreen('xs');
  const henryLogo = isXsScreen ? mobileLogo : desktopLogo;

  return (
    <Link to="/">
      <img
        className={clsx([classes.root, className])}
        alt="Henry Logo"
        src={henryLogo}
      />
    </Link>
  );
}

export default HenryLogo;
