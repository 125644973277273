import React, { ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';

export interface FilterLabelProps {
  children?: ReactNode;
}

export default function FilterLabel({ children }: FilterLabelProps) {
  return (
    <Typography
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        textDecoration: 'underline',
      }}
    >
      {children}
    </Typography>
  );
}
