import React, { forwardRef } from 'react';
import {
  ChevronRight,
  ChevronLeft,
  FirstPage,
  LastPage,
  ArrowUpward,
} from '@material-ui/icons';
import { Icons, Options } from 'material-table';
import { DeclarationStatus, DeclarationType } from '../../../common/enums';
import { Data } from './interfaces';

export const fields = {
  field: 'action',
  fullName: 'Graduado',
  id: 'DJ #',
  period: 'Período',
  companyName: 'Empleador',
  type: 'Tipo DJ',
  incomeDocument: { title: 'Comprobante', sort: false },
  incomeCurrency: 'Moneda',
  incomeAmount: 'Ingresos',
  status: 'Status',
};

export const tableLocalization = {
  header: {
    actions: '',
  },
};

export const tableOptions: Options<Data> = {
  toolbar: false,
  search: false,
  sorting: false,
  draggable: false,
  grouping: false,
  showTitle: false,
  thirdSortClick: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
  tableLayout: 'fixed',
  headerStyle: {
    fontWeight: 900,
    fontSize: 14,
    color: '#757575',
    padding: 8,
    borderTop: '1px solid #757575',
    borderBottom: '1px solid #757575',
    textAlign: 'center',
  },
};

export const tableIcons: Icons = {
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
};

export const defaultPageSize = 20;
export const defaultPageSizeOptions = [10, 20, 50, 100];
export const isPendingState = [
  DeclarationStatus.DRAFT,
  DeclarationStatus.EMPTY,
];
export const isReviewedState = [
  ...isPendingState,
  DeclarationStatus.PRESENTED,
  DeclarationStatus.RESUBMITTED,
  DeclarationStatus.IN_REVIEW,
];
export const isStayState = [
  DeclarationType.STAY_EMPLOYED_INCOME_CHANGED,
  DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED,
  DeclarationType.STAY_UNEMPLOYMENT,
];
