import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// TODO: try to not import from dist
import { Step, Steps } from '@soyhenry/commons/dist/Wizard';
import moment from 'moment';
import * as yup from 'yup';
import {
  getDDJJContext,
  getGraduateInfo,
  upload,
} from '../../services/collections.service';
import {
  DeclarationType,
  GraduateStatus,
  IncomeDocumentTypes,
  JobModalities,
} from '../../common/enums';
import { DeclaredData, IDDJJContext } from '../../common/interfaces';
import currencies from '../../common/currencies.json';
import { useAuth } from '../../providers/Auth';
import { useGraduateContext } from '../../providers/GraduateContext';
import countries from '../../utils/countries';
import typeOfServices from '../../utils/typeOfServices';
import FinalConfirm from '../FinalConfirm';
import { useQuestions } from './questions';

export type StepNames =
  | 'multiemploymentFirstStep'
  | 'notDeclaredFirstStep'
  | 'companyStep'
  | 'modalityStep'
  | 'incomeStep'
  | 'incomeChangeStep'
  | 'incomeChangedStep'
  | 'incomeDocumentStep'
  | 'employmentFirstStep'
  | 'endDateStep'
  | 'finalConfirm'
  | 'continueFinalConfirm';

export function useSteps() {
  const questions = useQuestions();
  const steps: Steps<StepNames> = {
    multiemploymentFirstStep: {
      questions: [questions.multiemploymentType],
      jumps: [
        {
          condition: (values) =>
            values.type === DeclarationType.STAY_EMPLOYED ||
            values.type === DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED ||
            values.type === DeclarationType.STAY_EMPLOYED_INCOME_CHANGED,
          to: 'incomeChangeStep',
        },
      ],
      defaultJump: 'endDateStep',
    },
    continueFinalConfirm: {
      PresentationComponent: ({
        values,
      }: {
        values: Omit<DeclaredData, 'date'> & {
          id: number;
          periods: { value: number; text: string }[];
          periodId: number;
          type: DeclarationType;
        };
      }) => <FinalConfirm values={values} />,
      defaultJump: 'companyStep',
    },
    notDeclaredFirstStep: {
      questions: [questions.notDeclaredType],
      jumps: [
        {
          condition: (values) => values.type === DeclarationType.NEW_EMPLOYMENT,
          to: 'companyStep',
        },
        {
          condition: (values) =>
            values.type === DeclarationType.STAY_UNEMPLOYMENT,
          to: 'finalConfirm',
        },
      ],
    },
    endDateStep: {
      questions: [questions.endDate],
      defaultJump: 'finalConfirm',
      jumps: [
        {
          condition: (values) =>
            values.type === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT,
          to: 'continueFinalConfirm',
        },
      ],
    },
    companyStep: {
      questions: [
        questions.companyName,
        questions.companyCountry,
        questions.contactName,
        questions.contactEmail,
      ],
      defaultJump: 'modalityStep',
      disableBackButton: (values) =>
        values.type === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT ||
        values.type === DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT,
    },
    modalityStep: {
      questions: [
        questions.modality,
        questions.typeOfService,
        questions.startDate,
      ],
      defaultJump: 'incomeStep',
    },
    incomeStep: {
      questions: [
        questions.incomeCurrency,
        questions.incomeAmount,
        questions.incomeDocumentType,
        questions.incomeDocumentFile,
      ],
      defaultJump: 'finalConfirm',
    },
    incomeChangeStep: {
      questions: [questions.incomeChange],
      jumps: [
        {
          condition: (values) =>
            values.type === DeclarationType.STAY_EMPLOYED_INCOME_CHANGED,
          to: 'incomeChangedStep',
        },
        {
          condition: (values) =>
            values.type === DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED,
          to: 'incomeDocumentStep',
        },
      ],
    },
    incomeChangedStep: {
      questions: [
        questions.incomeCurrency,
        questions.incomeAmount,
        questions.startDateNewIncome,
        questions.incomeDocumentType,
        questions.incomeDocumentFile,
      ],
      defaultJump: 'finalConfirm',
    },
    incomeDocumentStep: {
      questions: [questions.incomeDocumentType, questions.incomeDocumentFile],
      defaultJump: 'finalConfirm',
    },
    employmentFirstStep: {
      questions: [questions.employmentType],
      jumps: [
        {
          condition: (values) =>
            values.type === DeclarationType.STAY_EMPLOYED ||
            values.type === DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED ||
            values.type === DeclarationType.STAY_EMPLOYED_INCOME_CHANGED,
          to: 'incomeChangeStep',
        },
      ],
      defaultJump: 'endDateStep',
    },
    finalConfirm: {
      PresentationComponent: ({
        values,
      }: {
        values: Omit<DeclaredData, 'date'> & {
          id: number;
          periods: { value: number; text: string }[];
          periodId: number;
          type: DeclarationType;
        };
      }) => <FinalConfirm values={values} />,
    },
  };

  return steps;
}

export const initialStepByStatus = {
  [GraduateStatus.NOT_DECLARED]: 'notDeclaredFirstStep',
  [GraduateStatus.UNEMPLOYMENT]: 'notDeclaredFirstStep',
  [GraduateStatus.EMPLOYMENT]: 'employmentFirstStep',
  [GraduateStatus.MULTIEMPLOYMENT]: 'multiemploymentFirstStep',
};

type StepNamesUnemploymentForm = 'companyStep' | 'finalConfirm';

export function useUnemploymentFormSteps() {
  const { declarations } = useGraduateContext();

  const currentCompanies = useMemo(
    () =>
      declarations.reduce((result, dec) => {
        if (dec.declaredData.companyName) {
          const { companyName } = dec.declaredData;

          if (result.some((item) => item.value === companyName)) {
            return result;
          }

          result.push({
            text: companyName,
            value: companyName,
          });
        }

        return result;
      }, []),
    [declarations]
  );

  const questions = useQuestions(currentCompanies);
  const unemploymentFormSteps: Steps<StepNamesUnemploymentForm> = {
    companyStep: {
      title: (
        <Box mb={3}>
          <Typography variant="h6">Dar de baja un empleo</Typography>
        </Box>
      ),
      defaultJump: 'finalConfirm',
      questions: [questions.currentCompanyName, questions.endDate],
    },
    finalConfirm: {
      PresentationComponent: ({ values }) => (
        <Box>
          <Box mb={2}>
            <Typography variant="h6">Confirma la baja de empleo</Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">
              Darás de baja tu empleo en <strong>{values.companyName}</strong>.
              Recuerda que una vez que presentes esta DJ no podrás borrarla.
            </Typography>
          </Box>
        </Box>
      ),
    },
  };

  return { unemploymentFormSteps };
}
