import React from 'react';
import { Typography } from '@soyhenry/commons';
import clsx from 'clsx';
import { statusNames } from '../GraduateDashboard/functions';
import { DeclarationStatus, DeclarationType } from '../../common/enums';
import WithTooltip from '../WithTooltip';
import useStyles from './styles';

interface DeclarationStatusChipProps
  extends React.HTMLAttributes<HTMLDivElement> {
  status: DeclarationStatus | DeclarationType.NOT_PRESENTED;
  withTooltip?: boolean;
}

function DeclarationStatusChip({
  status,
  withTooltip = true,
  ...props
}: DeclarationStatusChipProps) {
  const classes = useStyles();

  const classesByStatus: {
    [x in DeclarationStatus]: string;
  } = {
    [DeclarationStatus.EMPTY]: classes.pending,
    [DeclarationStatus.REJECTED]: classes.pending,
    [DeclarationStatus.ACCEPTED_WITH_PENDINGS]: classes.pending,
    [DeclarationStatus.NOT_PRESENTED]: classes.pending,
    [DeclarationStatus.ACCEPTED_WITH_CORRECTIONS]: classes.accepted,
    [DeclarationStatus.ACCEPTED]: classes.accepted,
    [DeclarationStatus.IN_REVIEW]: classes.inReview,
    [DeclarationStatus.PRESENTED]: classes.inReview,
    [DeclarationStatus.RESUBMITTED]: classes.inReview,
    [DeclarationStatus.DRAFT]: classes.draft,
  };

  return (
    <div className={clsx([classes.root, classesByStatus[status]])} {...props}>
      <WithTooltip tooltip={statusNames[status]} withTooltip={withTooltip}>
        <Typography element="p" noWrap>
          {statusNames[status]}
        </Typography>
      </WithTooltip>
    </div>
  );
}

export default DeclarationStatusChip;
