// TODO: try to not import from dist
import { Steps } from '@soyhenry/commons/dist/Wizard';
import { DeclarationType, GraduateStatus } from '../../common/enums';
import { StepNames, useSteps } from './steps';

export const getStepNamesPerFlow = () => {
  return {
    [GraduateStatus.MULTIEMPLOYMENT]: {
      [DeclarationType.NEW_EMPLOYMENT]: [
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_EMPLOYED]: [
        'multiemploymentFirstStep',
        'incomeChangeStep',
      ],
      [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]: [
        'multiemploymentFirstStep',
        'incomeChangeStep',
        'incomeStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]: [
        'multiemploymentFirstStep',
        'incomeChangeStep',
        'incomeDocumentStep',
        'finalConfirm',
      ],
    },
    [GraduateStatus.CHANGING_EMPLOYMENT]: {
      [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: [
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
    },
    [GraduateStatus.UNEMPLOYMENT]: {
      [DeclarationType.NEW_EMPLOYMENT]: [
        'notDeclaredFirstStep',
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_UNEMPLOYMENT]: [
        'notDeclaredFirstStep',
        'finalConfirm',
      ],
    },
    [GraduateStatus.NOT_DECLARED]: {
      [DeclarationType.NEW_EMPLOYMENT]: [
        'notDeclaredFirstStep',
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_UNEMPLOYMENT]: [
        'notDeclaredFirstStep',
        'finalConfirm',
      ],
    },
    [GraduateStatus.EMPLOYMENT]: {
      [DeclarationType.NEW_EMPLOYMENT]: [
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_EMPLOYED]: [
        'employmentFirstStep',
        'incomeChangeStep',
      ],
      [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]: [
        'employmentFirstStep',
        'incomeChangeStep',
        'incomeChangedStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]: [
        'employmentFirstStep',
        'incomeChangeStep',
        'incomeDocumentStep',
        'finalConfirm',
      ],
      [DeclarationType.UNEMPLOYMENT]: [
        'employmentFirstStep',
        'endDateStep',
        'finalConfirm',
      ],
      [DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT]: [
        'employmentFirstStep',
        'endDateStep',
        'continueFinalConfirm',
      ],
      [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: [
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
    },
    [GraduateStatus.MULTIEMPLOYMENT]: {
      [DeclarationType.NEW_EMPLOYMENT]: [
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_EMPLOYED]: [
        'employmentFirstStep',
        'incomeChangeStep',
      ],
      [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]: [
        'employmentFirstStep',
        'incomeChangeStep',
        'incomeChangedStep',
        'finalConfirm',
      ],
      [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]: [
        'employmentFirstStep',
        'incomeChangeStep',
        'incomeDocumentStep',
        'finalConfirm',
      ],
      [DeclarationType.UNEMPLOYMENT]: [
        'employmentFirstStep',
        'endDateStep',
        'finalConfirm',
      ],
      [DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT]: [
        'employmentFirstStep',
        'endDateStep',
        'continueFinalConfirm',
      ],
      [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: [
        'companyStep',
        'modalityStep',
        'incomeStep',
        'finalConfirm',
      ],
    },
  };
};

export function useGetFlowSteps() {
  const steps = useSteps();

  const getFlowSteps = (
    graduateStatus: GraduateStatus,
    declarationType: DeclarationType
  ): Steps<StepNames> => {
    return Object.entries(steps).reduce(
      (filteredSteps, [stepName, step]) =>
        getStepNamesPerFlow()[graduateStatus][declarationType].includes(
          stepName
        )
          ? { ...filteredSteps, [stepName]: step }
          : filteredSteps,
      {}
    );
  };

  return { getFlowSteps };
}
