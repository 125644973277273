import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteDeclaration } from '../../../services/collections.service';
import { useAuth } from '../../../providers/Auth';
import Spinner from '../../Spinner';
import useStyles from './styles';

interface DeleteConfirmationProps {
  id?: number;
  onCancel?: () => void;
  onFinally?: (err?: any) => void;
}

export default function DeleteConfirmation({
  id,
  onCancel,
  onFinally,
}: DeleteConfirmationProps) {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const onConfirm = () => {
    setLoading(true);
    deleteDeclaration(getAccessToken, id)
      .then(() => {
        setLoading(false);
        if (onFinally) {
          onFinally();
        } else {
          onCancel?.();
        }
      })
      .catch((err) => {
        setLoading(false);
        if (onFinally) {
          onFinally(err);
        } else {
          onCancel?.();
        }
      });
  };

  return (
    <Dialog className={classes.dialog} open={!!id} onClose={onCancel}>
      <DialogTitle className={classes.deleteConfirmationTitle}>
        ¿Está seguro de borrar la declaracion DJ#: {id}?
      </DialogTitle>
      <DialogActions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button style={{ width: '50%' }} variant="outlined" onClick={onCancel}>
          No
        </Button>
        <Button
          style={{ width: '50%' }}
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {loading ? <Spinner size={24} /> : 'Si'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
