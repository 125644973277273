import React from 'react';
import Box from '@material-ui/core/Box';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import { DeclarationInstance } from '../../common/interfaces';
import { TableData, TableProps } from '../Table/interfaces';
import WithTooltip from '../WithTooltip';
import useStyles from './styles';

export interface TableCellProps {
  children: React.ReactNode;
  withTooltip?: boolean;
  maxWidth?: string | number;
  // only for a border style from length of this array
  declarations?: DeclarationInstance[];
  typographyProps?: TypographyProps;
}

export const TableCell = ({
  declarations,
  children,
  withTooltip,
  maxWidth,
  typographyProps = {},
}: TableCellProps) => {
  const classes = useStyles();
  return (
    <MuiTableCell
      className={clsx([
        classes.tableCellBody,
        declarations?.length && classes.borderBottom,
      ])}
      align="center"
    >
      <WithTooltip tooltip={children} withTooltip={withTooltip}>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          maxWidth={maxWidth}
        >
          <Typography
            className={
              (classes.tableCellBodyTypography, typographyProps.className)
            }
            noWrap
            {...typographyProps}
          >
            {children}
          </Typography>
        </Box>
      </WithTooltip>
    </MuiTableCell>
  );
};

export interface RowProps<Data extends object> {
  fields: TableProps<Data>['fields'];
  keysWithTooltip?: string[];
  rowData: TableData<Data>;
  clickable?: boolean | ((rowData: Data) => boolean);
  Actions?: React.ReactNode | ((props: { data: Data }) => JSX.Element);
  maxWidth?: { [x: string]: string | number };
  typographyProps?: TypographyProps;
  onClick?: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowData: Data
  ) => void;
}

function Row<Data extends object>({
  onClick,
  fields,
  keysWithTooltip,
  rowData,
  maxWidth,
  typographyProps = {},
  Actions,
  clickable = !!onClick,
}: RowProps<Data>) {
  const columnsToRender = Object.entries(rowData.data)
    .filter(([key]) => Object.keys(fields).includes(key))
    .sort(
      ([a], [b]) =>
        Object.keys(fields).indexOf(a) - Object.keys(fields).indexOf(b)
    );

  const handleClick: React.MouseEventHandler<HTMLTableRowElement> = (event) => {
    onClick?.(event, rowData.data);
  };

  return (
    <TableRow
      style={{
        cursor: (
          typeof clickable === 'function' ? clickable(rowData.data) : clickable
        )
          ? 'pointer'
          : 'auto',
      }}
      onClick={handleClick}
    >
      {columnsToRender.map(([key, value]) => (
        <TableCell
          key={key}
          withTooltip={keysWithTooltip?.includes(key)}
          maxWidth={maxWidth?.[key]}
          typographyProps={typographyProps}
        >
          {value}
        </TableCell>
      ))}
      {!!Actions &&
        (typeof Actions === 'function' ? (
          <Actions data={rowData.data} />
        ) : (
          Actions
        ))}
    </TableRow>
  );
}

export default Row;
