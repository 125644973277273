import React from 'react';
import Box from '@material-ui/core/Box';
import { DeclarationType } from '../../common/enums';
import { DeclarationInstance } from '../../common/interfaces';
import IconButtonGroup, { IconButtonGroupProps } from '../IconButtonGroup';
import DDJJResume from './DDJJResume';

function Header({
  declaration,
  actionButtons,
}: {
  declaration: DeclarationInstance;
  actionButtons?: IconButtonGroupProps['groups'];
}) {
  const { type } = declaration;

  const isStayUnemploymentType = type === DeclarationType.STAY_UNEMPLOYMENT;

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <DDJJResume declaration={declaration} />
      <Box display="flex" justifyContent="flex-end">
        <IconButtonGroup
          groups={isStayUnemploymentType ? [actionButtons[0]] : actionButtons}
        />
      </Box>
    </Box>
  );
}

export default Header;
