import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from './styles';

const useStyles = makeStyles(styles);

export interface DialogComponentProps {
  isOpen: boolean;
  close: () => void;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  buttons?: Array<{
    action: React.MouseEventHandler<HTMLButtonElement>;
    color?: ButtonProps['color'];
    variant?: ButtonProps['variant'];
    text: string;
  }>;
  children?: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
}

export default function DialogComponent({
  title,
  description,
  isOpen,
  close,
  buttons,
  children,
  maxWidth,
}: DialogComponentProps) {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.root}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {buttons?.map((button, index) => (
            <Button
              key={button.text}
              onClick={button.action}
              variant={button.variant || 'contained'}
              color={button.color || 'primary'}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      </div>
    </Dialog>
  );
}
