import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import { TooltipIconWrapper } from '@soyhenry/commons';
import { parseFieldValue } from '../QuestionItem/functions';
import { FieldProps } from './interfaces';

function Field({
  value,
  label,
  tooltip,
  editable,
  color,
  onClickEdit,
}: FieldProps) {
  return (
    <TooltipIconWrapper tooltip={tooltip}>
      <Box width="100%" display="flex" alignItems="flex-start">
        <Box maxWidth={200} width="100%">
          <Tooltip arrow title={parseFieldValue(value)}>
            <Typography
              noWrap
              variant="subtitle2"
              component="p"
              style={{
                fontWeight: 750,
                fontSize: 17,
              }}
              color={color}
            >
              <span>{parseFieldValue(value)}</span>
            </Typography>
          </Tooltip>
          <Typography style={{ fontSize: 13 }} color="textSecondary">
            {label}
          </Typography>
        </Box>
        <Box>
          {editable && (
            <IconButton>
              <Edit onClick={onClickEdit} style={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Box>
      </Box>
    </TooltipIconWrapper>
  );
}

export default Field;
