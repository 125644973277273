import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import Magnifier from 'react-magnifier';
import { pdfjs } from 'react-pdf';
import Modal, { ModalProps } from '../Modal';
import PdfView from '../PdfView';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const getExtensionFile = (src: string): string => {
  let extensionFile = src.split('.').pop();
  extensionFile = extensionFile.slice(0, extensionFile.indexOf('?'));
  return extensionFile;
};

interface IncomeDocumentViewProps {
  src: string;
}

export default function IncomeDocumentView({ src }: IncomeDocumentViewProps) {
  const extensionFile = src && getExtensionFile(src);

  if (!src) {
    return (
      <Box textAlign="center" width="100%">
        <Typography variant="h4">No hay comprobante</Typography>
      </Box>
    );
  }

  if (extensionFile === 'pdf' || extensionFile === 'PDF') {
    return <PdfView src={src} />;
  }

  return (
    <Box
      bgcolor="rgba(128, 128, 128, 0.16);"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      display="flex"
      borderRadius={10}
    >
      <Magnifier mgShape="circle" src={src} />
    </Box>
  );
}

export const IncomeDocumentViewModal = ({
  src,
  open,
  onClose,
}: IncomeDocumentViewProps & Omit<ModalProps, 'children'>) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Container maxWidth="md">
        <Box bgcolor="#f0f0f0" borderRadius="8px" padding="16px">
          <Grid container>
            <Grid container justifyContent="flex-end" item xs={12}>
              <IconButton onClick={() => onClose({}, 'backdropClick')}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <IncomeDocumentView src={src} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Modal>
  );
};
