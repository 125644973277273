import moment from 'moment';
import { useGetQuestionLabel } from '../QuestionItem/functions';
import { DeclarationType } from '../../common/enums';
import {
  DeclarationInstance,
  ItemsReviewData,
  QuestionName,
  Review,
} from '../../common/interfaces';
import { ReviewValue } from '../Field/interfaces';
import { FieldValue } from '../QuestionItem/interfaces';
import { useQuestions } from '../DDJJForm/questions';

export const getNeccesaryFields = (
  declarationType: DeclarationType
): QuestionName[] => {
  if (declarationType === DeclarationType.STAY_UNEMPLOYMENT) {
    return [];
  }
  let neccesaryFields: QuestionName[] = [
    'incomeDocumentFile',
    'incomeDocumentType',
    'incomeCurrency',
    'incomeAmount',
    'typeOfService',
    'companyName',
    'contactEmail',
    'modality',
    'contactName',
    'companyCountry',
    'startDate',
  ];
  if (declarationType === DeclarationType.STAY_EMPLOYED_INCOME_CHANGED) {
    neccesaryFields.push('startDateNewIncome');
  }
  if (
    declarationType === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT ||
    declarationType === DeclarationType.UNEMPLOYMENT
  ) {
    neccesaryFields.push('endDate');
    const indexOfDeletables = [
      neccesaryFields.indexOf('incomeDocumentFile'),
      neccesaryFields.indexOf('incomeDocumentType'),
    ];
    neccesaryFields = neccesaryFields.filter(
      (_v, i) => !indexOfDeletables.includes(i)
    );
  }
  return neccesaryFields;
};

const questionNamesByType: {
  [key in DeclarationType]: QuestionName[];
} = {
  [DeclarationType.EMPTY]: [],
  [DeclarationType.NOT_PRESENTED]: [],
  [DeclarationType.STAY_UNEMPLOYMENT]: [],
  [DeclarationType.NEW_EMPLOYMENT]: [
    'companyName',
    'companyCountry',
    'contactName',
    'contactEmail',
    'modality',
    'typeOfService',
    'startDate',
    'incomeCurrency',
    'incomeAmount',
    'incomeDocumentType',
    'incomeDocumentFile',
  ],
  [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: [
    'companyName',
    'companyCountry',
    'contactName',
    'contactEmail',
    'modality',
    'typeOfService',
    'startDate',
    'incomeCurrency',
    'incomeAmount',
    'incomeDocumentType',
    'incomeDocumentFile',
  ],
  [DeclarationType.STAY_EMPLOYED]: [],
  [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]: [
    'contactName',
    'contactEmail',
    'incomeDocumentType',
    'incomeDocumentFile',
    'modality',
    'typeOfService',
  ],
  [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]: [
    'contactName',
    'contactEmail',
    'incomeCurrency',
    'incomeAmount',
    'incomeDocumentType',
    'incomeDocumentFile',
    'modality',
    'typeOfService',
    'startDateNewIncome',
  ],
  [DeclarationType.UNEMPLOYMENT]: ['endDate'],
  [DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT]: ['endDate'],
};

export const getInitialReview = (declaration: DeclarationInstance) => {
  const fieldsToReview = questionNamesByType[declaration.type];

  const getInitialReview = (field: QuestionName, fieldValue: FieldValue) => ({
    value: !fieldValue
      ? ReviewValue.PENDING
      : fieldsToReview.includes(field)
      ? ReviewValue.NOT_SELECTED
      : ReviewValue.ACCEPTED,
    correction: '',
    openObservation: false,
    observation: '',
  });

  const neccesaryFields = getNeccesaryFields(declaration.type);
  return neccesaryFields.reduce(
    (review, neccesaryField) => ({
      ...review,
      [neccesaryField]: getInitialReview(
        neccesaryField,
        declaration.declaredData[neccesaryField]
      ),
    }),
    { type: declaration.type }
  );
};

export const getDraftInitialReview = (reviewData: ItemsReviewData) => {
  return Object.entries(reviewData).reduce(
    (newReview, [questionName, review]: [QuestionName, Review]) => {
      if (typeof review === 'string') {
        return newReview;
      }
      const { value, correction, observation } = review;
      return {
        ...newReview,
        [questionName]: {
          value,
          correction: correction || '',
          observation: observation || '',
          openObservation: false,
        },
      };
    },
    { type: reviewData.type }
  );
};

// TODO: unused, only to get labels in tests, try to remove it.
export function useGetFormattedDeclaration() {
  const questions = useQuestions();
  const getQuestionLabel = useGetQuestionLabel();
  const getFormattedDeclaration = (
    { declaredData, type }: DeclarationInstance,
    labelAsKey?: boolean
  ) => {
    let formattedDeclaration: {
      [x: string]: FieldValue;
    }[] = [];
    if (type !== DeclarationType.STAY_UNEMPLOYMENT) {
      formattedDeclaration = [
        ...formattedDeclaration,
        {
          [labelAsKey
            ? getQuestionLabel(questions.incomeDocumentType.name)
            : questions.incomeDocumentType.name]:
            declaredData?.incomeDocumentType,
          [labelAsKey
            ? getQuestionLabel(questions.incomeDocumentFile.name)
            : questions.incomeDocumentFile.name]:
            declaredData?.incomeDocumentFile,
          [labelAsKey
            ? getQuestionLabel(questions.incomeCurrency.name)
            : questions.incomeCurrency.name]: declaredData?.incomeCurrency,
          [labelAsKey
            ? getQuestionLabel(questions.incomeAmount.name)
            : questions.incomeAmount.name]: declaredData?.incomeAmount,
        },
        {
          [labelAsKey
            ? getQuestionLabel(questions.companyName.name)
            : questions.companyName.name]: declaredData?.companyName,
          [labelAsKey
            ? getQuestionLabel(questions.companyCountry.name)
            : questions.companyCountry.name]: declaredData?.companyCountry,
          [labelAsKey
            ? getQuestionLabel(questions.contactName.name)
            : questions.contactName.name]: declaredData?.contactName,
          [labelAsKey
            ? getQuestionLabel(questions.contactEmail.name)
            : questions.contactEmail.name]: declaredData?.contactEmail,
        },
        {
          [labelAsKey
            ? getQuestionLabel(questions.modality.name)
            : questions.modality.name]: declaredData?.modality,
          [labelAsKey
            ? getQuestionLabel(questions.typeOfService.name)
            : questions.typeOfService.name]: declaredData?.typeOfService,
          [labelAsKey
            ? getQuestionLabel(questions.startDate.name)
            : questions.startDate.name]:
            typeof declaredData?.startDate === 'string'
              ? declaredData?.startDate
              : moment(declaredData?.startDate),
        },
      ];
    }
    if (
      type === DeclarationType.UNEMPLOYMENT ||
      type === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT
    ) {
      formattedDeclaration[2] = {
        ...formattedDeclaration[2],
        [labelAsKey
          ? getQuestionLabel(questions.endDate.name)
          : questions.endDate.name]: typeof declaredData?.endDate
          ? declaredData?.endDate
          : moment(declaredData?.endDate),
      };
    }
    return formattedDeclaration;
  };
  return getFormattedDeclaration;
}

export type SetReviewAction = {
  type: 'set';
  newReview: ItemsReviewData;
};

export type OpenFieldObservationAction = {
  type: 'openFieldObservation';
  questionName: QuestionName;
  value: boolean;
};

export type FieldObservationAction = {
  type: 'fieldObservation';
  questionName: QuestionName;
  value: string;
};

export type StatusValuesAction = {
  type: 'statusValues';
  questionName: QuestionName;
  value: ReviewValue;
};

export type StatusValuesAllAction = {
  type: 'acceptValuesAll' | 'rejectValuesAll';
};

export type CorrectionAction = {
  type: 'correction';
  questionName: QuestionName;
  value: string;
};

export const reviewReducer = (
  review: ItemsReviewData,
  action:
    | SetReviewAction
    | OpenFieldObservationAction
    | FieldObservationAction
    | StatusValuesAction
    | StatusValuesAllAction
    | CorrectionAction
): ItemsReviewData | undefined => {
  if (review) {
    switch (action.type) {
      case 'set':
        return action.newReview;

      case 'openFieldObservation':
        return {
          ...review,
          [action.questionName]: {
            ...review[action.questionName],
            openFieldObservation: action.value,
          },
        };

      case 'fieldObservation':
        return {
          ...review,
          [action.questionName]: {
            ...review[action.questionName],
            observation: action.value,
          },
        };

      case 'statusValues':
        return {
          ...review,
          [action.questionName]: {
            ...review[action.questionName],
            value: action.value,
          },
        };

      case 'correction':
        return {
          ...review,
          [action.questionName]: {
            ...review[action.questionName],
            correction: action.value,
            value: ReviewValue.ACCEPTED,
          },
        };

      case 'acceptValuesAll':
        return questionNamesByType[review.type].reduce(
          (newReview, questionName) => {
            const questionReview = review[questionName];
            return {
              ...newReview,
              [questionName]: {
                ...questionReview,
                value:
                  questionReview.value !== ReviewValue.PENDING
                    ? ReviewValue.ACCEPTED
                    : ReviewValue.PENDING,
              },
            };
          },
          review
        );

      case 'rejectValuesAll':
        return questionNamesByType[review.type].reduce(
          (newReview, questionName) => {
            const questionReview = review[questionName];
            return {
              ...newReview,
              [questionName]: {
                ...questionReview,
                value:
                  questionReview.value !== ReviewValue.PENDING
                    ? ReviewValue.REJECTED
                    : ReviewValue.PENDING,
              },
            };
          },
          review
        );

      default:
        return review;
    }
  }
  return review;
};
