import React, { useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import MoreVert from '@material-ui/icons/MoreVert';
import useStyles from './styles';

function MobileOptionMenu({
  open,
  onClose,
  onClickDelete,
  onMenuClick,
}: {
  onMenuClick: () => void;
  onClickDelete: () => void;
  onClose: () => void;
  open: boolean;
}) {
  const ref = useRef();
  const classes = useStyles();

  return (
    <>
      <IconButton onClick={onMenuClick}>
        <MoreVert ref={ref} />
      </IconButton>
      <Menu
        className={classes.menu}
        anchorEl={() => ref.current}
        open={open}
        onClose={onClose}
      >
        <MenuItem onClick={onClickDelete}>
          <DeleteIcon style={{ marginRight: 4 }} /> Borrar
        </MenuItem>
      </Menu>
    </>
  );
}

export default MobileOptionMenu;
