import { NotificationType } from './interfaces';

const notificationTexts: {
  [x in NotificationType]: (declarationId: number) => string;
} = {
  [NotificationType.A_DECLARATION_NEEDS_TO_BE_COMPLETED]: (declarationId) =>
    `Debes completar la declaración #${declarationId}`,
  [NotificationType.A_DECLARATION_WAS_ACCEPTED]: (declarationId) =>
    `La declaración #${declarationId} fue aceptada.`,
  [NotificationType.A_DECLARATION_WAS_ACCEPTED_WITH_CORRECTIONS]: (
    declarationId
  ) =>
    `La declaración #${declarationId} fue aceptada con correcciones. Por favor, revisalas.`,
  [NotificationType.A_DECLARATION_WAS_ACCEPTED_WITH_PENDINGS]: (
    declarationId
  ) =>
    `La declaración #${declarationId} fue aceptada con pendientes. Por favor, completa los campos faltantes y vuelve a presentarla.`,
  [NotificationType.A_DECLARATION_WAS_REJECTED]: (declarationId) =>
    `La declaración #${declarationId} fue rechazada. Por favor, corrige los campos observados y vuelve a presentarla.`,
  [NotificationType.A_DECLARATION_WAS_REMINDED_AFTER_3_DAYS]: (declarationId) =>
    `Te recordamos que ya pasaron 72 horas desde el primer aviso, recuerda completar la declaracion #${declarationId}.`,
  [NotificationType.A_DECLARATION_WAS_REMINDED_AFTER_15_DAYS]: (
    declarationId
  ) =>
    `Te recordamos que ya pasaron 15 dias desde el primer aviso, recuerda completar la declaracion #${declarationId}.`,
};

export default notificationTexts;
