import React from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import Box, { BoxProps } from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, TypographyProps } from '@soyhenry/commons';
import styles from './styles';

const useStyles = makeStyles(styles);

const Avatar = ({
  src,
  className,
  name,
  size,
  style,
}: {
  src?: string;
  name?: string;
  size?: number;
  className?: string;
  style?: Object;
}) => (
  <MuiAvatar
    style={{
      height: size,
      width: size,
      ...style,
    }}
    className={className}
    src={src}
  >
    <Typography variant="h6" style={{ fontSize: size / 2 }}>
      {name && !src && name[0]}
    </Typography>
  </MuiAvatar>
);

interface NamedAvatarProps extends BoxProps {
  src?: string;
  name: string;
  size?: number;
  style?: Object;
  className?: string;
  typographyProps?: TypographyProps;
}

export const NamedAvatar = ({
  src,
  name,
  size,
  style,
  className,
  typographyProps,
  ...props
}: NamedAvatarProps) => {
  const classes = useStyles();
  return (
    <Box style={style} className={className} {...props}>
      <Grid container alignItems="center" direction="row" wrap="nowrap">
        <Avatar
          size={size}
          className={classes.avatar}
          name={name}
          style={{ marginRight: 16 }}
          src={src}
        />
        <div style={{ maxWidth: '75%' }}>
          <Tooltip arrow title={name}>
            <Typography noWrap {...typographyProps}>
              {name}
            </Typography>
          </Tooltip>
        </div>
      </Grid>
    </Box>
  );
};

export default Avatar;
