import React from 'react';
import Box from '@material-ui/core/Box';
import { Input } from '@soyhenry/commons';
import moment from 'moment';
import { useQuery } from '../../../../common/functions';
import FilterLabel from '../FilterLabel';

export default function FromDateFilter() {
  const { query, changeQuery } = useQuery();

  return (
    <Box mb={0.5}>
      <FilterLabel>Desde</FilterLabel>
      <Input
        type="date"
        name="fromDate"
        format="DD/MM/YYYY"
        formatValue={(value: string) => {
          return moment(value)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toISOString();
        }}
        value={
          query.fromDate &&
          moment(query.fromDate)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toISOString()
        }
        onChange={({ value }) =>
          changeQuery({
            name: 'fromDate',
            value: (value as string).slice(0, 10),
          })
        }
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        InputProps={{ style: { borderRadius: 10 } }}
        maxDate={query.toDate ? new Date(query.toDate) : new Date()}
      />
    </Box>
  );
}
