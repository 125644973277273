import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAuth } from '../../providers/Auth';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function LoginComponent() {
  const { logIn } = useAuth();
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.rootContainer}
    >
      <Box mb={5} component="div" alignContent="center" justifyContent="center">
        <Typography variant="body2" className={classes.title}>
          Bienvenido
        </Typography>
        <br />
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={() => logIn()}
        >
          Log In
        </Button>
      </Box>
    </Grid>
  );
}
