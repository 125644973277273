import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import DownloadIcon from '@material-ui/icons/GetAppRounded';
import moment from 'moment';
import useErrorHandler from '../../../services/ErrorHandler';
import { getBackofficeDeclarationsCSV } from '../../../services/collections.service';
import { useQuery } from '../../../common/functions';
import { useAuth } from '../../../providers/Auth';
import CompanyNameFilter from './CompanyNameFilter';
import FromDateFilter from './FromDateFilter';
import IncomeCurrencyFilter from './IncomeCurrencyFilter';
import PeriodIdFilter from './PeriodIdFilter';
import StatusFilter from './StatusFilter';
import ToDateFilter from './ToDateFilter';
import TypeFilter from './TypeFilter';
import ValidityFilter from './ValidityFilter';

export default function FiltersPanel() {
  const { query, setQuery } = useQuery();
  const { apiCatcher } = useErrorHandler();
  const { getAccessToken } = useAuth();

  const handleReset = useCallback(() => {
    setQuery({});
  }, [setQuery]);

  const handleDownload = useCallback(() => {
    getBackofficeDeclarationsCSV(getAccessToken, query)
      .then((csv) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:text/csv;charset=utf-8,${escape(csv)}`;
        downloadLink.download = `Declaraciones ${moment().format(
          'YYYY-MM-DD HH-mm-ss'
        )}.csv`;
        downloadLink.click();
      })
      .catch(apiCatcher('severe'));
  }, [getAccessToken, query, apiCatcher]);

  return (
    <Paper>
      <Box height="100%" p={3}>
        <Box
          mb={1}
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography style={{ fontSize: 22, fontWeight: 900 }} variant="h5">
            Filtros
          </Typography>
          <div>
            <Tooltip title="Resetar Filtros" arrow>
              <span style={{ margin: '4px' }}>
                <IconButton onClick={handleReset}>
                  <CachedIcon style={{ fontSize: '2rem' }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Exportar CSV con estos Filtros" arrow>
              <span style={{ margin: '4px' }}>
                <IconButton onClick={handleDownload}>
                  <DownloadIcon style={{ fontSize: '2rem' }} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </Box>
        <List>
          <StatusFilter />
          <TypeFilter />
          <CompanyNameFilter />
          <IncomeCurrencyFilter />
          <ValidityFilter />
          <PeriodIdFilter />
          <FromDateFilter />
          <ToDateFilter />
        </List>
      </Box>
    </Paper>
  );
}
