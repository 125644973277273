import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import LibraryAddIcon from '@material-ui/icons/LibraryAddOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import clsx from 'clsx';
import { DeclarationType, GraduateStatus } from '../../common/enums';
import { useGraduateContext } from '../../providers/GraduateContext';
import useIsThisScreen from '../../utils/useIsThisScreen';
import useWindowHeight from '../../utils/useWindowHeight';
import MobileNewDeclaration from '../MobileNewDeclaration';
import useStyles from './style';

export default function Footer(): JSX.Element {
  const {
    hasPendingDeclarations,
    loadingDDJJContext,
    createNewJobDeclaration,
    createUnemploymentDeclaration,
  } = useGraduateContext();
  const { graduateData } = useGraduateContext({
    ignoreWarnings: true,
  });

  const location = useLocation();

  const windowHeight = useWindowHeight();

  const [initialHeight] = useState(window.innerHeight);

  const [mobileNewDeclarationMenu, setMobileNewDeclarationMenu] =
    useState(false);
  const [newDeclarationType, setNewDeclarationType] = useState<
    DeclarationType.NEW_EMPLOYMENT | DeclarationType.UNEMPLOYMENT
  >(null);

  const classes = useStyles();

  const isThisScreenSM = useIsThisScreen('sm');

  const shouldHideFooterPaths = ['declaration', 'profile'];

  const windowHeightChange = windowHeight < initialHeight;

  const shouldHideFooter =
    windowHeightChange ||
    (shouldHideFooterPaths.includes(location.pathname) && isThisScreenSM);

  const isEmploymentGraduate =
    graduateData?.status === GraduateStatus.EMPLOYMENT ||
    graduateData?.status === GraduateStatus.MULTIEMPLOYMENT;

  const toggleMobileNewDeclarationMenu = () => {
    setMobileNewDeclarationMenu(!mobileNewDeclarationMenu);
  };

  const handleTypeSelection = ({
    value,
  }: {
    name: string;
    value: DeclarationType.UNEMPLOYMENT | DeclarationType.NEW_EMPLOYMENT;
  }) => {
    setNewDeclarationType(value);
  };

  const createNewDeclaration = () => {
    if (newDeclarationType === DeclarationType.NEW_EMPLOYMENT) {
      createNewJobDeclaration();
    } else {
      createUnemploymentDeclaration();
    }
    toggleMobileNewDeclarationMenu();
  };

  return (
    <>
      <div
        className={classes.root}
        style={{
          transform: `translateY(${shouldHideFooter ? 100 : 0}%)`,
          transition: '500ms',
        }}
      >
        <Container maxWidth="xs" className={classes.contentContainer}>
          {isThisScreenSM ? (
            <Button
              variant="contained"
              color="secondary"
              className={clsx(
                classes.circle,
                (hasPendingDeclarations || shouldHideFooter) &&
                  classes.buttonUnmount
              )}
              onClick={
                !isEmploymentGraduate
                  ? createNewJobDeclaration
                  : toggleMobileNewDeclarationMenu
              }
              disabled={loadingDDJJContext}
            >
              <LibraryAddIcon className={classes.buttonIcon} />
            </Button>
          ) : (
            <>
              <Typography noWrap className={classes.text}>
                <LockOutlinedIcon height="1em" />
                Confidencial
              </Typography>
              <Link to="/">
                <div className={clsx(classes.circle, classes.logoContainer)}>
                  <img
                    alt=""
                    src="https://assets.soyhenry.com/assets/LOGO-HENRY-03.png"
                    className={classes.logo}
                  />
                </div>
              </Link>
              <Typography noWrap className={classes.text}>
                Henry ®
              </Typography>
            </>
          )}
        </Container>
      </div>
      <Modal
        onClose={toggleMobileNewDeclarationMenu}
        open={!!mobileNewDeclarationMenu}
        style={{
          margin: '100px auto',
        }}
      >
        <Container maxWidth="xs">
          <MobileNewDeclaration
            onChange={handleTypeSelection}
            onSubmit={createNewDeclaration}
            onClose={toggleMobileNewDeclarationMenu}
            submitDisabled={!newDeclarationType}
          />
        </Container>
      </Modal>
    </>
  );
}
