import React, { useEffect, useState } from 'react';
import { Wizard } from '@soyhenry/commons';
import { DeclarationType } from '../../common/enums';
import { DeclarationInstance, Graduate } from '../../common/interfaces';
import { useGraduateContext } from '../../providers/GraduateContext';
import Spinner from '../Spinner';
import Stepper from '../Stepper';
import { useUnemploymentFormSteps } from './steps';

function UnemploymentForm({
  userInfo,
  declarationId,
  onSubmit,
  onClose,
}: {
  userInfo: Graduate;
  declarationId: number;
  onSubmit: (step: string, values: unknown) => void;
  onClose: () => void;
}) {
  const { DDJJContext, loadingDDJJContext } = useGraduateContext();
  const { unemploymentFormSteps } = useUnemploymentFormSteps();

  const [selectedCompany, setSelectedCompany] = useState<string>(null);
  const [lastDeclaration, setLastDeclaration] =
    useState<DeclarationInstance>(null);

  useEffect(() => {
    setLastDeclaration(userInfo.lastDeclarations?.[selectedCompany || '']);
  }, [selectedCompany, setLastDeclaration, userInfo.lastDeclarations]);

  const latestJobValues = {
    incomeAmount:
      lastDeclaration?.reviewData?.incomeAmount?.correction ||
      lastDeclaration?.declaredData?.incomeAmount,
    incomeCurrency:
      lastDeclaration?.reviewData?.incomeCurrency?.correction ||
      lastDeclaration?.declaredData?.incomeCurrency,
    contactEmail:
      lastDeclaration?.reviewData?.contactEmail?.correction ||
      lastDeclaration?.declaredData?.contactEmail,
    contactName:
      lastDeclaration?.reviewData?.contactName?.correction ||
      lastDeclaration?.declaredData?.contactName,
    companyName:
      lastDeclaration?.reviewData?.companyName?.correction ||
      lastDeclaration?.declaredData?.companyName,
    companyCountry:
      lastDeclaration?.reviewData?.companyCountry?.correction ||
      lastDeclaration?.declaredData?.companyCountry,
    modality:
      lastDeclaration?.reviewData?.modality?.correction ||
      lastDeclaration?.declaredData?.modality,
    typeOfService:
      lastDeclaration?.reviewData?.typeOfService?.correction ||
      lastDeclaration?.declaredData?.typeOfService,
    startDate:
      lastDeclaration?.reviewData?.startDate?.correction ||
      lastDeclaration?.declaredData?.startDate,
    incomeDocumentType:
      lastDeclaration?.reviewData?.incomeDocumentType?.correction ||
      lastDeclaration?.declaredData?.incomeDocumentType,
  };

  const hiddenValues = {
    id: declarationId,
    type: DeclarationType.UNEMPLOYMENT,
    startDates: Object.entries(userInfo.lastDeclarations).reduce(
      (
        acc,
        [
          companyName,
          {
            declaredData: { startDate: declaredStartDate },
            reviewData,
          },
        ]
      ) => {
        const reviewedStartDate = reviewData?.startDate;
        const startDate = reviewedStartDate || declaredStartDate;
        return {
          ...acc,
          [companyName]: startDate,
        };
      },
      {}
    ),
  };

  const handleSubmit = (step, values) => {
    const payload = {
      periodId: DDJJContext?.period[0].value,
      ...latestJobValues,
      ...values,
    };
    delete payload.startDates;
    onSubmit(step, payload);
  };

  const handleNextStep = (step, values) => {
    setSelectedCompany(values.companyName);
  };

  if (loadingDDJJContext) {
    return <Spinner />;
  }

  return (
    <Wizard
      steps={unemploymentFormSteps}
      startStep="companyStep"
      onSubmit={handleSubmit}
      onNextStep={handleNextStep}
      hiddenValues={hiddenValues}
      submitButtonText="Presentar"
      nextButtonText="Siguiente"
      previousButtonText="Atrás"
      CustomStepper={({ ...props }) => <Stepper {...props} onClose={onClose} />}
    />
  );
}

export default UnemploymentForm;
