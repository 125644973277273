import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { AxiosError } from 'axios';
import { useGetBackofficeDDJJContext } from '../services/collections.service';
import { Role } from '../common/enums';
import { DeclarationInstance, IDDJJContext } from '../common/interfaces';
import { useGeneralContext } from './GeneralContext';

const Context = createContext(null);

let selectedRoleRef: Role;

export function BackofficeContextProvider(props) {
  const { selectedRole } = useGeneralContext();

  useEffect(() => {
    selectedRoleRef = selectedRole;
  }, [selectedRole]);

  const {
    data: DDJJContext,
    loading: loadingDDJJContext,
    error: errorDDJJContext,
  } = useGetBackofficeDDJJContext();

  const [declarations, setDeclarations] = useState<DeclarationInstance[]>([]);

  const value = useMemo(() => {
    return {
      DDJJContext,
      errorDDJJContext,
      loadingDDJJContext,
      declarations,
      setDeclarations,
    };
  }, [
    DDJJContext,
    errorDDJJContext,
    loadingDDJJContext,
    declarations,
    setDeclarations,
  ]);

  return (
    <Context.Provider value={value} {...props}>
      {props.children}
    </Context.Provider>
  );
}

export function useBackofficeContext(
  { ignoreWarnings }: { ignoreWarnings?: boolean } = { ignoreWarnings: false }
) {
  const context: {
    DDJJContext: IDDJJContext;
    errorDDJJContext: AxiosError;
    loadingDDJJContext: boolean;
    declarations: DeclarationInstance[];
    setDeclarations: (declarations: DeclarationInstance[]) => void;
  } = useContext(Context);
  if (!context) {
    throw new Error('useBackofficeContext has to be inside a ContextProvider');
  }
  if (
    !!selectedRoleRef &&
    selectedRoleRef !== Role.backoffice &&
    !ignoreWarnings
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'You are accessing to BackofficeContext with non backoffice selected role'
    );
  }
  return context;
}
