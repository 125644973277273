function isEncrypted(file: File): Promise<boolean> {
  return new Promise((resolve, _reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const files = new Blob([reader.result], { type: 'application/pdf' });
      files.text().then((text) => {
        if (
          text.includes('Encrypt') ||
          text
            .substring(text.lastIndexOf('<<'), text.lastIndexOf('>>'))
            .includes('/Encrypt')
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    };
    reader.readAsArrayBuffer(file);
  });
}

export default isEncrypted;
