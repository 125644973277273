import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Comment from '@material-ui/icons/Comment';
import Delete from '@material-ui/icons/Delete';
import FindInPage from '@material-ui/icons/FindInPage';
import { Input, InputProps } from '@soyhenry/commons';
import { useTheme as useHenryTheme } from '@soyhenry/commons/dist/ThemeProvider';
import WithTooltip from '../WithTooltip';

export interface ObservationButtonProps
  extends Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin' | 'open'>,
    Omit<IconButtonProps, 'onChange' | 'name'> {
  name: string | number | symbol;
  value: string;
  fixed?: boolean;
  disabled?: boolean;
  tooltip?: string;
  isGeneral?: boolean;
  textboxProps?: Partial<InputProps>;
  onIsOpenChange: (name: string | number | symbol, value: boolean) => void;
  onChange?: (name: string | number | symbol, value: string) => void;
  onCleanValue?: (name: string | number | symbol) => void;
  onClose?: () => void;
}

function ObservationButton({
  onClose,
  onIsOpenChange,
  onChange,
  onCleanValue,
  value,
  textboxProps,
  isGeneral,
  open,
  name,
  fixed,
  disabled,
  tooltip,
  anchorOrigin,
  transformOrigin,
  'aria-label': ariaLabel,
}: ObservationButtonProps) {
  const henryTheme = useHenryTheme();
  const theme = useTheme();
  const buttonRef = useRef(null);
  const onClick = () => onIsOpenChange(name, !open);
  const isInputDisabled = !onChange;
  return (
    <>
      <WithTooltip tooltip={tooltip} withTooltip={tooltip && !disabled}>
        <div ref={buttonRef}>
          <IconButton
            component="div"
            onClick={onClick}
            disabled={disabled}
            style={{
              color: (!value
                ? henryTheme.palette.gray.main
                : henryTheme.palette.purple.main) as string,
            }}
            aria-label={ariaLabel}
          >
            {!isGeneral ? (
              <Comment style={{ fontSize: 30 }} />
            ) : (
              <FindInPage style={{ fontSize: 50 }} />
            )}
          </IconButton>
        </div>
      </WithTooltip>
      <Popover
        open={open || false}
        onClose={fixed ? undefined : () => onIsOpenChange(name, !open)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorEl={buttonRef.current}
      >
        <Box width="100%" px={2} py={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Observación {isGeneral ? 'general' : ''}</Typography>
          </Grid>
          <Grid container alignItems="center">
            <Grid item container direction="column" xs={12}>
              <Input
                disabled={isInputDisabled}
                type="textarea"
                name={name}
                label=""
                placeholder="Deja un comentario..."
                minRows={4}
                InputProps={{
                  style: {
                    backgroundColor: isInputDisabled
                      ? theme.palette.background.paper
                      : theme.palette.common.white,
                    color: isInputDisabled
                      ? theme.palette.common.white
                      : theme.palette.grey[900],
                  },
                }}
                variant="outlined"
                value={value}
                onChange={({ value, name }: { value: string; name }) =>
                  onChange(name, value)
                }
                {...textboxProps}
              />
              <Grid item container direction="row" justifyContent="flex-end">
                {!isInputDisabled && (
                  <Tooltip arrow title="Limpiar observación">
                    <IconButton
                      onClick={() => onCleanValue(name)}
                      style={{
                        padding: '4px',
                        color: (!value
                          ? henryTheme.palette.gray.light
                          : henryTheme.palette.red.dark) as string,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip arrow title="Guardar observación">
                  <IconButton
                    onClick={onClose}
                    style={{
                      padding: '4px',
                      color: (!value
                        ? henryTheme.palette.gray.light
                        : henryTheme.palette.green.dark) as string,
                    }}
                  >
                    <CheckCircle />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default ObservationButton;
