import React, { forwardRef } from 'react';
import Container from '@material-ui/core/Container';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/Close';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import MaterialTable, { Icons } from 'material-table';
import { createColumns } from './functions';
import { TableProps } from './interfaces';
import useIsThisScreen from '../../utils/useIsThisScreen';
import Header from '../TableHeader';
import Row from '../TableRow';

const tableIcons: Icons = {
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Close {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
};

function Table<Data extends object>({
  fields,
  tableStyle,
  ContainerProps,
  ...MTableProps
}: TableProps<Data>) {
  const isSmScreen = useIsThisScreen('sm');

  return (
    <Container
      maxWidth="xl"
      style={{ backgroundColor: 'inherit', ...ContainerProps?.style }}
      disableGutters={ContainerProps?.disableGutters || isSmScreen}
      {...ContainerProps}
    >
      <MaterialTable<Data>
        {...MTableProps}
        icons={MTableProps?.icons || tableIcons}
        localization={{
          body: {
            ...MTableProps?.localization?.body,
            emptyDataSourceMessage:
              MTableProps?.localization?.body?.emptyDataSourceMessage ||
              'No hay registros para mostrar',
          },
          pagination: {
            ...MTableProps?.localization?.pagination,
            labelRowsSelect:
              MTableProps?.localization?.pagination?.labelRowsSelect || 'filas',
          },
          toolbar: {
            ...MTableProps?.localization?.toolbar,
            searchPlaceholder:
              MTableProps?.localization?.toolbar?.searchPlaceholder || 'Buscar',
          },
          ...MTableProps?.localization,
        }}
        columns={createColumns(fields)}
        components={{
          Header,
          Row: (rowData) => (
            <Row
              rowData={rowData}
              fields={fields}
              onClick={rowData.onRowClick}
            />
          ),
          ...MTableProps?.components,
        }}
        style={tableStyle}
      />
    </Container>
  );
}

export default Table;
