import { MaterialTableProps } from 'material-table';
import { TableProps } from '../Table/interfaces';

type Payload<Data extends object> = MaterialTableProps<Data>['columns'];
type Fields<Data extends object> = TableProps<Data>['fields'];

export function createColumns<Data extends object>(
  fields: Fields<Data>
): Payload<Data> {
  return Object.keys(fields).map((field) => {
    const current = fields[field];

    if (typeof current === 'string') {
      return {
        title: current,
        field,
      };
    }
    return {
      title: current?.title || field,
      field,
      width: current?.width,
      sort: current?.sort !== undefined ? current.sort : true,
    };
  });
}
