import React from 'react';
import MuiModal, { ModalProps as MuiModalProps } from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import useIsThisScreen from '../../utils/useIsThisScreen';
import styles from './styles';

const useStyles = makeStyles(styles);

export interface ModalProps extends Omit<MuiModalProps, 'children'> {
  children: React.ReactNode;
}

export default function Modal(props: ModalProps) {
  const classes = useStyles();
  const isXsScreen = useIsThisScreen('xs');

  return (
    <MuiModal
      className={clsx([classes.root, props.className])}
      style={{ zIndex: 500 }}
      hideBackdrop={isXsScreen}
      {...props}
    >
      <>{props.children}</>
    </MuiModal>
  );
}
