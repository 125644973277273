import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    missingProfileDataBadge: {
      '& .MuiBadge-badge': {
        height: 24,
        width: 24,
        borderRadius: '50%',
      },
    },
    notificationsIcon: {
      fontSize: 34,
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        fontSize: 28,
      },
    },
    avatar: {
      border: `2px solid ${theme.palette.common.white}`,
      height: 50,
      width: 50,
      [theme.breakpoints.down('xs')]: {
        height: 38,
        width: 38,
      },
    },
    notificationsButton: {
      marginRight: 50,
      [theme.breakpoints.down('xs')]: {
        marginRight: 28,
      },
    },
    expandMoreIcon: {
      marginLeft: 10,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    root: {
      height: theme.mixins.toolbar.minHeight,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        height: theme.mixins.toolbar.xsMinHeight,
      },
    },
    icon: {
      fontSize: 34,
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.secondary.dark,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 28,
      },
    },
  })
);
