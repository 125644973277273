import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { getDeclarationTypeName } from '../../common/functions';
import { formatDate, statusNames } from '../GraduateDashboard/functions';
import { DeclarationStatus } from '../../common/enums';
import { DeclarationInstance } from '../../common/interfaces';
import Avatar from '../Avatar';

const ResumeField = ({
  label,
  value,
}: {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
}) => {
  return (
    <Box mr={1.5}>
      <Typography>
        {label}: <span style={{ fontWeight: 750, fontSize: 15 }}>{value}</span>
      </Typography>
    </Box>
  );
};

function DDJJResume({
  declaration: { graduate, id, type, period, status, declaredData, reviewData },
}: {
  declaration: DeclarationInstance;
}) {
  const isAcceptedDeclaration =
    status === DeclarationStatus.ACCEPTED ||
    status === DeclarationStatus.ACCEPTED_WITH_CORRECTIONS;

  const resumeDeclaration = {
    id,
    period: period.text,
    lastPresentationDate: formatDate(declaredData?.date, true),
    acceptedDate: isAcceptedDeclaration && formatDate(reviewData?.date),
    status: statusNames[status],
    type: getDeclarationTypeName(type),
    graduateEmail: graduate?.email,
    reviewer: reviewData?.reviewer,
  };

  return (
    <Box display="flex" alignItems="center">
      <Avatar size={60} src={graduate?.avatar} />
      <Box ml={2}>
        <Box>
          <Typography style={{ fontSize: 18, fontWeight: 900 }}>
            {graduate?.fullName || graduate?.email} (DJ #:{' '}
            {resumeDeclaration.id})
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" my={1}>
          <ResumeField label="Período" value={resumeDeclaration.period} />
          <ResumeField
            label="Ultima presentación"
            value={resumeDeclaration.lastPresentationDate}
          />
          <ResumeField label="Tipo" value={resumeDeclaration.type} />
          <ResumeField label="Status" value={resumeDeclaration.status} />
          <ResumeField label="Email" value={resumeDeclaration.graduateEmail} />
          {resumeDeclaration.reviewer && (
            <ResumeField label="Revisión" value={resumeDeclaration.reviewer} />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default DDJJResume;
