import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Input, InputProps } from '@soyhenry/commons';
import { getGraduatesSuggestions } from '../../../../services/collections.service';
import { useQuery } from '../../../../common/functions';
import { useAuth } from '../../../../providers/Auth';

export function TableToolbar({
  bulkSelection,
  onClickBulkApproval,
}: {
  bulkSelection?: boolean;
  onClickBulkApproval?: () => void;
}) {
  const { query, changeQuery } = useQuery();
  const { getAccessToken } = useAuth();
  const contractsUrl = process.env.REACT_APP_CONTRACTS_URL;

  const getOptions = useCallback(() => {
    return getGraduatesSuggestions(getAccessToken).then((res) =>
      res.data.concat([{ value: '', text: '' }])
    );
  }, [getAccessToken]);

  const handleChange = useCallback<InputProps['onChange']>(
    ({ value }) => {
      if (value === undefined || typeof value === 'string') {
        changeQuery({ name: 'search', value });
      }
    },
    [changeQuery]
  );

  return (
    <Paper style={{ padding: '0px 16px' }}>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        py={2}
        bgcolor="inherit"
      >
        <Box flexGrow={1} display="flex" alignItems="center">
          <Typography variant="h6" noWrap>
            Declaraciones juradas
          </Typography>

          {contractsUrl && (
            <Button
              style={{ marginLeft: 16 }}
              color="secondary"
              size="small"
              variant="contained"
              href={contractsUrl}
            >
              Contratos
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center" gridColumnGap={24}>
          {bulkSelection && (
            <Box>
              <Button
                color="secondary"
                size="small"
                variant="contained"
                onClick={onClickBulkApproval}
              >
                Aceptar todo
              </Button>
            </Box>
          )}
          <Box>
            <Input
              name="search"
              type="autocomplete"
              variant="outlined"
              placeholder="Buscar..."
              autoFocus
              InputProps={{ style: { borderRadius: 10, width: 272 } }}
              renderNewOptions={(input) => input}
              remoteOptions={getOptions}
              value={query.search || ''}
              onChange={handleChange}
              disableNewOptions
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default TableToolbar;
