import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircleOutlined';
import Cached from '@material-ui/icons/Cached';
import Error from '@material-ui/icons/Error';
import Exit from '@material-ui/icons/ExitToApp';
import { Role } from '../../../common/enums';
import { useAuth } from '../../../providers/Auth';
import { useGeneralContext } from '../../../providers/GeneralContext';
import { useGraduateContext } from '../../../providers/GraduateContext';
import Avatar from '../../Avatar';
import WithTooltip from '../../WithTooltip';
import ListItem from './ListItem';
import useStyles from './style';

export default function ProfileMenu({ open, onClose, anchorEl }) {
  const classes = useStyles();
  const history = useHistory();
  const { logOut } = useAuth();

  const { handleRole, selectedRole, isBackoffice, isGraduate, user } =
    useGeneralContext();
  const { areMissingProfileData } = useGraduateContext({
    ignoreWarnings: true,
  });

  const redirectToProfile = () => {
    history.push('/profile');
  };
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box
        padding={3}
        pb={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Avatar
            style={{ marginBottom: 8 }}
            name={user.given_name}
            size={75}
            src={user.picture}
          />
          <Typography variant="h6">
            {user.given_name} {user.family_name}
          </Typography>
        </Box>
        <Box width="100%">
          <List>
            {selectedRole === Role.graduate && (
              <ListItem
                onClick={redirectToProfile}
                text="Mi perfil"
                Icon={AccountCircle}
                SecondaryIcon={
                  areMissingProfileData &&
                  (() => (
                    <WithTooltip
                      withTooltip
                      tooltip="Tienes datos sin completar en tu perfil."
                    >
                      <Error className={classes.missingProfileDataIcon} />{' '}
                    </WithTooltip>
                  ))
                }
              />
            )}
            {isGraduate && isBackoffice && (
              <ListItem
                text={`Cambiar a rol de ${
                  selectedRole === Role.graduate ? 'Backoffice' : 'Graduado'
                }`}
                Icon={Cached}
                onClick={handleRole}
              />
            )}
            <MuiListItem disableGutters>
              <Button
                color="inherit"
                variant="text"
                className={classes.logoutButton}
                onClick={() => logOut()}
              >
                <Exit className={classes.exitIcon} />
                <Typography>Logout</Typography>
              </Button>
            </MuiListItem>
          </List>
        </Box>
      </Box>
    </Popover>
  );
}
