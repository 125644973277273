import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

export default function Spinner(props: CircularProgressProps) {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <CircularProgress color="secondary" size="100px" {...props} />
    </Grid>
  );
}
