import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Document, Page, pdfjs } from 'react-pdf';
import Controller from './Controller';
import useStyles from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfView({ src }: { src: string }) {
  const classes = useStyles();

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(null);
  const [isPasswordProtected, setIsPasswordProtected] =
    useState<boolean>(false);

  const onLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handleBackPage = () => {
    setPage(page - 1);
  };

  const handlePassword = () => {
    setIsPasswordProtected(true);
  };

  return (
    <Box
      bgcolor="rgba(128, 128, 128, 0.16);"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      display="flex"
      borderRadius={10}
    >
      {!isPasswordProtected ? (
        <Document
          file={src}
          loading={'Cargando...'}
          onLoadSuccess={onLoadSuccess}
          onPassword={handlePassword}
        >
          <Page className={classes.page} pageNumber={page} />
          <Controller
            page={page}
            totalPages={totalPages}
            onNextPage={handleNextPage}
            onBackPage={handleBackPage}
          />
        </Document>
      ) : (
        <Box padding="10px">
          <Typography variant="h6">Comprobante no disponible</Typography>
          <Typography paragraph>El documento requiere contraseña.</Typography>
        </Box>
      )}
    </Box>
  );
}

export default PdfView;
