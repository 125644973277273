import React from 'react';
import moment, { isMoment } from 'moment';
import NumberFormat from 'react-number-format';
import {
  getIncome,
  isIncome,
  isIncomeDocumentFile,
} from '../../common/functions';
import { FieldValue } from './interfaces';
import { useQuestions } from '../DDJJForm/questions';

export function useGetQuestionLabel() {
  const questions = useQuestions();
  return (questionName: string | number): string => {
    const { label } = Object.values(questions).find(
      ({ name }) => name === questionName
    );
    return typeof label === 'function' ? label({}) : label;
  };
}

export const parseFieldValue = (value: FieldValue) => {
  if (typeof value === 'string') {
    if (value.includes('https://')) {
      return (
        <a href={value} rel="noreferrer" target="_blank">
          Link
        </a>
      );
    }
    const date = moment(value);
    if (date.isValid()) {
      return date.format('DD/MM/YYYY');
    }
  }
  if (typeof value === 'object') {
    if (isIncome(value)) {
      return getIncome(value);
    }
    if (isIncomeDocumentFile(value)) {
      return (
        <a href={value.url} rel="noreferrer" target="_blank">
          Link
        </a>
      );
    }
    if (isMoment(value)) {
      return value.format('DD/MM/YYYY');
    }
  }
  if (typeof value === 'number') {
    return <NumberFormat value={value} thousandSeparator displayType="text" />;
  }
  return value;
};
