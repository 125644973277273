import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import { useGetBackofficeDDJJContext } from '../../services/collections.service';
import { getNeccesaryFields } from './functions';
import {
  DeclarationInstance,
  ItemsReviewData,
  QuestionName,
} from '../../common/interfaces';
import { QuestionItemProps } from '../QuestionItem/interfaces';
import QuestionItem from '../QuestionItem';
import Spinner from '../Spinner';
// TODO: this is only neccesary to make that QuestionItems does not change their height depending on incomeDocument or backofficeNote
// I need to apply height: fit-content & -moz-fit-content to work on chrome and mozilla
// how can make this better?
import './styles.modules.css';

interface QuestionItemsProps
  extends Pick<
    QuestionItemProps,
    | 'onStatusValuesChange'
    | 'onCorrectionChange'
    | 'onObservationChange'
    | 'onIsObservationOpenChange'
    | 'showTooltipIcon'
  > {
  declaration: DeclarationInstance;
  reviewableDeclaration: boolean;
  review: ItemsReviewData;
}

const BaseQuestionItems = ({
  fields,
  declaration,
  review,
  reviewableDeclaration,
  remoteOptions,
  spacing,
  questionItemProps,
}: {
  fields: QuestionName[];
  declaration: DeclarationInstance;
  review: ItemsReviewData;
  reviewableDeclaration: boolean;
  remoteOptions: {
    [questionName: string]: { text: string; value?: any }[];
  };
  questionItemProps: QuestionItemsProps;
  spacing?: GridProps['spacing'];
}) => {
  const reviewer = !reviewableDeclaration && declaration?.reviewData?.reviewer;

  return (
    <Grid container item xs={12} spacing={spacing || 0} className="wrapper">
      {fields.map((questionName) => {
        const declaredValue = declaration.declaredData?.[questionName];
        return (
          <Grid key={questionName} item xs={6}>
            <QuestionItem
              questionValue={declaredValue}
              questionName={questionName}
              reviewable={
                reviewableDeclaration ? 'all' : 'correctionAndObservation'
              }
              reviewer={reviewer}
              toCorrectRemoteOptions={remoteOptions}
              value={review?.[questionName]?.value}
              correctionValue={review?.[questionName]?.correction}
              isObservationOpen={review?.[questionName]?.openFieldObservation}
              observation={review?.[questionName]?.observation}
              {...questionItemProps}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

function QuestionItems({
  declaration,
  review,
  reviewableDeclaration,
  ...questionItemProps
}: QuestionItemsProps) {
  const { data: DDJJContext, loading } = useGetBackofficeDDJJContext();

  const remoteOptions: {
    [questionName: string]: { text: string; value?: any }[];
  } = DDJJContext && {
    companyName: DDJJContext.companyName,
  };

  const toCheckFields = getNeccesaryFields(declaration.type);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Grid container>
      <BaseQuestionItems
        fields={toCheckFields}
        declaration={declaration}
        review={review}
        reviewableDeclaration={reviewableDeclaration}
        remoteOptions={remoteOptions}
        questionItemProps={{
          declaration,
          reviewableDeclaration,
          review,
          ...questionItemProps,
        }}
        spacing={1}
      />
    </Grid>
  );
}

export default QuestionItems;
