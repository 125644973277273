// TODO: try to not import from dist
import { TemplateQuestion } from '@soyhenry/commons/dist/Wizard';
import moment from 'moment';
import * as yup from 'yup';
import { getDDJJContext, upload } from '../../services/collections.service';
import {
  JobModalities,
  DeclarationType,
  IncomeDocumentTypes,
} from '../../common/enums';
import { IDDJJContext } from '../../common/interfaces';
import currencies from '../../common/currencies.json';
import { useAuth } from '../../providers/Auth';
import countries from '../../utils/countries';
import typeOfServices from '../../utils/typeOfServices';

export function useQuestions(options = []) {
  const { getAccessToken } = useAuth();

  const notDeclaredType: TemplateQuestion = {
    type: 'buttons',
    name: 'type',
    label: (values: {
      variant: 'outlined';
      periodId?: number;
      periods?: { value: number; text: string }[];
      lastCompanyName?: string;
    }) => {
      const selectedPeriodText = values.periods?.find(
        ({ value }) => values.periodId === value
      )?.text;
      return `Cuéntanos tu situación laboral ${
        selectedPeriodText ? `en ${selectedPeriodText}` : ''
      }`;
    },
    direction: 'row',
    options: [
      {
        text: 'Estoy desempleado',
        value: DeclarationType.STAY_UNEMPLOYMENT,
        color: 'secondary',
      },
      {
        text: 'Tengo empleo',
        value: DeclarationType.NEW_EMPLOYMENT,
        color: 'primary',
      },
    ],
    validations: yup.string().required(),
    GridItemProps: {
      xs: 12,
      style: { marginTop: 16 },
    },
  };

  const employmentType: TemplateQuestion = {
    type: 'buttons',
    name: 'type',
    label: (values: {
      variant: 'outlined';
      periodId?: number;
      periods?: { value: number; text: string }[];
      lastCompanyName?: string;
    }) => {
      return `En el período anterior tenias una relación laboral con ${values.lastCompanyName}. Contanos tu situación actual.`;
    },
    helperText: (values) =>
      values.type === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT &&
      'Realizarás primero una declaración de baja de tu empleo actual.',
    options: [
      {
        text: 'Ahora estoy desempleado',
        value: DeclarationType.UNEMPLOYMENT,
      },
      {
        text: 'Cambié de empleo',
        value: DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT,
      },
      {
        text: 'Mantengo mi empleo',
        value: DeclarationType.STAY_EMPLOYED,
      },
    ],
    validations: yup.string().required(),
  };

  const multiemploymentType: TemplateQuestion = {
    type: 'buttons',
    name: 'type',
    label: (values: {
      variant: 'outlined';
      periodId?: number;
      periods?: { value: number; text: string }[];
      lastCompanyName?: string;
    }) => {
      return `En el período anterior tenias una relación laboral con ${values.lastCompanyName}. Contanos tu situación actual.`;
    },
    helperText: (values) =>
      values.type === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT &&
      'Realizarás primero una declaración de baja de tu empleo actual.',
    options: [
      {
        text: 'Ya no tengo este empleo',
        value: DeclarationType.UNEMPLOYMENT,
      },
      {
        text: 'Mantengo este empleo',
        value: DeclarationType.STAY_EMPLOYED,
      },
    ],
    validations: yup.string().required(),
  };

  const incomeChange: TemplateQuestion = {
    type: 'buttons',
    name: 'type',
    variant: 'outlined',
    label: (values) =>
      `Tus ingresos en el periodo anterior son de ${values.incomeCurrency} ${values.incomeAmount}. Cuéntanos sobre tus ingresos actuales`,
    options: [
      {
        text: 'Mis ingresos cambiaron',
        value: DeclarationType.STAY_EMPLOYED_INCOME_CHANGED,
      },
      {
        text: 'Mantengo mis ingresos',
        value: DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED,
      },
    ],
    ButtonsProps: {
      style: {
        margin: '.5rem 0',
      },
    },
    validations: yup.string().required(),
  };

  const startDateNewIncome: TemplateQuestion = {
    type: 'date',
    name: 'startDateNewIncome',
    variant: 'outlined',
    label: `Fecha de inicio de tu nuevo ingreso`,
    defaultValue: moment(),
    format: 'DD/MM/YYYY',
    validations: yup
      .string()
      .required('La fecha de inicio de tu nuevo ingreso es requerida'),
  };

  const companyName: TemplateQuestion = {
    name: 'companyName',
    type: 'autocomplete',
    label: 'Empleador',
    variant: 'outlined',
    validations: yup.string().required('El empleador es requerido').max(90),
    remoteOptions: () => {
      return getDDJJContext(getAccessToken).then(
        (response: { data: IDDJJContext }) => response.data.companyName
      );
    },
    renderNewOptions: (value: string) => `Añadir "${value}"`,
  };

  const currentCompanyName: TemplateQuestion = {
    name: 'companyName',
    type: 'autocomplete',
    label: 'Empleador',
    variant: 'outlined',
    validations: yup.string().required('El empleador es requerido').max(90),
    disableNewOptions: true,
    options,
  };

  const companyCountry: TemplateQuestion = {
    type: 'autocomplete',
    name: 'companyCountry',
    label: 'País',
    variant: 'outlined',
    validations: yup.string().required('El país es requerido'),
    defaultValue: countries[0],
    disableNewOptions: true,
    options: Object.values(countries).map((country) => ({
      text: country,
    })),
  };

  const contactName: TemplateQuestion = {
    type: 'text',
    name: 'contactName',
    label: 'Nombre del empleador',
    variant: 'outlined',
    validations: yup
      .string()
      .max(90, 'El nombre del empleador del contacto es demasiado extenso'),
  };

  const contactEmail: TemplateQuestion = {
    type: 'text',
    name: 'contactEmail',
    label: 'Email del empleador',
    variant: 'outlined',
    validations: yup
      .string()
      .email('El email del empleador no es válido')
      .max(90),
  };

  const modality: TemplateQuestion = {
    type: 'select',
    name: 'modality',
    label: 'Relación',
    variant: 'outlined',
    options: [
      {
        text: 'Empleado',
        value: JobModalities.EMPLEADO,
      },
      {
        text: 'Freelancer',
        value: JobModalities.FREELANCER,
      },
      {
        text: 'Proveedor de servicios',
        value: JobModalities.PROVEEDOR,
      },
    ],
    validations: yup.string().required('La modalidad es requerida'),
  };

  const typeOfService: TemplateQuestion = {
    type: 'autocomplete',
    name: 'typeOfService',
    label: 'Puesto / Función',
    variant: 'outlined',
    validations: yup
      .string()
      .max(90, 'El puesto es demasiado extenso')
      .required('El puesto es requerido'),
    options: Object.values(typeOfServices).map((value) => ({ text: value })),
  };

  const startDate: TemplateQuestion = {
    type: 'date',
    name: 'startDate',
    label: 'Fecha de inicio',
    variant: 'outlined',
    defaultValue: moment(),
    maxDate: moment().add(1, 'M').date(0).toDate(),
    format: 'DD/MM/YYYY',
    validations: yup.string().required('La fecha de inicio es requerida'),
  };

  const endDate: TemplateQuestion = {
    type: 'date',
    name: 'endDate',
    label: 'Fecha de baja',
    variant: 'outlined',
    defaultValue: moment(),
    disableFutureDates: true,
    format: 'DD/MM/YYYY',
    // orientation: 'landscape',
    validations: yup.string().required('La fecha de baja es requerida'),
  };

  const incomeAmount: TemplateQuestion = {
    name: 'incomeAmount',
    label: 'Ingreso bruto',
    variant: 'outlined',
    type: 'number',
    defaultValue: 0,
    ContainerProps: { xs: 6 },
    validations: yup.number().required().min(1, 'Es requerido'),
  };

  const incomeCurrency: TemplateQuestion = {
    name: 'incomeCurrency',
    label: 'Moneda',
    variant: 'outlined',
    type: 'autocomplete',
    validations: yup.string().required(),
    options: Object.keys(currencies).map((code) => ({
      text: `${code} (${currencies[code].es})`,
      value: code,
    })),
    renderSelectedOption: (option?: { value: string; text: string }) =>
      option?.value,
    ContainerProps: { xs: 6 },
    disableNewOptions: true,
  };

  const incomeDocumentFile: TemplateQuestion = {
    name: 'incomeDocumentFile',
    label: 'Comprobante',
    variant: 'outlined',
    type: 'file',
    inputProps: {
      accept: 'application/pdf, image/jpeg, image/png',
    },
    helperText:
      'No es obligatorio que subas el comprobante ahora. Sin embargo tu declaración será observada cuando la presentes y deberás completarlo luego.',
    disabled: (values) => !values.incomeDocumentType,
    upload: (value) =>
      upload('incomeDocument', value, getAccessToken)
        .then(({ data }) => {
          return Object.assign(value, data);
        })
        .catch((err) => alert(err)),
  };

  const incomeDocumentType: TemplateQuestion = {
    name: 'incomeDocumentType',
    label: 'Tipo de comprobante',
    variant: 'outlined',
    type: 'autocomplete',
    options: Object.values(IncomeDocumentTypes).map((value: string) => ({
      text: value,
      value,
    })),
  };

  return {
    multiemploymentType,
    notDeclaredType,
    employmentType,
    incomeChange,
    companyName,
    currentCompanyName,
    companyCountry,
    contactName,
    contactEmail,
    modality,
    typeOfService,
    startDate,
    endDate,
    incomeCurrency,
    incomeAmount,
    incomeDocumentFile,
    incomeDocumentType,
    startDateNewIncome,
  };
}
