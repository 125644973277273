import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 80,
      [theme.breakpoints.up('sm')]: {
        height: 50,
        width: 'auto',
      },
    },
  })
);
