import React, { CSSProperties } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import { ReviewValue } from '../Field/interfaces';
import ObservationButton from '../ObservationButton';
import styles from './styles';

const useStyles = makeStyles(styles);

export interface ItemStatusProps {
  questionName: string;
  value: ReviewValue;
  isObservationOpen: boolean;
  observation: string;
  style?: CSSProperties;
  reviewable?: 'correctionAndObservation' | 'all';
  onStatusValuesChange?: (questionName: string, value: ReviewValue) => void;
  onObservationChange?: (questionName: string, value: string) => void;
  onIsObservationOpenChange?: (questionName: string, value: boolean) => void;
}

// TODO: think better name
const ItemStatus = ({
  questionName,
  value,
  observation,
  isObservationOpen,
  reviewable,
  style,
  onStatusValuesChange,
  onObservationChange,
  onIsObservationOpenChange,
}: ItemStatusProps) => {
  const classes = useStyles();

  const handleClick = (
    newValue: ReviewValue.ACCEPTED | ReviewValue.REJECTED
  ) => {
    onStatusValuesChange(
      questionName,
      value === newValue ? ReviewValue.NOT_SELECTED : newValue
    );
  };

  const isAccepted = value === ReviewValue.ACCEPTED;
  const isRejected = value === ReviewValue.REJECTED;

  const cleanObservation = (name: string) => onObservationChange(name, '');

  return (
    <>
      {value !== ReviewValue.PENDING && (
        <Box
          style={style}
          width={100}
          display="flex"
          justifyContent="space-between"
        >
          {reviewable === 'all' && (
            <IconButton
              data-testid={`rejectButtonField-${questionName}`}
              onClick={() => handleClick(ReviewValue.REJECTED)}
            >
              <Cancel
                className={clsx([
                  classes.icon,
                  isRejected && classes.iconReject,
                ])}
              />
            </IconButton>
          )}
          {reviewable === 'all' && (
            <IconButton
              data-testid={`acceptButtonField-${questionName}`}
              onClick={() => handleClick(ReviewValue.ACCEPTED)}
            >
              <CheckCircle
                className={clsx([
                  classes.icon,
                  isAccepted && classes.iconAccept,
                ])}
              />
            </IconButton>
          )}
          <ObservationButton
            open={isObservationOpen}
            name={questionName}
            value={observation}
            tooltip={observation ? 'Campo observado' : 'Realizar observación'}
            onChange={onObservationChange}
            onCleanValue={() => cleanObservation(questionName)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onIsOpenChange={onIsObservationOpenChange}
            onClose={() => onIsObservationOpenChange(questionName, false)}
          />
        </Box>
      )}
    </>
  );
};

export default ItemStatus;
