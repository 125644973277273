import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& div': {
        scrollbarWidth: 'none',
      },
      '& div::-webkit-scrollbar': {
        width: 0,
      },
      padding: '1rem 0',
      boxShadow: 'none',
      '& .MuiTable-root': {
        borderSpacing: '0 10px',
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
          borderCollapse: 'separate',
          // @ts-ignore
          backgroundColor: theme.palette.primary.lighterGrey,
        },
      },
      '& .MuiTableRow-head': {
        border: '1px solid #DBDBDB',
        // @ts-ignore
        backgroundColor: theme.palette.primary.lighterGrey,
        [theme.breakpoints.down('xs')]: {
          backgroundColor: 'inherit',
          border: 'none',
        },
      },
      '& .MuiTableRow-root th:first-child': {
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
      },
      '& .MuiTableRow-root th:last-child': {
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
      },
      '& .MuiTableRow-root td:first-child': {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      '& .MuiTableRow-root td:last-child': {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
      '& .MuiPaper-elevation2': {
        boxShadow: 'none',
      },
    },
    deleteConfirmationTitle: {
      '& .MuiTypography-root': {
        fontSize: 18,
        fontWeight: 900,
        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
        },
      },
    },
    dialog: {
      '& .MuiDialog-paper': {
        maxWidth: 400,
      },
    },
    menu: {
      '& .MuiMenu-paper': {
        borderRadius: 8,
        padding: '0px 20px',
      },
      '& .MuiListItem-root': {
        padding: 0,
        fontSize: 12,
        fontWeight: 750,
      },
      '& .MuiList-padding': {
        padding: 0,
      },
    },
  })
);

export default useStyles;
