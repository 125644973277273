import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { History } from '../../common/interfaces';
import DeclarationHistory from '../DeclarationHistory';

function DeclarationHistoryButton({
  declarationHistory,
  selectedIndex,
  onClickItem,
}: {
  declarationHistory: History;
  selectedIndex?: number;
  onClickItem?: (selectedIndex: number) => void;
}) {
  const historyButton = useRef();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const [localSelectedIndex, setLocalSelectedIndex] = useState(0);

  const getSelectedIndex = () =>
    selectedIndex !== undefined ? selectedIndex : localSelectedIndex;

  const toggleDeclarationHistory = () => {
    setIsHistoryOpen(!isHistoryOpen);
  };

  return (
    <>
      <Button
        onClick={toggleDeclarationHistory}
        color="primary"
        ref={historyButton}
      >
        Ver Historial
      </Button>
      <Popover
        onClose={toggleDeclarationHistory}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isHistoryOpen}
        anchorEl={historyButton.current}
      >
        <DeclarationHistory
          history={declarationHistory?.history}
          createdByUser={declarationHistory?.deletable}
          selectedIndex={getSelectedIndex()}
          onClickItem={
            onClickItem ||
            ((selectedIndex) => setLocalSelectedIndex(selectedIndex))
          }
          itemProps={{
            width: '100%',
            minHeight: 85,
          }}
        />
      </Popover>
    </>
  );
}

export default DeclarationHistoryButton;
