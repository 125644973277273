import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useGetQuestionLabel } from './functions';
import { ReviewValue } from '../Field/interfaces';
import { QuestionItemProps, FieldValue } from './interfaces';
import useIsThisScreen from '../../utils/useIsThisScreen';
import UpdatableField from '../Field/UpdatableField';
import { UpdateFieldButtons } from '../Field/UpdateInput';
import ItemStatus from './ItemStatus';

export default function QuestionItem({
  questionName,
  questionValue,
  correctionValue,
  reviewable,
  value,
  reviewer,
  toCorrectRemoteOptions,
  showTooltipIcon,
  onCorrectionChange,
  onStatusValuesChange,
  onObservationChange,
  ...itemStatusProps
}: QuestionItemProps) {
  const isXsScreen = useIsThisScreen('xs');
  const getQuestionLabel = useGetQuestionLabel();

  const [correction, setCorrection] = useState<{
    isOpen: boolean;
    localValue: FieldValue;
  }>({
    isOpen: false,
    localValue: '',
  });
  const isCorrectionOpen = correction.isOpen;
  const openCorrection = () =>
    setCorrection({
      ...correction,
      isOpen: true,
    });
  const closeCorrection = () => {
    setCorrection({
      ...correction,
      isOpen: false,
    });
  };

  useEffect(() => {
    setCorrection((correction) => ({
      ...correction,
      localValue:
        value === ReviewValue.ACCEPTED ? correctionValue : questionValue,
    }));
  }, [correction.isOpen, correctionValue, questionValue, value]);

  const onChangeUpdateField = (value) => {
    setCorrection({ ...correction, localValue: value });
  };
  const onConfirmUpdateField = () => {
    onCorrectionChange(
      questionName,
      correction.localValue !== questionValue ? correction.localValue : ''
    );
    closeCorrection();
  };

  const hasCorrection: boolean =
    value === ReviewValue.ACCEPTED &&
    !!correctionValue &&
    correctionValue !== questionValue;
  const label = getQuestionLabel(questionName);

  return (
    <Grid container spacing={3}>
      <Grid item xs={!reviewable ? 12 : 8}>
        <Box
          p={!isXsScreen && 1}
          style={{ overflowWrap: 'anywhere' }}
          textAlign={isXsScreen ? 'center' : 'left'}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <UpdatableField
              isCorrectionOpen={isCorrectionOpen}
              value={
                (!isCorrectionOpen
                  ? hasCorrection
                    ? correctionValue
                    : questionValue
                  : correction.localValue) || 'Pendiente'
              }
              label={label}
              defaultValue={questionValue}
              remoteOptions={toCorrectRemoteOptions}
              questionName={questionName}
              onChange={onChangeUpdateField}
              editable={!!reviewable}
              onClickEdit={openCorrection}
              color={
                (
                  !isCorrectionOpen
                    ? hasCorrection
                      ? correctionValue
                      : questionValue
                    : correction.localValue
                )
                  ? 'primary'
                  : 'textSecondary'
              }
              tooltip={
                showTooltipIcon &&
                (hasCorrection ||
                  value === ReviewValue.REJECTED ||
                  value === ReviewValue.PENDING) ? (
                  <span>
                    Este campo fue{' '}
                    {value === ReviewValue.PENDING
                      ? 'marcado como pendiente'
                      : hasCorrection
                      ? 'corregido'
                      : 'rechazado'}
                    . <br />
                    {itemStatusProps?.observation &&
                      `Observaciones: ${itemStatusProps.observation}`}
                    {hasCorrection && `Valor anterior: ${correctionValue}`}
                  </span>
                ) : (
                  itemStatusProps?.observation &&
                  !reviewable && (
                    <span>
                      {`Observaciones: ${itemStatusProps.observation}`}
                    </span>
                  )
                )
              }
            />
            {isCorrectionOpen && (
              <UpdateFieldButtons
                onConfirm={onConfirmUpdateField}
                onCancel={closeCorrection}
              />
            )}
          </Box>
        </Box>
      </Grid>
      {reviewable && (
        <Grid item xs={4}>
          <ItemStatus
            value={value}
            style={isCorrectionOpen ? { display: 'none' } : {}}
            questionName={questionName}
            onStatusValuesChange={onStatusValuesChange}
            // this disable textarea input when is not reviewable
            onObservationChange={reviewable ? onObservationChange : null}
            reviewable={reviewable}
            {...itemStatusProps}
          />
        </Grid>
      )}
    </Grid>
  );
}
