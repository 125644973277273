import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useIsThisScreen = (breakpoint: Breakpoint | number) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
};

export default useIsThisScreen;
