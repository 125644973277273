import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RotateLeft from '@material-ui/icons/RotateLeft';
import { TooltipIconWrapper, Input } from '@soyhenry/commons';
// TODO: try to not use dist
import { TemplateQuestion } from '@soyhenry/commons/dist/Wizard';
import { UpdateFieldButtonsProps, UpdateInputProps } from './interfaces';
import { useQuestions } from '../DDJJForm/questions';
import useStyles from './styles';

const isOptions = (array: any): array is { value?: unknown; text: string }[] =>
  array.every((obj) => obj.text);

export const UpdateFieldButtons = ({
  onCancel,
  onConfirm,
}: UpdateFieldButtonsProps) => {
  return (
    <Box
      ml={1}
      mb={1.5}
      width={55}
      display="flex"
      justifyContent="space-between"
    >
      <IconButton onClick={onCancel}>
        <Cancel style={{ fontSize: 20 }} />
      </IconButton>
      <IconButton onClick={onConfirm}>
        <CheckCircle style={{ fontSize: 20 }} />
      </IconButton>
    </Box>
  );
};

function UpdateInput({
  value,
  defaultValue,
  questionName,
  error,
  remoteOptions,
  label,
  tooltip,
  tooltipIcon,
  onChange,
}: UpdateInputProps) {
  const classes = useStyles();

  const onChangeLocalValue = ({ value, name }) => {
    onChange(value);
  };

  const questions = useQuestions();

  const question: TemplateQuestion = questions[questionName];

  const type =
    typeof question.type === 'function' ? question.type({}) : question.type;

  const baseInputProps = {
    name: question.name,
    type,
    className: classes.input,
    onChange: onChangeLocalValue,
    value: value || '',
    defaultValue,
    options:
      remoteOptions[questionName] ||
      (question.options && isOptions(question.options) && question.options),
    error,
    variant:
      typeof question.variant !== 'function'
        ? question.variant
        : question.variant({}),
    upload: question.upload,
    fullWidth: true,
    InputProps: {
      endAdornment: (
        <IconButton
          onClick={() =>
            onChangeLocalValue({
              value: defaultValue,
              name: question.name,
            })
          }
          style={{ marginRight: 10, marginTop: 4 }}
        >
          <RotateLeft />
        </IconButton>
      ),
      style: type === 'autocomplete' ? { paddingRight: 0 } : {},
    },
  };
  return (
    <TooltipIconWrapper tooltip={tooltip} icon={tooltipIcon}>
      <Box width="100%">
        {/** pass inputProps to other inputs break them, especially autocomplete that lost the inputRef.current in mui package */}
        {question.type === 'file' ? (
          <Input {...baseInputProps} inputProps={question.inputProps} />
        ) : (
          <Input {...baseInputProps} />
        )}
        <Typography style={{ fontSize: 13 }} color="textSecondary">
          {label}
        </Typography>
      </Box>
    </TooltipIconWrapper>
  );
}

export default UpdateInput;
