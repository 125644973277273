import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const headerColor = '#757575';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    acceptButtonTable: {
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
    tableCellHeader: {
      fontWeight: 900,
      border: 'none',
      padding: 8,
      fontSize: 14,
      borderTop: `1px solid ${headerColor}`,
      borderBottom: `1px solid ${headerColor}`,
      color: headerColor,
      cursor: 'pointer',
    },
    disableReorder: {
      cursor: 'auto',
    },
    borderBottom: {
      '&.MuiTableCell-root': {
        borderBottom: '1px solid #DBDBDB',
      },
    },
    tableCellBody: {
      maxWidth: 150,
      padding: '10px 8px',
      fontSize: 14,
      fontWeight: 900,
      backgroundColor: theme.palette.common.white,
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
      '&.MuiTableCell-root': {
        maxHeight: 75,
      },
    },
    table: {
      '& div': {
        scrollbarWidth: 'none',
      },
      '& div::-webkit-scrollbar': {
        width: 0,
      },
      padding: '1rem 0',
      boxShadow: 'none',
      '& .MuiTable-root': {
        borderCollapse: 'inherit',
        borderSpacing: '0 6px',
        backgroundColor: 'white',
        paddingTop: '0.5rem',
      },
      '& .MuiTableRow-root th:first-child': {
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
      },
      '& .MuiTableRow-root th:last-child': {
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
      },
      '& .MuiTableRow-root td:first-child': {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      '& .MuiTableRow-root td:last-child': {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
      '& .MuiPaper-elevation2': {
        boxShadow: 'none',
      },
      '& .MuiPaper-root': {
        padding: '0 10px',
      },
    },
  })
);

export default useStyles;
