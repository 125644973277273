import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px -3px 3px 0px #0000001A',
      // TODO move footer height to theme
      height: '50px',
      width: '100%',
      zIndex: theme.zIndex.appBar,
      [theme.breakpoints.down('sm')]: {
        borderRadius: '30px 30px 0 0',
        bottom: 0,
        left: 0,
        position: 'fixed',
      },
    },
    contentContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      // TODO move footer height to theme
      height: '50px',
      justifyContent: 'space-around',
      position: 'relative',
    },
    text: {
      alignItems: 'center',
      color: theme.palette.common.white,
      display: 'flex',
      fontSize: '16px',
      fontWeight: 750,
      justifyContent: 'center',
      textAlign: 'center',
      width: '150px',
    },
    circle: {
      animation: `$footerGrow 500ms ${theme.transitions.easing.easeInOut}`,
      border: `6px ${theme.palette.grey[50]} solid`,
      borderRadius: '50%',
      boxShadow: 'none',
      boxSizing: 'content-box',
      height: '60px',
      left: '50%',
      padding: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%) translateY(-20px) scale(1)',
      width: '60px',
    },
    buttonIcon: {
      fontSize: '36px',
    },
    logoContainer: {
      background: theme.palette.primary.main,
    },
    logo: {
      height: '60px',
      transform: 'translate(2px,-2px)',
    },
    '@keyframes footerGrow': {
      '0%': { transform: 'translate(-50%, -50%) translateY(-20px) scale(0)' },
      '100%': { transform: 'translate(-50%, -50%) translateY(-20px) scale(1)' },
    },
    buttonUnmount: {
      animation: `$footerUnGrow 500ms ${theme.transitions.easing.easeInOut}`,
      transform: 'translate(-50%, -50%) translateY(-20px) scale(0)',
      pointerEvents: 'none',
    },
    '@keyframes footerUnGrow': {
      '0%': { transform: 'translate(-50%, -50%) translateY(-20px) scale(1)' },
      '100%': { transform: 'translate(-50%, -50%) translateY(-20px) scale(0)' },
    },
  })
);
