import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Role } from '../common/enums';
import { DecodedAccessToken, DecodedIdToken, useAuth } from './Auth';

const { REACT_APP_BOTH_ROLES_DEFAULT_ROLE: defaultRole } = process.env;

const Context = createContext(null);

export function GeneralContextProvider(props) {
  const { isLoading, isAuthenticated, user } = useAuth();

  const isBackoffice = user?.['cognito:groups']?.includes(Role.backoffice);
  const isGraduate = user?.['cognito:groups']?.includes(Role.graduate);
  const isBoth = isBackoffice && isGraduate;

  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  const getRole = useCallback(() => {
    setSelectedRole(
      isBoth &&
        (defaultRole === Role.backoffice || defaultRole === Role.graduate)
        ? defaultRole
        : isBackoffice
        ? Role.backoffice
        : isGraduate
        ? Role.graduate
        : null
    );
  }, [isBackoffice, isGraduate, isBoth]);

  useEffect(() => {
    getRole();
  }, [getRole]);

  const handleRole = useCallback(() => {
    if (isBackoffice && isGraduate) {
      setSelectedRole(
        selectedRole === Role.graduate ? Role.backoffice : Role.graduate
      );
    }
  }, [isBackoffice, isGraduate, selectedRole]);

  // isModal
  const location = useLocation<{ modal: boolean }>();
  const [previousLocation, setPreviousLocation] =
    useState<typeof location>(null);
  const isModal =
    location.state && location.state.modal && previousLocation !== location;

  useEffect(() => {
    if (!(location.state && location.state.modal)) {
      setPreviousLocation(location);
    }
  }, [location]);

  const value = useMemo(() => {
    return {
      user,
      selectedRole,
      isLoading,
      isAuthenticated,
      isGraduate,
      isBackoffice,
      handleRole,
      isModal,
      location,
      previousLocation,
      setPreviousLocation,
    };
  }, [
    selectedRole,
    user,
    isLoading,
    isAuthenticated,
    isGraduate,
    isBackoffice,
    handleRole,
    isModal,
    location,
    previousLocation,
    setPreviousLocation,
  ]);

  return (
    <Context.Provider value={value} {...props}>
      {props.children}
    </Context.Provider>
  );
}

export function useGeneralContext() {
  const context: {
    isBackoffice: boolean;
    isGraduate: boolean;
    selectedRole: Role | null;
    user: DecodedIdToken;
    handleRole: () => void;
    isLoading: boolean;
    isAuthenticated: boolean;
    isModal: boolean;
    location;
    previousLocation;
    setPreviousLocation: (previousLocation) => void;
  } = useContext(Context);
  if (!context) {
    throw new Error('useGeneralContext has to be inside a ContextProvider');
  } else return context;
}
