import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { NotificationsListProps, NotificationType } from './interfaces';
import notificationTexts from './notificationTexts';
import useStyles from './styles';

const redirectPathByNotificationType = {
  [NotificationType.A_DECLARATION_NEEDS_TO_BE_COMPLETED]: 'form',
  [NotificationType.A_DECLARATION_WAS_REJECTED]: 'detail',
  [NotificationType.A_DECLARATION_WAS_ACCEPTED]: 'detail',
  [NotificationType.A_DECLARATION_WAS_ACCEPTED_WITH_CORRECTIONS]: 'detail',
  [NotificationType.A_DECLARATION_WAS_ACCEPTED_WITH_PENDINGS]: 'detail',
};

export default function NotificationsList({
  notifications,
  onClickDeleteNotification,
  onClose,
}: NotificationsListProps) {
  const history = useHistory();
  const classes = useStyles();

  const redirect = (
    declarationId: number,
    notificationType: NotificationType
  ) => {
    onClose();
    history.replace(
      `declaration/${declarationId}${
        redirectPathByNotificationType[notificationType] === 'form'
          ? '/form'
          : ''
      }`
    );
  };

  return (
    <List>
      {notifications.map((notification, i) => {
        const notificationText = notificationTexts[notification.type](
          notification.declarationId
        );
        const notificationDate = moment(notification.createdAt).format(
          'DD-MM-YYYY'
        );
        return (
          <Fragment key={notification.id}>
            <ListItem
              button
              onClick={() =>
                redirect(notification.declarationId, notification.type)
              }
            >
              <Box>
                <Typography variant="subtitle2">{notificationText}</Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {notificationDate}
                </Typography>
              </Box>
              <ListItemSecondaryAction
                className={classes.deleteNotificationIcon}
              >
                <IconButton
                  onClick={() => onClickDeleteNotification(notification.id)}
                >
                  <ClearIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {i !== notifications.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </List>
  );
}
