import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid`,
      borderRadius: 16,
      backgroundColor: 'inherit',
      fontSize: 13,
      fontWeight: 900,
      padding: '5px 18px',
      [theme.breakpoints.down('md')]: {
        padding: '5px 16px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '5px 7px',
      },
      '& p': {
        fontSize: 13,
        [theme.breakpoints.down('md')]: {
          fontSize: 10.4,
        },
      },
    },
    pending: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    inReview: {
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
    },
    accepted: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    draft: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
  })
);

export default useStyles;
