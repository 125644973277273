import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const headerColor = '#757575';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellHeader: {
      fontWeight: 900,
      border: 'none',
      padding: 8,
      fontSize: 14,
      borderTop: `1px solid ${headerColor}`,
      borderBottom: `1px solid ${headerColor}`,
      color: headerColor,
      cursor: 'pointer',
    },
    disableReorder: {
      cursor: 'auto',
    },
  })
);

export default useStyles;
