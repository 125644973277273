import React, { useEffect, useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export interface WarningErrorProps {
  errors: { message: string; index: number }[];
  onClose: (index: number) => void;
}

export default function WarningError({ errors, onClose }: WarningErrorProps) {
  const [open, setOpen] = useState<{ [x: number]: boolean }>({});
  useEffect(() => {
    setOpen((open) =>
      errors.reduce(
        (open, { index }) => ({
          ...open,
          [index]: true,
        }),
        open
      )
    );
  }, [errors]);
  return (
    <>
      {errors.map(({ message, index }, i) => {
        const handleClose = (_event, reason?: SnackbarCloseReason) => {
          if (reason === 'clickaway') return;
          setOpen({
            ...open,
            [index]: false,
          });
          setTimeout(() => onClose(index), 200);
        };
        return (
          <Snackbar
            key={index}
            open={open[index]}
            onClose={handleClose}
            autoHideDuration={15000}
            transitionDuration={100}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            style={{
              transform: `translateY(-${i * 55}px)`,
            }}
          >
            <Alert
              style={{ minWidth: '300px' }}
              onClose={handleClose}
              severity="warning"
            >
              {message}
            </Alert>
          </Snackbar>
        );
      })}
    </>
  );
}
