import React from 'react';
import Box from '@material-ui/core/Box';
import { Input } from '@soyhenry/commons';
import { useQuery } from '../../../../common/functions';
import { useBackofficeContext } from '../../../../providers/BackofficeContext';
import FilterLabel from '../FilterLabel';

export default function PeriodIdFilter() {
  const { DDJJContext } = useBackofficeContext();
  const { query, changeQuery } = useQuery();

  return (
    <>
      {DDJJContext?.period && (
        <Box mb={0.5}>
          <FilterLabel>Periodo</FilterLabel>
          <Input
            type="select"
            variant="outlined"
            style={{ width: '100%' }}
            InputProps={{ style: { borderRadius: 10 } }}
            name="periodId"
            options={DDJJContext.period}
            value={query.periodId || ''}
            onChange={({ value }) =>
              changeQuery({
                name: 'periodId',
                value: String(value),
              })
            }
          />
        </Box>
      )}
    </>
  );
}
