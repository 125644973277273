import React from 'react';
import Box from '@material-ui/core/Box';
import { useQuery } from '../../../../common/functions';
import FilterLabel from '../FilterLabel';
import RadioItem from '../RadioItem';
import RadioList from '../RadioList';

export default function ValidityFilter() {
  const { query, changeQuery } = useQuery();

  return (
    <Box mb={0.5}>
      <FilterLabel>Vigencia</FilterLabel>
      <RadioList>
        <RadioItem
          text={`Vigentes`}
          value={query.validity === 'notExpired'}
          onChange={() =>
            changeQuery({
              name: 'validity',
              value: query.validity !== 'notExpired' ? 'notExpired' : undefined,
            })
          }
        />
        <RadioItem
          text={`Vencidas`}
          value={query.validity === 'expired'}
          onChange={() =>
            changeQuery({
              name: 'validity',
              value: query.validity !== 'expired' ? 'expired' : undefined,
            })
          }
        />
      </RadioList>
    </Box>
  );
}
