import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { Notification } from '../components/Navbar/Notifications/interfaces';
import {
  DeclarationStatus,
  DeclarationType,
  PaymentMethod,
} from '../common/enums';
import {
  DeclarationInstance,
  Graduate,
  History,
  IDDJJContext,
  TourName,
} from '../common/interfaces';
import isEncrypted from '../utils/checkFile';
import { useAPI, callAPI } from './api.service';

const { REACT_APP_BACKEND_URL: baseURL } = process.env;

axios.defaults.baseURL = baseURL;
axios.defaults.paramsSerializer = (params) =>
  new URLSearchParams(params).toString();

export const useGetUserInfo = () =>
  useAPI<Graduate>({
    url: `graduate/me`,
  });

export const getGraduateInfo = (getAccessToken) =>
  callAPI(getAccessToken, { url: `graduate/me` });

export const getGraduateDeclarations = (getAccessToken, params) =>
  callAPI(getAccessToken, {
    method: 'get',
    url: `graduate/declaration`,
    params,
  }).then((res) => res.data);

export const getBackofficeDeclarations = (getAccessToken, params) => {
  return callAPI(getAccessToken, {
    method: 'get',
    url: `/backoffice/declaration`,
    params,
  }).then((res) => res.data);
};

export const getBackofficeDeclarationsCSV = (getAccessToken, params) => {
  return callAPI(getAccessToken, {
    method: 'get',
    url: `/backoffice/declaration/csv`,
    params,
  }).then((res) => res.data);
};

export const getDeclarationById = (
  getAccessToken,
  id: number
): Promise<AxiosResponse<Omit<DeclarationInstance, 'graduate'>>> =>
  callAPI(getAccessToken, {
    url: id && `graduate/declaration/${id}`,
    method: 'get',
  });

export const useGetDeclarationById = (id: number) =>
  useAPI<DeclarationInstance>({
    url: id && `backoffice/declaration/${id}`,
  });

export const useGetCountResume = () =>
  useAPI<{
    [x in keyof typeof DeclarationStatus]: number;
  }>({
    url: `backoffice/declaration/countResume`,
  });

export const sendDeclaration = (
  data,
  urlData: {
    type: DeclarationType;
    status: DeclarationStatus;
  },
  getAccessToken
) => {
  const { type, status } = urlData;
  return callAPI(getAccessToken, {
    url: `graduate/declaration/${type}/${status}`,
    method: 'patch',
    data,
  });
};

export const sendReviewDeclaration = (
  declarationId: number,
  data,
  getAccessToken
) => {
  return callAPI(getAccessToken, {
    url: `backoffice/declaration/review/${declarationId}`,
    method: 'patch',
    data,
  });
};

export const sendBulkApproval = (declarationsId: number[], getAccessToken) => {
  return callAPI(getAccessToken, {
    url: `backoffice/declaration`,
    method: 'patch',
    data: { id: declarationsId },
  });
};

export const deleteDeclaration = (
  getAccessToken,
  declarationId: number
): AxiosPromise<any> =>
  callAPI(getAccessToken, {
    url: `graduate/declaration/${declarationId}`,
    method: 'DELETE',
  });

export const useGetDDJJContext = () => {
  return useAPI<IDDJJContext>({
    url: `graduate/declaration/context`,
  });
};

let storedDDJJContextResponse;
export const getDDJJContext: (
  getAccessToken
) => Promise<AxiosResponse<IDDJJContext>> = (getAccessToken) => {
  if (storedDDJJContextResponse) {
    return Promise.resolve(storedDDJJContextResponse);
  }
  return callAPI(getAccessToken, {
    url: `graduate/declaration/context`,
  }).then((response) => {
    storedDDJJContextResponse = response;
    return response;
  });
};

export const useGetBackofficeDDJJContext = () =>
  useAPI<IDDJJContext>({
    url: `backoffice/declaration/context`,
  });

type UploadGroup = 'incomeDocument';
export const upload = async (group: UploadGroup, file, getAccessToken) => {
  const encrypted = await isEncrypted(file);
  if (encrypted) {
    throw new Error(
      'El archivo que intentas subir está protegido. Por favor sube un archivo que no requiera contraseña.'
    );
  }
  const data = new FormData();
  data.append('file', file);
  return callAPI(getAccessToken, {
    url: `/upload/${group}`,
    method: 'post',
    data,
  });
};

export const useGetGraduateNotifications = () =>
  useAPI<Notification[]>({
    url: `graduate/notification`,
  });

export const markNotificationsSeenAndDismiss = (
  getAccessToken,
  data: { seen?: number[]; delete?: number[] }
) =>
  callAPI(getAccessToken, {
    url: 'graduate/notification',
    method: 'patch',
    data,
  });

export const updateProfile = (
  getAccessToken,
  data: {
    residenceCountry: string | null;
    paymentMethod: PaymentMethod | null;
    cuil: string | null;
  }
) =>
  callAPI(getAccessToken, {
    url: 'graduate/me',
    method: 'patch',
    data,
  });

export const useGetBackofficeDeclarationHistory = (
  id: number,
  hookDeps: any[] = []
) =>
  useAPI<History>(
    {
      url: `backoffice/declaration/${id}/history`,
    },
    hookDeps
  );

export const useGetGraduateDeclarationHistory = (
  id: number,
  hookDeps: any[] = []
) =>
  useAPI<History>(
    {
      url: `graduate/declaration/${id}/history`,
    },
    hookDeps
  );

export const markAsSeenTour = (getAccessToken, tourName: TourName) =>
  callAPI(getAccessToken, {
    url: `graduate/tour/${tourName}`,
    method: 'patch',
  });

export const useGetGraduateLastDeclarations = (graduateId: number) =>
  useAPI<
    {
      periodText?: string;
      companyName?: string;
      declaredIncome: {
        amount?: number;
        currency?: string;
      };
      reviewedIncome: {
        amount?: number;
        currency?: string;
      };
    }[]
  >({
    url: `/backoffice/declaration/last-declarations/${graduateId}`,
  });

export const getGraduatesSuggestions = (
  getAccessToken: () => Promise<string>
) =>
  callAPI<
    {
      text: string;
      value: string;
    }[]
  >(getAccessToken, {
    url: `/backoffice/graduate/suggestions`,
  });
