import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Dialog from '../../components/Dialog';

export interface SevereErrorsProps {
  errors: { message: string; index: number; isHtmlError?: boolean }[];
  onClose: () => void;
}

export default function SevereErrors({ errors, onClose }: SevereErrorsProps) {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setOpen(!!errors.length);
  }, [errors]);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  return (
    <Dialog
      isOpen={open}
      title="Hubo un error:"
      close={handleClose}
      buttons={[
        {
          text: 'Ok',
          color: 'primary',
          action: handleClose,
          variant: 'outlined',
        },
      ]}
    >
      <Grid container direction="column" spacing={1}>
        {errors.map(({ index, message, isHtmlError }) => {
          const render = isHtmlError ? (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            message
          );
          return (
            <Grid key={index} item>
              <Alert style={{ minWidth: 300 }} severity="error">
                {render}
              </Alert>
            </Grid>
          );
        })}
      </Grid>
    </Dialog>
  );
}
