import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';

function GeneralObservation({
  observation,
  width = '60%',
}: {
  observation: string;
  width?: string | number;
}) {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h6">Observación general</Typography>
      <Box
        p={3}
        borderRadius={8}
        width={width}
        border={`1px solid ${theme.palette.error.main}`}
      >
        <Typography>{observation}</Typography>
      </Box>
    </>
  );
}

export default GeneralObservation;
