import React from 'react';
import Joyride, {
  CallBackProps,
  Props as JoyrideProps,
  STATUS,
} from 'react-joyride';

export interface CustomJoyrideProps
  extends Pick<JoyrideProps, 'steps' | 'run'> {
  onClose?: () => void;
}

export default function CustomJoyride({
  steps,
  run,
  onClose,
}: CustomJoyrideProps) {
  const handleCallback = (data: CallBackProps) => {
    if (data.status === STATUS.FINISHED || data.status === STATUS.SKIPPED) {
      onClose?.();
    }
  };

  return (
    <Joyride
      run={run}
      steps={[{ ...steps[0], disableBeacon: true }, ...steps.slice(1)]}
      callback={handleCallback}
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton
      locale={{
        back: 'Atras',
        next: 'Siguiente',
        open: 'Abrir',
        skip: 'Saltar Intro',
        last: 'Cerrar',
        close: 'Cerrar',
      }}
    />
  );
}
