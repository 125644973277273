import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DeclarationType } from './enums';
import { Income } from './interfaces';
import { useGeneralContext } from '../providers/GeneralContext';

export const isIncomeDocumentFile = (
  object: any
): object is { key: string; url: string } =>
  object && ('key' in object || 'url' in object);

export const isIncome = (object: any): object is Income =>
  object && 'currency' in object && 'amount' in object;

export const declarationTypeNames: { [x in DeclarationType]: string } = {
  [DeclarationType.NEW_EMPLOYMENT]: 'Nuevo empleo',
  [DeclarationType.UNEMPLOYMENT]: 'Baja de empleo',
  [DeclarationType.STAY_UNEMPLOYMENT]: 'Desempleado',
  [DeclarationType.STAY_EMPLOYED_INCOME_UNCHANGED]:
    'Mantiene empleo (mismo salario)',
  [DeclarationType.STAY_EMPLOYED_INCOME_CHANGED]:
    'Mantiene empleo (nuevo salario)',
  [DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT]: 'Cambio de empleo (baja)',
  [DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT]: 'Cambio de empleo (alta)',
  [DeclarationType.STAY_EMPLOYED]: 'Mantiene empleo',
  [DeclarationType.EMPTY]: 'Pendiente',
  [DeclarationType.NOT_PRESENTED]: 'No presentada',
};

export const getDeclarationTypeName = (type: DeclarationType) =>
  declarationTypeNames[type];

export const getIncome = (income: Income) =>
  income && `${income.currency} ${income.amount}`;

export const useQuery = () => {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const query: Record<string, string> = {};
    searchParams.forEach((rawValue, rawKey) => {
      const key = decodeURIComponent(rawKey);
      if (key !== 'code' && key !== 'state') {
        const value = decodeURIComponent(rawValue);
        query[key] = value;
      }
    });
    return query;
  }, [location.search]);

  const handleSetQuery = (query: Record<string, string>) => {
    history.push({ search: new URLSearchParams(query).toString() });
  };

  const handleChangeQuery = (input: { name: string; value?: string }) => {
    const newQuery = { ...query };

    if (input.value && input.value !== 'undefined') {
      newQuery[input.name] = input.value;
    } else {
      delete newQuery[input.name];
    }

    history.push({
      search: new URLSearchParams(newQuery).toString(),
    });
  };

  return { query, setQuery: handleSetQuery, changeQuery: handleChangeQuery };
};

export const useMatchRouteModal = (pathname: string) => {
  const { isModal, previousLocation, setPreviousLocation } =
    useGeneralContext();
  const history = useHistory();

  if (!isModal) {
    if (!previousLocation) {
      setPreviousLocation({
        pathname: '/',
        hash: '',
        search: '',
        state: undefined,
      });
    }
    history.push(pathname || '', {
      modal: true,
    });
  }
};
