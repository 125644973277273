import React from 'react';
import { UpdatableFieldProps } from './interfaces';
import UpdateInput from './UpdateInput';
import Field from '.';

function UpdatableField({
  isCorrectionOpen,
  onChange,
  ...rest
}: UpdatableFieldProps) {
  const fieldProps = {
    value: rest.value,
    label: rest.label,
    editable: rest.editable,
    onClickEdit: rest.onClickEdit,
    color: rest.color,
    tooltip: rest.tooltip,
  };

  const updateInputProps = {
    value: rest.value,
    label: rest.label,
    questionName: rest.questionName,
    remoteOptions: rest.remoteOptions,
    defaultValue: rest.defaultValue,
  };

  return (
    <>
      {!isCorrectionOpen ? (
        <Field {...fieldProps} />
      ) : (
        <UpdateInput onChange={onChange} {...updateInputProps} />
      )}
    </>
  );
}

export default UpdatableField;
