import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ObservationButton, {
  ObservationButtonProps,
} from '../ObservationButton';

interface ButtonsContainerProps {
  generalObservation?: ObservationButtonProps;
  disabled: {
    accept: boolean;
    reject: boolean;
  };
  onClick: (type: 'accept' | 'reject') => void;
  buttonText: {
    reject: string;
    accept: string;
  };
  helperText?: string;
}

function ButtonsContainer({
  onClick,
  generalObservation,
  buttonText,
  helperText,
  disabled,
}: ButtonsContainerProps) {
  return (
    <Grid item xs={12} container alignItems="flex-end" direction="column">
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {generalObservation && (
          <Box mr="20px">
            <ObservationButton
              {...generalObservation}
              isGeneral
              aria-label="generalObservationButton"
              textboxProps={{
                inputProps: {
                  'aria-label': 'generalObservation',
                },
              }}
            />
          </Box>
        )}
        <Button
          color="primary"
          size="large"
          variant="outlined"
          disabled={disabled.reject}
          onClick={() => onClick('reject')}
          style={{ marginRight: 20 }}
        >
          {buttonText.reject}
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          disabled={disabled.accept}
          onClick={() => onClick('accept')}
        >
          {buttonText.accept}
        </Button>
      </Grid>
      {helperText && (
        <Typography color="textSecondary">{helperText}</Typography>
      )}
    </Grid>
  );
}

export default ButtonsContainer;
