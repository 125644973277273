import React from 'react';

function Rockent({ width = 607 }: { width?: number }) {
  const height = width * 0.7512;
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 607 456"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M416.87 349.883C422.324 357.015 421.065 359.951 413.514 375.053C406.383 388.897 408.48 387.638 408.06 402.321C407.641 417.003 421.065 436.301 434.489 431.267C447.913 426.233 450.865 412.063 451.268 403.578C451.915 389.962 444.138 383.024 446.235 375.892C448.333 368.761 459.66 368.341 456.723 351.981C453.786 335.62 440.362 333.103 439.943 329.327C439.523 325.552 449.591 332.264 461.757 320.098C471.49 310.366 466.651 295.627 463.016 289.475C466.232 285.419 473.042 275.777 471.404 265.142C469.725 254.234 466.79 247.103 459.658 239.972C462.455 234.938 468.3 224.115 467.629 219.416C466.79 213.543 465.952 208.511 470.986 203.476C476.02 198.442 484.83 187.116 486.927 167.399C489.025 147.683 478.118 119.156 446.655 116.22C415.192 113.283 407.222 142.229 401.768 148.522C396.315 154.814 382.05 151.457 374.079 167.817C367.703 180.906 377.717 196.904 383.729 203.476C381.073 212.006 379.367 232.003 393.798 243.749C411.836 258.431 406.383 260.529 406.802 267.66C407.222 274.792 409.319 284.441 416.031 290.314C422.743 296.187 420.226 297.026 418.968 301.64C417.709 306.255 421.065 308.772 421.485 312.547C421.904 316.323 412.256 317.581 407.222 326.81C402.188 336.039 411.417 342.752 416.87 349.883Z"
          fill="#9595AF"
        />
        <path
          d="M280.336 426.587C284.081 427.212 283.769 433.151 291.572 435.339C311.547 435.339 316.541 435.339 323.096 426.587C329.65 417.835 288.451 388.767 293.757 369.389C299.063 350.01 221.033 309.377 212.294 284.685C203.554 259.992 166.1 245.927 149.87 229.049C133.64 212.171 158.174 196.308 137.385 163.099C124.276 142.157 81.8282 111.526 39.3801 142.157C-1.64298 171.76 17.2197 218.109 20.0288 227.174C22.9338 236.547 8.48038 249.053 9.41674 266.869C10.3531 284.685 22.8378 300 43.4377 302.188C64.0375 304.376 61.2284 314.378 78.707 325.943C96.1857 337.507 115.849 333.444 126.461 331.881C137.073 330.319 136.761 334.694 144.876 342.821C152.991 350.948 163.915 355.636 175.464 356.261C187.012 356.886 185.764 358.136 189.197 362.512C192.63 366.888 203.867 370.326 204.491 375.952C205.115 381.578 196.376 395.956 212.294 409.709C228.212 423.462 237.887 413.147 243.818 415.335C249.748 417.523 249.436 421.586 257.863 426.587C266.29 431.588 276.59 425.962 280.336 426.587Z"
          fill="#9595AF"
        />
        <rect
          x="180.234"
          y="176.081"
          width="85.2003"
          height="17.8705"
          rx="4.15287"
          transform="rotate(-31.4822 180.234 176.081)"
          fill="#B8A977"
          stroke="black"
          strokeWidth="3.5596"
        />
        <rect
          x="183.702"
          y="191.072"
          width="95.0012"
          height="16.6115"
          rx="4.15287"
          transform="rotate(-31.4822 183.702 191.072)"
          fill="#988860"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M388.379 153.537C384.582 178.217 374.141 229.475 369.394 252.019L273.285 155.91L317.187 129.213L318.373 7C333.996 32.7082 369.513 92.1932 379.48 107.855C391.561 126.84 391.939 130.4 388.379 153.537Z"
          fill="#D500DF"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M139.801 307.787C163.413 315.912 208.408 327.6 230.571 333.89L192 206.819L149.265 233.279L40.1316 177.268C55.6158 203.06 91.4213 261.419 100.646 277.53C111.827 297.058 117.666 300.17 139.801 307.787Z"
          fill="#D500DF"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M228.79 326.771C254.894 382.301 301.26 428.813 320.838 444.831C324.489 447.818 376.012 453.137 376.012 453.137C376.012 453.137 429.952 444.831 429.999 443.051C431.779 376.605 414.242 324.629 383.633 273.377C353.517 222.949 301.762 177.861 273.285 156.503L193.194 205.744C193.788 223.147 202.687 271.241 228.79 326.771Z"
          fill="#FFF621"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M403.21 374.826L378.293 420.507H326.679L300.575 378.385L324.899 331.517H375.92L403.21 374.826Z"
          fill="#CF0000"
          stroke="black"
          strokeWidth="3.5596"
          strokeLinejoin="bevel"
        />
        <circle
          cx="351.003"
          cy="376.012"
          r="35.596"
          fill="#E7E7CC"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M299.389 243.714C300.971 246.087 304.491 251.189 305.915 252.613C307.694 254.392 310.067 253.799 313.034 251.426C316 249.053 321.34 248.46 324.899 251.426C327.747 253.799 334.589 260.325 337.358 263.291C337.753 261.512 339.849 258.545 345.07 258.545C350.291 258.545 365.637 257.557 372.954 257.359"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M299.389 243.714C300.971 246.087 304.491 251.189 305.915 252.613C307.694 254.392 310.067 253.799 313.034 251.426C316 249.053 321.34 248.46 324.899 251.426C327.747 253.799 334.589 260.325 337.358 263.291C337.753 261.512 339.849 258.545 345.07 258.545C350.291 258.545 365.637 257.557 372.954 257.359"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M275.066 313.719C275.066 317.476 275.066 325.584 275.066 327.958C275.066 330.924 271.506 333.297 268.54 333.297C263.794 333.297 262.014 334.483 262.014 337.45C262.014 340.416 262.014 346.349 261.421 348.722C260.827 351.095 258.454 351.095 254.895 352.281C251.335 353.468 247.776 359.994 248.369 363.554"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M275.066 313.719C275.066 317.476 275.066 325.584 275.066 327.958C275.066 330.924 271.506 333.297 268.54 333.297C263.794 333.297 262.014 334.483 262.014 337.45C262.014 340.416 262.014 346.349 261.421 348.722C260.827 351.095 258.454 351.095 254.895 352.281C251.335 353.468 247.776 359.994 248.369 363.554"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M348.037 331.517C346.653 328.353 343.528 321.313 342.105 318.465C340.325 314.906 340.918 312.533 343.291 310.753C345.664 308.973 349.224 307.193 345.071 301.854C340.918 296.514 338.545 298.294 337.358 294.141C336.172 289.988 336.765 291.175 334.985 288.802"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M348.037 331.517C346.653 328.353 343.528 321.313 342.105 318.465C340.325 314.906 340.918 312.533 343.291 310.753C345.664 308.973 349.224 307.193 345.071 301.854C340.918 296.514 338.545 298.294 337.358 294.141C336.172 289.988 336.765 291.175 334.985 288.802"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M265.573 249.646C263.2 250.833 263.2 253.206 263.2 255.579C263.2 257.952 264.98 260.325 260.233 263.291C255.487 266.258 254.894 268.631 254.894 271.597C254.894 274.563 256.674 276.343 250.741 278.716"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M265.573 249.646C263.2 250.833 263.2 253.206 263.2 255.579C263.2 257.952 264.98 260.325 260.233 263.291C255.487 266.258 254.894 268.631 254.894 271.597C254.894 274.563 256.674 276.343 250.741 278.716"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M209.213 196.845C211.19 198.823 215.502 203.253 216.925 205.151C218.705 207.524 218.112 211.084 216.332 212.864C214.908 214.288 214.157 215.83 213.959 216.423L224.045 226.509L224.638 231.255L231.757 238.374"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M209.213 196.845C211.19 198.823 215.502 203.253 216.925 205.151C218.705 207.524 218.112 211.084 216.332 212.864C214.908 214.288 214.157 215.83 213.959 216.423L224.045 226.509L224.638 231.255L231.757 238.374"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M147.513 232.442C147.987 235.289 145.337 239.956 143.953 241.934C146.722 242.923 152.259 244.9 152.259 248.46C152.259 254.392 147.513 255.579 147.513 258.545C147.513 262.74 152.259 260.918 152.259 264.478C152.259 269.948 159.378 268.038 159.378 272.784C159.378 275.631 159.378 280.694 159.378 284.056C160.169 285.836 161.87 290.344 164.717 291.768"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M147.513 232.442C147.987 235.289 145.337 239.956 143.953 241.934C146.722 242.923 152.259 244.9 152.259 248.46C152.259 254.392 147.513 255.579 147.513 258.545C147.513 262.74 152.259 260.918 152.259 264.478C152.259 269.948 159.378 268.038 159.378 272.784C159.378 275.631 159.378 280.694 159.378 284.056C160.169 285.836 161.87 290.344 164.717 291.768"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M94.7119 205.151C94.7119 209.304 94.7119 212.27 92.3388 214.05C89.9657 215.83 88.1861 218.796 92.3386 221.763"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M94.7119 205.151C94.7119 209.304 94.7119 212.27 92.3388 214.05C89.9657 215.83 88.1861 218.796 92.3386 221.763"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M317.78 129.213C318.769 129.213 319.797 129.213 323.12 129.213C327.273 129.213 330.239 133.366 328.459 136.925C327.273 139.299 326.086 143.451 329.646 143.451C334.415 143.451 340.72 142.66 343.291 142.265V155.91C345.268 155.317 351.359 154.13 359.902 154.13"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M317.78 129.213C318.769 129.213 319.797 129.213 323.12 129.213C327.273 129.213 330.239 133.366 328.459 136.925C327.273 139.299 326.086 143.451 329.646 143.451C334.415 143.451 340.72 142.66 343.291 142.265V155.91C345.268 155.317 351.359 154.13 359.902 154.13"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M317.78 81.1584C319.956 78.5876 324.306 72.2594 326.679 72.2594C329.764 72.2594 332.612 75.2257 335.578 74.6325"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M317.78 81.1584C319.956 78.5876 324.306 72.2594 326.679 72.2594C329.764 72.2594 332.612 75.2257 335.578 74.6325"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M343.383 445.424L355.248 433.559L348.722 429.406"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M343.383 445.424L355.248 433.559L348.722 429.406"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M333.799 444.238C314.617 429.011 269.845 386.572 244.216 338.636C218.587 290.7 203.874 229.673 199.721 205.151"
          stroke="black"
          strokeWidth="3.5596"
          strokeDasharray="7.12 7.12"
        />
        <path
          d="M333.799 444.238C314.617 429.011 269.845 386.572 244.216 338.636C218.587 290.7 203.874 229.673 199.721 205.151"
          stroke="black"
          strokeWidth="3.5596"
          strokeDasharray="7.12 7.12"
        />
        <path
          d="M271.507 164.191C291.655 178.116 340.056 220.717 368.802 266.851C397.548 312.986 418.042 362.96 423.383 411.015"
          stroke="black"
          strokeWidth="3.5596"
          strokeDasharray="7.12 7.12"
        />
        <path
          d="M271.507 164.191C291.655 178.116 340.056 220.717 368.802 266.851C397.548 312.986 418.042 362.96 423.383 411.015"
          stroke="black"
          strokeWidth="3.5596"
          strokeDasharray="7.12 7.12"
        />
        <path
          d="M381.197 437.471C393.529 429.271 407.478 419.321 428.219 419.321C429.406 434.746 429.999 444.238 426.439 444.238C409.235 444.238 383.724 446.018 372.452 444.238C376.002 439.357 371.331 444.031 381.197 437.471Z"
          fill="#D500DF"
        />
        <path
          d="M354.654 287.022C322.224 231.848 279.903 193.286 253.799 175.488L266.851 165.402C281.089 170.148 324.398 207.88 369.486 280.496C414.574 353.112 422.682 417.343 422.88 445.424H415.167C406.071 413.981 400.336 364.74 354.654 287.022Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M325.528 385.777C327.016 385.091 328.778 385.741 329.464 387.228C332.099 392.938 336.214 397.617 341.622 399.799C347.227 402.06 353.484 402.098 359.116 399.904C360.643 399.309 362.362 400.064 362.957 401.591C363.552 403.117 362.796 404.837 361.27 405.432C354.23 408.174 346.408 408.127 339.402 405.3C332.197 402.393 327.14 396.352 324.077 389.713C323.391 388.226 324.041 386.463 325.528 385.777Z"
          fill="white"
        />
        <circle cx="323.211" cy="381.352" r="2.96634" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M326.178 353.251C332.109 348.1 339.853 344.983 348.325 344.983C366.991 344.983 382.122 360.115 382.122 378.782C382.122 387.255 379.004 395 373.853 400.932C380.989 394.735 385.502 385.596 385.502 375.402C385.502 356.735 370.371 341.603 351.705 341.603C341.513 341.603 332.374 346.115 326.178 353.251Z"
          fill="#C7C3B2"
        />
        <path
          d="M401.431 443.051C402.024 436.525 402.617 433.559 400.244 428.22C397.607 422.287 394.311 424.66 394.312 421.1C394.312 417.541 397.278 418.727 396.091 411.015"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M401.431 443.051C402.024 436.525 402.617 433.559 400.244 428.22C397.607 422.287 394.311 424.66 394.312 421.1C394.312 417.541 397.278 418.727 396.091 411.015"
          stroke="black"
          strokeWidth="2.37307"
        />
        <path
          d="M561.889 455.51H174.301C207.424 455.51 207.425 441.129 213.447 437.811C219.47 434.492 223.341 441.129 228.934 441.129C234.526 441.129 232.805 433.939 240.548 433.939C248.291 433.939 250.873 439.47 256.035 441.129C261.197 442.789 261.627 428.962 272.381 428.962C282.275 428.962 283.136 435.599 291.739 435.599C300.343 435.599 304.644 420.112 318.84 426.196C333.036 432.28 330.88 445.001 354.545 445.001C376.914 445.001 380.355 430.068 388.529 428.962C396.702 427.855 400.573 434.492 405.736 433.939C410.898 433.386 409.49 424.151 423.373 421.771C436.278 419.559 433.697 426.196 441.87 426.196C453.915 426.196 453.485 403.884 477.145 405.179C490.303 405.899 495.212 416.793 504.246 416.793C511.989 416.793 513.974 410.353 521.883 406.285C536.939 398.542 553.716 406.838 561.889 420.112C570.062 433.386 576.515 455.51 561.889 455.51Z"
          fill="#594561"
        />
        <path
          d="M246.68 183.2C221.169 145.231 192.403 105.878 174.803 84.718C186.273 108.251 210.734 163.187 229.475 193.286C249.053 224.729 282.184 273.97 301.169 292.955C294.049 277.134 270.596 218.796 246.68 183.2Z"
          fill="#D500DF"
        />
        <path
          d="M182.607 97.1766L295.921 285.242"
          stroke="#C27BE8"
          strokeWidth="2.37307"
          strokeLinecap="round"
        />
        <path
          d="M246.68 183.2C221.169 145.231 192.403 105.878 174.803 84.718C186.273 108.251 210.734 163.187 229.475 193.286C249.053 224.729 282.184 273.97 301.169 292.955C294.049 277.134 270.596 218.796 246.68 183.2Z"
          stroke="black"
          strokeWidth="3.5596"
        />
        <path
          d="M246.68 183.2C221.169 145.231 192.403 105.878 174.803 84.718C186.273 108.251 210.734 163.187 229.475 193.286C249.053 224.729 282.184 273.97 301.169 292.955C294.049 277.134 270.596 218.796 246.68 183.2Z"
          stroke="black"
          strokeWidth="3.5596"
        />
      </svg>
    </div>
  );
}

export default Rockent;
