import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Info } from '@material-ui/icons';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import { Input } from '@soyhenry/commons';
import moment from 'moment';
import Edit from '../../icons/Edit';
import { useAuth } from '../../providers/Auth';

const backofficeNoteExplanation = `Esta nota es visible únicamente en el Backoffice, 
y sirve como medio para que sus miembros puedan dejar observaciones privadas sobre una DJ.
Te recomendamos dejar tu nombre y la fecha hacer más clara esta comunicación.
La nota es guardada al hacer click en Guardar, al aceptar, o rechazar una DJ.`;

export interface BackofficeNoteProps {
  value: string;
  disabled?: boolean;
  ableToEdit?: boolean;
  isEditing?: boolean;
  onClickEdit?: () => void;
  onCancelEdit?: () => void;
  onConfirmEdit?: (value: string) => void;
}

function BackofficeNote({
  value,
  disabled,
  ableToEdit,
  isEditing,
  onClickEdit,
  onCancelEdit,
  onConfirmEdit,
}: BackofficeNoteProps) {
  const { user } = useAuth();

  const initialBackofficeNote = `${value ? `${value}\n` : ''}${
    user.given_name && user.family_name
      ? `${user.given_name} ${user.family_name} (${moment().format(
          'DD/MM/YYYY, hh:mm a'
        )}):`
      : ''
  }`;

  const [localValue, setLocalValue] = useState(value);

  const handleChange = ({ value, name }) => {
    setLocalValue(value);
  };

  const handleClickEdit = () => {
    setLocalValue(initialBackofficeNote);
    onClickEdit?.();
  };

  const handleClickCancel = () => {
    setLocalValue(value);
    onCancelEdit?.();
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" width="50%">
          <Typography>Nota de Backoffice</Typography>
          <Tooltip title={backofficeNoteExplanation}>
            <IconButton style={{ marginLeft: 4 }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Box>
        {ableToEdit && (
          <Box width="100%" display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="editBackofficeNote"
              onClick={handleClickEdit}
            >
              <Edit />
            </IconButton>
          </Box>
        )}
      </Box>

      <Input
        type="textarea"
        name="backofficeNote"
        inputProps={{
          'aria-label': 'backofficeNote',
        }}
        label=""
        disabled={disabled}
        variant="outlined"
        minRows={8}
        style={{ width: '100%' }}
        value={disabled && !localValue ? 'Sin nota' : localValue}
        onChange={handleChange}
      />

      {isEditing && (
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          mt={2}
          alignItems="center"
        >
          <IconButton onClick={handleClickCancel}>
            <Cancel style={{ fontSize: 30 }} />
          </IconButton>
          <IconButton
            aria-label="confirmBackofficeNote"
            onClick={() => {
              onConfirmEdit(localValue);
            }}
            style={{ marginLeft: 10 }}
          >
            <Check style={{ fontSize: 30 }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default BackofficeNote;
