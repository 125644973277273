export interface Notification {
  id: number;
  seen: boolean;
  type: NotificationType;
  declarationId: number;
  createdAt: string;
}

export interface NotificationsProps {
  open: boolean;
  anchorEl: Element | ((element: Element) => Element);
  notifications: Notification[];
  error: boolean;
  onClose: () => void;
  onClickDeleteNotification: (notificationId: number) => void;
}

export type NotificationsListProps = Pick<
  NotificationsProps,
  'notifications' | 'onClickDeleteNotification' | 'onClose'
>;

export enum NotificationType {
  A_DECLARATION_WAS_ACCEPTED = 'aDeclarationWasAccepted',
  A_DECLARATION_WAS_ACCEPTED_WITH_PENDINGS = 'aDeclarationWasAcceptedWithPendings',
  A_DECLARATION_WAS_ACCEPTED_WITH_CORRECTIONS = 'aDeclarationWasAcceptedWithCorrections',
  A_DECLARATION_WAS_REJECTED = 'aDeclarationWasRejected',
  A_DECLARATION_NEEDS_TO_BE_COMPLETED = 'aDeclarationNeedsToBeCompleted',
  A_DECLARATION_WAS_REMINDED_AFTER_3_DAYS = 'aDeclarationWasRemindedAfter3Days',
  A_DECLARATION_WAS_REMINDED_AFTER_15_DAYS = 'aDeclarationWasRemindedAfter15Days',
}
