import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import moment from 'moment';
import { getDeclarationTypeName } from '../../common/functions';
import { statusNames } from '../GraduateDashboard/functions';
import { HistoryItem } from '../../common/interfaces';

const Separator = () => {
  return (
    <TimelineSeparator>
      <TimelineDot />
    </TimelineSeparator>
  );
};

const Connector = () => {
  return (
    <TimelineItem style={{ minHeight: 40 }}>
      <TimelineOppositeContent />
      <TimelineSeparator>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent />
    </TimelineItem>
  );
};

function DeclarationHistory({
  history,
  createdByUser,
  onClickItem,
  selectedIndex,
  itemProps,
}: {
  history: HistoryItem[];
  createdByUser: boolean;
  onClickItem: (index: number) => void;
  selectedIndex: number;
  itemProps?: {
    width?: number | string;
    minHeight?: number | string;
  };
}) {
  return (
    <Box height="100%" maxHeight={325} overflow="auto" width="100%">
      <Timeline align="left" style={{ alignItems: 'center' }}>
        {history.map(({ declaredData, reviewData }, i) => {
          return (
            <Box
              onClick={() => onClickItem(i)}
              width={itemProps?.width || '70%'}
            >
              <TimelineItem
                style={{
                  cursor: 'pointer',
                  backgroundColor: selectedIndex === i && 'black',
                  borderRadius: selectedIndex === i && 8,
                  color: selectedIndex === i && 'white',
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: itemProps?.minHeight || 60,
                }}
              >
                <TimelineOppositeContent>
                  <Typography>
                    {moment((reviewData || declaredData).date).format(
                      'DD-MM-YYYY, hh:mm A'
                    )}
                  </Typography>
                </TimelineOppositeContent>
                <Separator />
                <TimelineContent>
                  <Typography>
                    {(i === history.length - 1 &&
                      (createdByUser
                        ? 'Creada por el Graduado'
                        : 'Creada por el Backoffice')) ||
                      statusNames[(reviewData || declaredData).status]}
                    <br />
                    {i !== history.length - 1
                      ? ` (${getDeclarationTypeName(
                          (reviewData || declaredData).type
                        )})`
                      : ''}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              {i !== history.length - 1 && <Connector />}
            </Box>
          );
        })}
      </Timeline>
    </Box>
  );
}

export default DeclarationHistory;
