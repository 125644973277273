import useIsThisScreen from '../../../utils/useIsThisScreen';

export const useGetFields = () => {
  const isSmScreen = useIsThisScreen('sm');
  const is860Screen = useIsThisScreen(860);
  let fields: { [x: string]: string } = {
    period: 'Mes',
    employer: 'Empleador',
    status: 'Status',
  };
  if (!is860Screen) {
    fields = {
      id: 'DJ #',
      period: fields.period,
      employer: fields.employer,
      status: fields.status,
    };
  }
  if (!isSmScreen) {
    fields = {
      id: 'DJ #',
      period: fields.period,
      type: 'Tipo DJ',
      employer: fields.employer,
      incomeDocument: 'Comprobante',
      status: fields.status,
    };
  }
  return fields;
};
