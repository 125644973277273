import React from 'react';
import Box from '@material-ui/core/Box';
import ButtonGroup, { ButtonGroupProps } from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export interface IconButtonGroupProps {
  groups: {
    icon: React.ReactNode;
    onClick: () => void;
    tooltip: string;
    disabled?: boolean;
    dataTestId: string;
  }[][];
  spacing?: number;
  variant?: ButtonGroupProps['variant'];
}

function IconButtonGroup({
  groups,
  spacing = 4,
  variant = 'contained',
}: IconButtonGroupProps) {
  return (
    <>
      {groups.map((group, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={i} data-testid="actionButtons" mr={spacing}>
          <ButtonGroup variant={variant}>
            {group.map(
              ({ disabled, onClick, tooltip, dataTestId, icon }, i) => (
                <IconButton
                  key={dataTestId}
                  data-testid={dataTestId}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <Tooltip arrow title={tooltip}>
                    <span>{icon}</span>
                  </Tooltip>
                </IconButton>
              )
            )}
          </ButtonGroup>
        </Box>
      ))}
    </>
  );
}

export default IconButtonGroup;
