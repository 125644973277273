import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import LibraryAddIcon from '@material-ui/icons/LibraryAddOutlined';
import { GraduateStatus } from '../../common/enums';
import { useGraduateContext } from '../../providers/GraduateContext';
import useIsThisScreen from '../../utils/useIsThisScreen';
import Dialog from '../Dialog';
import Spinner from '../Spinner';
import WithTooltip from '../WithTooltip';
import GraduateDashboardTour from './GraduateDashboardTour';
import TableComponent from './TableComponent';

const NewJobButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) => (
  <Button
    disabled={disabled}
    variant="contained"
    color="primary"
    onClick={onClick}
    size="large"
    id="new-declaration-button"
  >
    Empleo Nuevo <LibraryAddIcon style={{ marginLeft: 8 }} />
  </Button>
);

const UnemploymentButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => (
  <Button
    variant="outlined"
    disabled={disabled}
    color="primary"
    onClick={onClick}
    size="large"
  >
    Baja de Empleo <DeleteSweepIcon style={{ marginLeft: 8 }} />
  </Button>
);

function GraduateDashboard() {
  const {
    successAlert,
    setSuccessAlert,
    hasPendingDeclarations,
    hasPendingUnemploymentDeclarations,
    createNewJobDeclaration,
    createUnemploymentDeclaration,
    loadingDDJJContext,
    graduateData,
    loadingGraduateData,
  } = useGraduateContext();

  const isSmScreen = useIsThisScreen('sm');

  const loading = loadingDDJJContext || loadingGraduateData;

  const closeSuccessAlert = () => {
    setSuccessAlert(false);
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box width="100%">
        <Box width="100%" display="flex" justifyContent="flex-end">
          {!isSmScreen && (
            <Box p={3} pb={0} display="flex">
              {(graduateData?.status === GraduateStatus.EMPLOYMENT ||
                graduateData?.status === GraduateStatus.MULTIEMPLOYMENT) && (
                <Box mr={2}>
                  <WithTooltip
                    withTooltip={hasPendingUnemploymentDeclarations}
                    tooltip="Para dar de baja un empleo debes completar tu baja de empleo pendiente."
                  >
                    <UnemploymentButton
                      disabled={hasPendingUnemploymentDeclarations}
                      onClick={createUnemploymentDeclaration}
                    />
                  </WithTooltip>
                </Box>
              )}
              <WithTooltip
                withTooltip={hasPendingDeclarations}
                tooltip="Para declarar un empleo nuevo, es necesario completar todas tus DJ pendientes"
              >
                <NewJobButton
                  disabled={hasPendingDeclarations}
                  onClick={createNewJobDeclaration}
                />
              </WithTooltip>
            </Box>
          )}
        </Box>
        <TableComponent />
      </Box>
      <Dialog
        title="Éxito"
        description="Declaración presentada correctamente."
        isOpen={successAlert}
        close={closeSuccessAlert}
        buttons={[
          {
            text: 'Ok',
            color: 'primary',
            variant: 'contained',
            action: closeSuccessAlert,
          },
        ]}
      />
      <GraduateDashboardTour />
    </>
  );
}

export default GraduateDashboard;
