import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import * as yup from 'yup';
import useErrorHandler from '../../services/ErrorHandler';
import { updateProfile } from '../../services/collections.service';
import { PaymentMethod, paymentMethodNames } from '../../common/enums';
import { useAuth } from '../../providers/Auth';
import { useGraduateContext } from '../../providers/GraduateContext';
import countries from '../../utils/countries';
import Avatar from '../Avatar';
import DialogComponent from '../Dialog';
import Items from '../Items';
import Spinner from '../Spinner';

function Profile() {
  const { user, getAccessToken } = useAuth();
  const { apiCatcher } = useErrorHandler();
  const { graduateData, loadingGraduateData, refreshGraduateData } =
    useGraduateContext();

  const [isEditing, setIsEditing] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);

  const [values, setValues] = useState<{
    cuil: string | null;
    paymentMethod: PaymentMethod | '' | null;
    residenceCountry: string;
    paypalUsername: string;
    bankName: string;
    secondaryEmail: string;
  }>({
    cuil: '',
    paymentMethod: '',
    residenceCountry: '',
    paypalUsername: '',
    bankName: '',
    secondaryEmail: '',
  });

  const [errors, setErrors] = useState<{
    cuil?: string;
    paymentMethod?: string;
    residenceCountry?: string;
    paypalUsername?: string;
    bankName?: string;
    secondaryEmail?: string;
  }>({});

  const validations = {
    cuil: yup
      .string()
      .matches(
        /^\d{2}-\d{8}-\d{1}$/,
        'Debe tener solo números y matchear con el siguiente formato: XX-XXXXXXX-XX'
      ),
    paypalUsername: yup
      .string()
      .max(90, 'El nombre de usuario de Paypal es demasiado extenso'),
    bankName: yup.string().max(90, 'El banco es demasiado extenso.'),
    secondaryEmail: yup
      .string()
      .email('Debe ser un email válido')
      .not(
        [graduateData?.email],
        'El email secundario no puede ser igual que el email principal.'
      ),
  };

  const getValues: () => {
    cuil: string | null;
    paymentMethod: PaymentMethod | '' | null;
    residenceCountry: string;
    paypalUsername: string;
    bankName: string;
    secondaryEmail: string;
  } = useCallback(
    () => ({
      cuil: graduateData?.cuil || '',
      paymentMethod: graduateData?.paymentMethod || '',
      residenceCountry: graduateData?.residenceCountry || '',
      paypalUsername: graduateData?.paypalUsername || '',
      bankName: graduateData?.bankName || '',
      secondaryEmail: graduateData?.secondaryEmail || '',
    }),
    [graduateData]
  );

  useEffect(() => {
    setValues(getValues);
  }, [getValues]);

  const handleChange = ({ name, value }) => {
    (
      validations?.[name]?.validate(value, { strict: true }) ||
      Promise.resolve()
    )
      .then(() => {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
      })
      .catch((error: { message: string }) => {
        let newErrors = { ...errors };
        if (value === '') {
          delete newErrors[name];
        } else {
          newErrors = { ...newErrors, [name]: error.message };
        }
        setErrors(newErrors);
      })
      .then(() => {
        const cuil =
          name === 'residenceCountry' && value !== 'Argentina'
            ? ''
            : name === 'cuil'
            ? value
            : values.cuil || graduateData?.cuil || null;
        const paypalUsername =
          name === 'paymentMethod' && value !== PaymentMethod.Paypal
            ? ''
            : name === 'paypalUsername'
            ? value
            : values.paypalUsername || graduateData?.paypalUsername || null;
        setValues({
          ...values,
          cuil,
          paypalUsername,
          [name]: value,
        });
      });
  };

  const startEdit = () => {
    setIsEditing(true);
  };
  const stopEdit = () => {
    setIsEditing(false);
    setValues(getValues);
  };

  const openSuccessAlert = () => {
    setIsSuccessAlertOpen(true);
  };
  const closeSuccessAlert = () => {
    setIsSuccessAlertOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      cuil: values.cuil || null,
      paymentMethod: values.paymentMethod || null,
      residenceCountry: values.residenceCountry || null,
      paypalUsername: values.paypalUsername || null,
      bankName: values.bankName || null,
      secondaryEmail: values.secondaryEmail || null,
    };

    setIsSubmitting(true);

    updateProfile(getAccessToken, payload)
      .then(() => {
        refreshGraduateData()
          .then(() => {
            openSuccessAlert();
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch(apiCatcher('severe'))
      .finally(() => {
        stopEdit();
        setIsSubmitting(false);
      });
  };

  const areChanges = Object.entries(values).some(
    ([key, value]) => (graduateData?.[key] || '') !== value
  );

  return (
    <Container maxWidth="sm">
      <Paper>
        <Box
          p={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {loadingGraduateData ? (
            <Spinner />
          ) : (
            <>
              <Box width="100%">
                <Link to="/">
                  <Button startIcon={<ArrowBackIos />}>Volver</Button>
                </Link>
              </Box>
              <Avatar
                src={user.picture}
                size={90}
                name={graduateData?.fullName}
              />
              <Box mt={2}>
                <Typography variant="h4">{graduateData?.fullName}</Typography>
              </Box>
              {!isEditing && (
                <Box mt={1}>
                  <Button
                    style={{ padding: '10px 16px' }}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={startEdit}
                  >
                    Editar perfil
                  </Button>
                </Box>
              )}
              <Box mt={2.5} display="flex" flexDirection="column" width="100%">
                <Items
                  title="Perfil personal"
                  onChangeData={handleChange}
                  isEditing={isEditing}
                  items={[
                    {
                      title: 'Email Henry',
                      value: graduateData.email,
                    },
                    {
                      title: 'Email secundario',
                      value: graduateData.secondaryEmail,
                      edit: {
                        label: 'Email secundario',
                        name: 'secondaryEmail',
                        value: values.secondaryEmail,
                        error: errors.secondaryEmail,
                        type: 'text',
                      },
                    },
                    {
                      title: 'Github',
                      value: graduateData.github,
                    },
                    {
                      title: 'País de residencia',
                      value: graduateData.residenceCountry,
                      edit: {
                        label: 'País de residencia',
                        name: 'residenceCountry',
                        value: values.residenceCountry,
                        error: errors.residenceCountry,
                        type: 'autocomplete',
                        disableNewValues: true,
                        options: Object.values(countries).map((country) => ({
                          text: country,
                          value: country,
                        })),
                      },
                    },
                  ]}
                />
              </Box>
              <Box mt={3} display="flex" flexDirection="column" width="100%">
                <Items
                  title="Perfil financiero"
                  onChangeData={handleChange}
                  isEditing={isEditing}
                  items={[
                    {
                      title: 'Banco con el que vas a operar',
                      value: graduateData.bankName,
                      edit: {
                        label: 'Banco con el que vas a operar',
                        name: 'bankName',
                        value: values.bankName,
                        error: errors.bankName,
                        type: 'text',
                      },
                    },
                    {
                      title: 'Método de pago',
                      value: paymentMethodNames[graduateData.paymentMethod],
                      edit: {
                        label: 'Método de pago',
                        name: 'paymentMethod',
                        value: values.paymentMethod,
                        error: errors.paymentMethod,
                        type: 'select',
                        options: Object.values(PaymentMethod).map(
                          (paymentMethod) => ({
                            text: paymentMethodNames[paymentMethod],
                            value: paymentMethod,
                          })
                        ),
                      },
                    },
                    {
                      title: 'Nombre de usuario de Paypal',
                      value: graduateData.paypalUsername,
                      edit: {
                        label: 'Nombre de usuario de Paypal',
                        name: 'paypalUsername',
                        value: values.paypalUsername,
                        error: errors.paypalUsername,
                        type: 'text',
                      },
                      render:
                        (!isEditing &&
                          graduateData.paymentMethod ===
                            PaymentMethod.Paypal) ||
                        (isEditing &&
                          values.paymentMethod === PaymentMethod.Paypal),
                    },
                    {
                      title: 'CUIL',
                      value: graduateData.cuil,
                      edit: {
                        label: 'CUIL',
                        name: 'cuil',
                        value: values.cuil,
                        error: errors.cuil,
                        type: 'text',
                      },
                      render:
                        (!isEditing &&
                          graduateData.residenceCountry === 'Argentina') ||
                        (isEditing && values.residenceCountry === 'Argentina'),
                    },
                  ]}
                />
              </Box>
              {isEditing && (
                <Box
                  mt={1}
                  display="flex"
                  width="100%"
                  justifyContent="flex-end"
                >
                  <Box
                    width="45%"
                    display="flex"
                    justifyContent="space-between"
                    mt={3}
                  >
                    <Button
                      style={{ padding: '12px 18px' }}
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={stopEdit}
                      disabled={isSubmitting}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{ padding: '12px 18px' }}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={
                        isSubmitting ||
                        !!Object.keys(errors).length ||
                        !areChanges
                      }
                    >
                      Confirmar
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Paper>
      <DialogComponent
        title="Éxito"
        description="Perfil actualizado correctamente"
        isOpen={isSuccessAlertOpen}
        close={closeSuccessAlert}
        buttons={[
          {
            action: closeSuccessAlert,
            text: 'Ok',
            color: 'primary',
            variant: 'contained',
          },
        ]}
      />
    </Container>
  );
}

export default Profile;
