import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = (theme: Theme) => ({
  page: {
    '& > canvas': {
      width: '100% !important',
      height: '100% !important',
    },
    overflow: 'hidden',
  },
});

export default makeStyles(styles);
