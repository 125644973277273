const typeOfServices = [
  'Full Stack Developer',
  'Administador de redes',
  'Analista de base de datos',
  'Analista de Desarrollo de Software Junior',
  'Analista IT',
  'Analista QA',
  'Analista RPA',
  'Analista SEO',
  'Angular Developer Jr',
  'Application Development Associate',
  'Associate Software Engineer',
  'Backend Developer Junior',
  'Cloud engineer',
  'Consultor Backend',
  'Contratista de desarrollo de tecnología',
  'Creative Engineer',
  'Data Scientist',
  'Desarrollador de Software',
  'Desarrollador Mobile',
  'Desarrollador Trainee',
  'Desarrollador Web Full Stack',
  'Desarrollador Wordpress',
  'Developer Support Associate',
  'Digital Marketing Specialist',
  'Diseño Multimedia  (Diseño, Animación, Desarrollo Web, etc).',
  'Frelance',
  'Frontend Developer',
  'Frontend Developer Junior',
  'Frontend Engineer',
  'Frontend Web Developer',
  'Full Stack Developer Junior',
  'Full Stack Engineer',
  'Full Stack Software Engineer',
  'Ingeniero Creativa',
  'Instructor',
  'iOS Developer',
  'Junior Web Developer',
  'Lider técnico',
  'Manager',
  'Mantenimiento de base de datos SQL',
  'Node Backend',
  'Node.js Developer Ssr.',
  'Programador',
  'Programador Junior',
  'Project Manager',
  'React Developer',
  'React Native Developer Junior',
  'Sales Agent',
  'Salesforce Developer',
  'Software Engineer',
  'Software Engineer Analyst',
  'Teaching Assistant',
  'Technical Leader',
  'Testing Automation Engineer',
  'Tutor',
  'Tutor de curso Backend',
  'User Support Desk',
  'Web Developer',
  'Web Production Developer',
  'Web UI Developer',
  'Web UI Developer Junior',
];

export default typeOfServices;
