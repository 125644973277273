import React from 'react';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

export interface RadioItemProps {
  text?: string;
  value?: boolean;
  onChange?: () => void;
}

export default function RadioItem({ text, value, onChange }: RadioItemProps) {
  return (
    <li
      style={{
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Radio checked={value} onChange={onChange} />
      <Typography style={{ cursor: 'pointer' }} onClick={onChange}>
        {text}
      </Typography>
    </li>
  );
}
