import { useEffect, useState } from 'react';

export default function useWindowHeight(): number {
  const [height, setHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const setWindowHeight = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', setWindowHeight);
    return () => window.removeEventListener('resize', setWindowHeight);
  }, []);

  return height;
}
