import React from 'react';
import { Box } from '@material-ui/core';
import { Input } from '@soyhenry/commons';
import { useQuery } from '../../../../common/functions';
import currencies from '../../../../common/currencies.json';
import FilterLabel from '../FilterLabel';

export default function IncomeCurrencyFilter() {
  const { query, changeQuery } = useQuery();

  return (
    <Box mb={0.5}>
      <FilterLabel>Moneda</FilterLabel>
      <Input
        type="autocomplete"
        variant="outlined"
        style={{ width: '100%' }}
        InputProps={{ style: { borderRadius: 10 } }}
        name="incomeCurrency"
        disableNewOptions
        options={Object.keys(currencies).map((currency) => ({
          text: currency,
          value: currency,
        }))}
        value={query.incomeCurrency || ''}
        onChange={({ value }) =>
          changeQuery({
            name: 'incomeCurrency',
            value: String(value),
          })
        }
      />
    </Box>
  );
}
