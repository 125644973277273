import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';

const style = (theme: Theme) =>
  createStyles({
    avatar: {
      height: 55,
      width: 55,
    },
  });

export default style;
