import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { getGraduateDeclarations } from '../../../services/collections.service';
import { getDeclarationTypeName } from '../../../common/functions';
import { DeclarationStatus, DeclarationType } from '../../../common/enums';
import { DeclarationInstance } from '../../../common/interfaces';
import { TableData } from '../../Table/interfaces';
import { Data } from './interfaces';
import SeeDocument from '../../../icons/SeeDocument';
import { useAuth } from '../../../providers/Auth';
import { useGraduateContext } from '../../../providers/GraduateContext';
import useIsThisScreen from '../../../utils/useIsThisScreen';
import DeclarationStatusChip from '../../DeclarationStatusChip';
import IncomeDocumentButton from '../../IncomeDocumentButton';
import { IncomeDocumentViewModal } from '../../IncomeDocumentView';
import Table from '../../Table';
import Row, { TableCell } from '../../TableRow';
import ActionIcon from './ActionIcon';
import DeleteConfirmation from './DeleteConfirmation';
import MobileOptionMenu from './MobileOptionMenu';
import useStyles from './styles';
import { useGetFields } from './utils';

const redirectPathByStatus = {
  [DeclarationStatus.ACCEPTED]: 'detail',
  [DeclarationStatus.ACCEPTED_WITH_CORRECTIONS]: 'detail',
  [DeclarationStatus.IN_REVIEW]: 'detail',
  [DeclarationStatus.PRESENTED]: 'detail',
  [DeclarationStatus.RESUBMITTED]: 'detail',
  [DeclarationStatus.ACCEPTED_WITH_PENDINGS]: 'detail',
  [DeclarationStatus.REJECTED]: 'detail',
  [DeclarationStatus.DRAFT]: 'form',
  [DeclarationStatus.EMPTY]: 'form',
};

function TableComponent() {
  const history = useHistory();

  const { getAccessToken } = useAuth();

  const isSmScreen = useIsThisScreen('sm');
  const isXsScreen = useIsThisScreen('xs');

  const classes = useStyles();

  const { tableRef, setDeclarations } = useGraduateContext();

  const fields = useGetFields();

  const [deleteDeclarationId, setDeleteDeclarationId] = useState<number>();

  const [incomeDocumentFile, setIncomeDocumentFile] = useState<string>(null);

  const [openMenuDeclarationId, setOpenMenuDeclarationId] =
    useState<number>(null);

  const toggleIncomeDocument = (link?: string) => {
    if (incomeDocumentFile) {
      setIncomeDocumentFile(null);
    } else {
      setIncomeDocumentFile(link);
    }
  };

  const parseData = (declarations: DeclarationInstance[]) =>
    declarations?.map(
      ({
        id,
        type,
        period,
        status,
        company,
        incomeDocument,
        deletable,
        declaredData,
      }) => {
        const hasIncomeDocument =
          incomeDocument ||
          (declaredData?.incomeDocumentFile && declaredData?.incomeAmount);
        return {
          id,
          period: period?.text,
          employer: company?.name || declaredData?.companyName || '-',
          incomeDocument: (
            <IncomeDocumentButton
              onClick={() =>
                toggleIncomeDocument(
                  incomeDocument?.file || declaredData?.incomeDocumentFile
                )
              }
              disabled={!hasIncomeDocument}
              tooltip={
                hasIncomeDocument
                  ? 'Ver comprobante'
                  : 'Comprobante no disponible'
              }
            />
          ),
          status: <DeclarationStatusChip status={status} />,
          deletable,
          notParsedStatus: status,
          notParsedType: type,
          type: getDeclarationTypeName(type),
        };
      }
    );

  const getTableData = ({ pageSize, page, search }) => {
    const params = {
      pageSize,
      page,
      search,
    };
    if (!params.search) {
      delete params.search;
    }
    return getGraduateDeclarations(getAccessToken, params).then(
      ({ data: declarations, ...pagination }) => {
        setDeclarations(declarations);
        return {
          data: parseData(declarations),
          ...pagination,
        };
      }
    );
  };

  const handleDelete = (declarationId: number) => {
    setDeleteDeclarationId(declarationId);
  };

  const toggleMenuOption = (declarationId?: number) => {
    if (declarationId && !openMenuDeclarationId) {
      setOpenMenuDeclarationId(declarationId);
    } else {
      setOpenMenuDeclarationId(null);
    }
  };

  const handleClickDeclaration = (data: Data) => {
    if (
      !(
        data.notParsedType === DeclarationType.STAY_UNEMPLOYMENT &&
        data.notParsedStatus === DeclarationStatus.ACCEPTED
      )
    ) {
      history.push(
        `/declaration/${data.id}${
          redirectPathByStatus[data.notParsedStatus] === 'form'
            ? `/form${
                data.deletable
                  ? data.notParsedType === DeclarationType.UNEMPLOYMENT
                    ? '?unemployment=true'
                    : '?forceStartStep=companyStep'
                  : ''
              }`
            : ''
        }`,
        {
          modal: true,
        }
      );
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        width="100%"
        bgcolor={'inherit'}
        id="declarations-table"
      >
        <Table
          ContainerProps={{
            className: classes.table,
            style: { backgroundColor: 'inherit' },
          }}
          tableRef={tableRef}
          fields={fields}
          title="Mis declaraciones"
          tableStyle={{ backgroundColor: 'inherit' }}
          data={getTableData}
          localization={{
            header: {
              actions: !isXsScreen ? 'Acciones' : '',
            },
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: !isSmScreen,
          }}
          components={{
            Row: (rowData: TableData<Data>) => (
              <Row<Data>
                rowData={rowData}
                fields={fields}
                keysWithTooltip={['employer', 'type']}
                Actions={({ data }) => {
                  const isDeletable =
                    data.notParsedStatus === DeclarationStatus.DRAFT &&
                    data.deletable &&
                    data.notParsedType !==
                      DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT;

                  const isAcceptedStayUnemployment =
                    data.notParsedStatus === DeclarationStatus.ACCEPTED &&
                    data.notParsedType === DeclarationType.STAY_UNEMPLOYMENT;

                  if (isXsScreen) {
                    return (
                      <TableCell>
                        {isDeletable && (
                          <MobileOptionMenu
                            open={openMenuDeclarationId === data.id}
                            onClose={toggleMenuOption}
                            onMenuClick={toggleMenuOption}
                            onClickDelete={() => handleDelete(data.id)}
                          />
                        )}
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell>
                      <ActionIcon
                        id="access-declaration"
                        status={data.notParsedStatus}
                        disabled={isAcceptedStayUnemployment}
                        customIcon={
                          isAcceptedStayUnemployment && (
                            <SeeDocument fill="rgba(0, 0, 0, 0.26)" />
                          )
                        }
                        onClick={() => handleClickDeclaration(data)}
                      />
                      {isDeletable && (
                        <ActionIcon
                          customIcon={
                            <DeleteIcon
                              style={{ fontSize: 36, color: 'black' }}
                            />
                          }
                          customTooltip="Borrar"
                          onClick={() => handleDelete(data.id)}
                          iconButtonStyle={{ paddingBottom: 4 }}
                        />
                      )}
                    </TableCell>
                  );
                }}
                clickable={(data: Data) =>
                  isXsScreen && data.id !== openMenuDeclarationId
                }
              />
            ),
          }}
        />
      </Box>
      <DeleteConfirmation
        id={deleteDeclarationId}
        onCancel={() => setDeleteDeclarationId(undefined)}
        onFinally={(err) => {
          // eslint-disable-next-line no-console
          if (err) console.error(err);
          setDeleteDeclarationId(undefined);
          tableRef.current?.onQueryChange();
        }}
      />
      <IncomeDocumentViewModal
        open={!!incomeDocumentFile}
        onClose={toggleIncomeDocument}
        src={incomeDocumentFile}
      />
    </>
  );
}

export default TableComponent;
