import React, { useCallback, useEffect, useState } from 'react';
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import useErrorHandler from '../../services/ErrorHandler';
import { getDeclarationById } from '../../services/collections.service';
import { useMatchRouteModal, useQuery } from '../../common/functions';
import { submitDeclaration } from '../GraduateDashboard/functions';
import { getStepNamesPerFlow } from './functions';
import { DeclarationStatus, DeclarationType } from '../../common/enums';
import { DeclarationInstance, DeclaredData } from '../../common/interfaces';
import { useAuth } from '../../providers/Auth';
import { useGeneralContext } from '../../providers/GeneralContext';
import { useGraduateContext } from '../../providers/GraduateContext';
import countries from '../../utils/countries';
import useIsThisScreen from '../../utils/useIsThisScreen';
import { StepNames } from '../DDJJForm/steps';
import Modal from '../Modal';
import Spinner from '../Spinner';
import Form from './Form';
import UnemploymentForm from './UnemploymentForm';

function DDJJForm(props: RouteComponentProps<{ id: string }>) {
  const isSmScreen = useIsThisScreen('sm');
  const history = useHistory();
  const location = useLocation();
  const { apiCatcher } = useErrorHandler();
  const { getAccessToken } = useAuth();
  const {
    refreshTable,
    setSuccessAlert,
    loadingGraduateData,
    graduateData,
    errorGraduateData: error,
    refreshGraduateData,
  } = useGraduateContext();
  const { isModal } = useGeneralContext();

  const [declaration, setDeclaration] = useState<DeclarationInstance>(null);
  const [loadingDeclaration, setLoadingDeclaration] = useState(true);

  const [shouldRedirectToDashboard, setShouldRedirectToDashboard] =
    useState(false);

  const [currentStep, setCurrentStep] = useState(null);
  const [lastMovement, setLastMovement] = useState<'next' | 'back'>(null);

  const loading = loadingDeclaration || loadingGraduateData;

  const isFinalConfirm =
    currentStep === 'finalConfirm' || currentStep === 'continueFinalConfirm';

  useMatchRouteModal(location.pathname);

  const closeModal = useCallback(() => {
    history.replace('/', {
      modal: false,
    });
  }, [history]);

  const { query } = useQuery();

  const shouldRenderUnemploymentForm = query.unemployment === 'true';

  useEffect(() => {
    getDeclarationById(getAccessToken, Number(props.match.params.id))
      .then((response) => {
        // TODO: when is deletable, try to redirect to ?=unemployment=true or ?=forceStartStep=companyStep
        // depending on DJ type. Now is not possible to access to deletable DJs from url, only from table

        // redirect to dashboard if is rejected but not stayUnemployment type and
        // redirect to dashboard if is not empty or draft
        setShouldRedirectToDashboard(
          response.data.status === DeclarationStatus.REJECTED
            ? response.data.type !== DeclarationType.STAY_UNEMPLOYMENT
            : response.data.status !== DeclarationStatus.DRAFT &&
                response.data.status !== DeclarationStatus.EMPTY
        );

        // redirect to detail if is completed DJ
        if (
          ![DeclarationStatus.DRAFT, DeclarationStatus.EMPTY].includes(
            response.data.status
          ) &&
          response.data.status !== DeclarationStatus.REJECTED &&
          response.data.type === DeclarationType.STAY_UNEMPLOYMENT
        ) {
          history.replace(`/declaration/${response.data.id}`);
        }

        setDeclaration(response.data);
        setLoadingDeclaration(false);
      })
      .catch((error) => {
        closeModal();
        apiCatcher('severe')(error);
      });
  }, [
    props.match.params.id,
    apiCatcher,
    getAccessToken,
    closeModal,
    location.search,
    history,
    isModal,
  ]);

  useEffect(() => {
    if (lastMovement === 'next') {
      refreshTable();
    }
    // refreshTable generate loop request even it is declared inside useCallback in GraduateDashboardContext
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const isRejectedStayUnemploymentType =
    declaration?.reviewData?.status === DeclarationStatus.REJECTED &&
    declaration?.reviewData?.type === DeclarationType.STAY_UNEMPLOYMENT;

  const handleCurrentStep = (
    step: string,
    declarationType: DeclarationType,
    movement: 'next' | 'back'
  ) => {
    const flowStepNames =
      getStepNamesPerFlow()[graduateData.status][declarationType];
    const currentStep =
      flowStepNames[
        flowStepNames.indexOf(step) + (movement === 'next' ? 1 : -1)
      ];
    setLastMovement(movement);
    setCurrentStep(currentStep);
  };

  const handleSubmit = (
    step: 'finalConfirm' | 'continueFinalConfirm',
    values: Omit<DeclaredData, 'date'> & {
      type: DeclarationType;
      periodId: number;
    }
  ) => {
    return submitDeclaration(
      values,
      isRejectedStayUnemploymentType
        ? DeclarationStatus.RESUBMITTED
        : DeclarationStatus.PRESENTED,
      values.type,
      getAccessToken
    )
      .then((response) => {
        if (step === 'finalConfirm') {
          refreshTable();
          refreshGraduateData().then(() => {
            closeModal();
            setSuccessAlert(true);
          });
        }
        if (values.type === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT) {
          return submitDeclaration(
            { periodId: values.periodId },
            DeclarationStatus.DRAFT,
            DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT,
            getAccessToken
          ).then((response) => {
            setDeclaration(response.data);
            history.replace(`/declaration/${response.data.id}/form`);
            return response;
          });
        }
        return response;
      })
      .catch((error) => {
        apiCatcher('severe')(error);
        throw error;
      });
  };

  const handleNextStep = (
    step: StepNames,
    values: Omit<DeclaredData, 'date'> & {
      type: DeclarationType;
      periodId: number;
    }
  ) => {
    if (step === 'continueFinalConfirm') {
      return handleSubmit(step, values).then((response) => {
        handleCurrentStep(step, values.type, 'next');
        return {
          companyName: null,
          companyCountry: countries[0],
          contactName: null,
          contactEmail: null,
          modality: null,
          typeOfService: null,
          startDate: moment(),
          endDate: null,
          incomeCurrency: 'ARS',
          incomeAmount: null,
          incomeDocumentFile: null,
          incomeDocumentType: null,
          id: response.data.id,
          periodId: values.periodId,
          type: DeclarationType.CHANGE_EMPLOYMENT_NEW_EMPLOYMENT,
        };
      });
    }
    return submitDeclaration(
      values,
      DeclarationStatus.DRAFT,
      values.type,
      getAccessToken
    )
      .then((response) => {
        handleCurrentStep(step, values.type, 'next');
        return {
          ...values,
          id: response.data.id,
        };
      })
      .catch((error) => {
        apiCatcher('severe')(error);
        throw error;
      });
  };

  const handlePreviousStep = (step: StepNames, values) => {
    handleCurrentStep(step, values.type, 'back');
  };

  const handleGetContinueStep = (continueStep: string) => {
    if (!currentStep) {
      setCurrentStep(continueStep);
    }
  };

  if (
    error ||
    // TODO: crash when try to redirect with this querys in useEffect,
    // and crash if try to render Wizard without them. To ammend this partialy, redirect to dashboard
    (declaration &&
      declaration.deletable &&
      !location.search.includes('forceStartStep=companyStep') &&
      !location.search.includes('unemployment=true'))
  ) {
    if (error) {
      apiCatcher('severe')(error);
    }
    return <Redirect to="/" />;
  }

  return (
    <Modal open onClose={closeModal}>
      <Container
        maxWidth={isFinalConfirm ? 'sm' : 'xs'}
        style={{
          height: isSmScreen && !isFinalConfirm && '100%',
          padding: isSmScreen && 0,
        }}
      >
        <Paper
          style={{
            padding: '1rem 1rem 2.5rem 1rem',
            height: 'auto',
          }}
        >
          {loading ? (
            <Spinner />
          ) : shouldRedirectToDashboard ? (
            <Redirect to="/" />
          ) : shouldRenderUnemploymentForm ? (
            <UnemploymentForm
              userInfo={graduateData}
              onSubmit={handleSubmit}
              onClose={closeModal}
              declarationId={declaration?.id}
            />
          ) : (
            <Form
              declaration={declaration}
              forceStartStep={query.forceStartStep}
              userInfo={graduateData}
              onSubmit={handleSubmit}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
              onGetContinueStep={handleGetContinueStep}
              onClose={closeModal}
            />
          )}
        </Paper>
      </Container>
    </Modal>
  );
}

export default DDJJForm;
