import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import MuiTable from 'material-table';
import NumberFormat from 'react-number-format';
import { useGetGraduateLastDeclarations } from '../../../services/collections.service';
import useStyles from './styles';

export interface LastDeclarationsProps {
  graduateId: number;
}

export default function LastDeclarations({
  graduateId,
}: LastDeclarationsProps) {
  const classes = useStyles();
  const { loading, data } = useGetGraduateLastDeclarations(graduateId);

  const parsedData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((declaration) => {
      return {
        periodText: declaration.periodText,
        companyName: declaration.companyName,
        declaredIncome: (
          <>
            {declaration.declaredIncome.currency}{' '}
            <NumberFormat
              value={declaration.declaredIncome.amount}
              thousandSeparator
              displayType="text"
            />
          </>
        ),
        reviewedIncome: (
          <>
            {declaration.reviewedIncome.currency}{' '}
            <NumberFormat
              value={declaration.reviewedIncome.amount}
              thousandSeparator
              displayType="text"
            />
          </>
        ),
      };
    });
  }, [data]);

  return (
    <Box className={classes.lastDeclarationsContainer}>
      <MuiTable
        columns={[
          { title: 'Periodo', field: 'periodText' },
          { title: 'Empresa', field: 'companyName' },
          { title: 'Ingreso Declarado', field: 'declaredIncome' },
          { title: 'Ingreso Validado', field: 'reviewedIncome' },
        ]}
        isLoading={loading}
        data={parsedData}
        options={{
          toolbar: false,
          paging: false,
          hideFilterIcons: true,
          draggable: false,
          sorting: false,
        }}
      />
    </Box>
  );
}
