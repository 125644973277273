import React from 'react';

function Edit() {
  return (
    <div>
      <svg
        width="36"
        height="33"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.25 15C25.9185 15 25.6005 15.1317 25.3661 15.3661C25.1317 15.6005 25 15.9185 25 16.25V23.75C25 24.0815 24.8683 24.3994 24.6339 24.6339C24.3995 24.8683 24.0815 25 23.75 25H6.25C5.91848 25 5.60054 24.8683 5.36612 24.6339C5.1317 24.3994 5 24.0815 5 23.75V6.24998C5 5.91846 5.1317 5.60052 5.36612 5.3661C5.60054 5.13168 5.91848 4.99998 6.25 4.99998H13.75C14.0815 4.99998 14.3995 4.86829 14.6339 4.63387C14.8683 4.39945 15 4.08151 15 3.74998C15 3.41846 14.8683 3.10052 14.6339 2.8661C14.3995 2.63168 14.0815 2.49998 13.75 2.49998H6.25C5.25544 2.49998 4.30161 2.89507 3.59835 3.59833C2.89509 4.3016 2.5 5.25542 2.5 6.24998V23.75C2.5 24.7445 2.89509 25.6984 3.59835 26.4016C4.30161 27.1049 5.25544 27.5 6.25 27.5H23.75C24.7446 27.5 25.6984 27.1049 26.4017 26.4016C27.1049 25.6984 27.5 24.7445 27.5 23.75V16.25C27.5 15.9185 27.3683 15.6005 27.1339 15.3661C26.8995 15.1317 26.5815 15 26.25 15ZM7.5 15.95V21.25C7.5 21.5815 7.6317 21.8994 7.86612 22.1339C8.10054 22.3683 8.41848 22.5 8.75 22.5H14.05C14.2145 22.5009 14.3776 22.4694 14.5299 22.4072C14.6822 22.345 14.8207 22.2533 14.9375 22.1375L23.5875 13.475L27.1375 9.99998C27.2547 9.88378 27.3477 9.74553 27.4111 9.59321C27.4746 9.44088 27.5072 9.2775 27.5072 9.11248C27.5072 8.94747 27.4746 8.78409 27.4111 8.63176C27.3477 8.47944 27.2547 8.34119 27.1375 8.22498L21.8375 2.86248C21.7213 2.74532 21.583 2.65233 21.4307 2.58887C21.2784 2.52541 21.115 2.49274 20.95 2.49274C20.785 2.49274 20.6216 2.52541 20.4693 2.58887C20.317 2.65233 20.1787 2.74532 20.0625 2.86248L16.5375 6.39998L7.8625 15.0625C7.74665 15.1793 7.65499 15.3178 7.59279 15.4701C7.53058 15.6224 7.49905 15.7855 7.5 15.95ZM20.95 5.51248L24.4875 9.04998L22.7125 10.825L19.175 7.28748L20.95 5.51248ZM10 16.4625L17.4125 9.04998L20.95 12.5875L13.5375 20H10V16.4625Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default Edit;
