import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { HistoryItem } from '../../common/interfaces';
import BackofficeNote, { BackofficeNoteProps } from '../BackofficeNote';
import DeclarationHistory from '../DeclarationHistory';
import IncomeDocumentView from '../IncomeDocumentView';
import LastDeclarations from './LastDeclarations';

function TabsPanel({
  selectedTab,
  backofficeNote,
  declarationHistory,
  incomeDocument: { src: incomeDocumentSrc },
  onChangeTab,
  graduateId,
}: {
  selectedTab: string;
  incomeDocument: {
    src: string;
  };
  backofficeNote: BackofficeNoteProps;
  declarationHistory: {
    createdByUser: boolean;
    history: HistoryItem[];
    onClickItem: (selectedIndex: number) => void;
    selectedIndex: number;
  };
  onChangeTab: (newValue: string) => void;
  graduateId: number;
}) {
  const handleChangeTab = (_e, newValue) => {
    onChangeTab(newValue);
  };

  return (
    <Box width="100%">
      <AppBar
        position="static"
        style={{
          borderRadius: 10,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Tabs
          onChange={handleChangeTab}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            style={{ flexGrow: 1 }}
            value="incomeDocument"
            label="Comprobante"
          />
          <Tab
            style={{ flexGrow: 1 }}
            value="lastDeclarations"
            label="Ultimas Declaraciones"
          />
          <Tab
            style={{ flexGrow: 1 }}
            value="backofficeNote"
            label="Nota de Backoffice"
          />
          <Tab
            style={{ flexGrow: 1 }}
            value="declarationHistory"
            label="Historial DJ"
          />
        </Tabs>
      </AppBar>
      <Box
        width="100%"
        borderRadius={10}
        border="1px solid #C4C4C4"
        style={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        }}
        p={2}
        minHeight={350}
      >
        {selectedTab === 'incomeDocument' ? (
          <IncomeDocumentView src={incomeDocumentSrc} />
        ) : selectedTab === 'declarationHistory' ? (
          <DeclarationHistory {...declarationHistory} />
        ) : selectedTab === 'backofficeNote' ? (
          <BackofficeNote {...backofficeNote} />
        ) : selectedTab === 'lastDeclarations' ? (
          <LastDeclarations graduateId={graduateId} />
        ) : null}
      </Box>
    </Box>
  );
}

export default TabsPanel;
