import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CancelOutlined from '@material-ui/icons/CancelOutlined';

const title = 'Detalle de tu declaración';

function Header({ onClose }: { onClose: () => void }) {
  return (
    <Grid container>
      <Box
        mb={1}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="100%" mb={1}>
          <Typography style={{ fontSize: 20, fontWeight: 750 }}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={onClose}>
          <CancelOutlined />
        </IconButton>
      </Box>
    </Grid>
  );
}

export default Header;
