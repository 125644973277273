import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';

const transitionDuration = 450;

const style = (theme: Theme) =>
  createStyles({
    icon: {
      fill: theme.palette.grey[800],
      transition: `${transitionDuration}ms`,
      fontSize: 30,
    },
    iconReject: {
      fill: theme.palette.error.light,
    },
    iconAccept: {
      fill: theme.palette.success.dark,
    },
  });

export default style;
