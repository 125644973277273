import React from 'react';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { NotificationsProps } from './interfaces';
import NotificationsList from './NotificationsList';

export default function Notifications({
  open,
  anchorEl,
  notifications,
  error,
  onClose,
  onClickDeleteNotification,
}: NotificationsProps) {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box padding={2} width={320} maxHeight={230}>
        <Typography variant="subtitle1">Notificaciones</Typography>
        {error || !notifications.length ? (
          <Box mt={1.5}>
            <Typography variant="subtitle2">
              {error
                ? 'Hubo un error al cargar las notificaciones'
                : 'Sin notificaciones pendientes'}
            </Typography>
          </Box>
        ) : (
          <NotificationsList
            onClickDeleteNotification={onClickDeleteNotification}
            onClose={onClose}
            notifications={notifications}
          />
        )}
      </Box>
    </Popover>
  );
}
