import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

function WithTooltip({
  withTooltip,
  tooltip,
  children,
  ...props
}: Omit<TooltipProps, 'children' | 'title'> & {
  withTooltip: boolean;
  tooltip: string | React.ReactNode;
  children: React.ReactNode;
}) {
  if (withTooltip) {
    return (
      <Tooltip {...props} arrow title={tooltip}>
        <span>{children}</span>
      </Tooltip>
    );
  }
  return <>{children}</>;
}

export default WithTooltip;
