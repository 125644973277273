const countries = {
  0: 'Argentina',
  1: 'Bolivia',
  2: 'Brasil',
  3: 'Chile',
  4: 'Colombia',
  5: 'Ecuador',
  6: 'Mexico',
  7: 'Paraguay',
  8: 'Uruguay',
  9: 'Venezuela',
  11: 'Afghanistan',
  12: 'Åland Islands',
  13: 'Albania',
  14: 'Algeria',
  15: 'American Samoa',
  16: 'Andorra',
  17: 'Angola',
  18: 'Anguilla',
  19: 'Antarctica',
  20: 'Antigua and Barbuda',
  21: 'Armenia',
  22: 'Aruba',
  23: 'Australia',
  24: 'Austria',
  25: 'Azerbaijan',
  26: 'Bahamas',
  27: 'Bahrain',
  28: 'Bangladesh',
  29: 'Barbados',
  30: 'Belarus',
  31: 'Belgium',
  32: 'Belize',
  33: 'Benin',
  34: 'Bermuda',
  35: 'Bhutan',
  36: 'Bosnia and Herzegovina',
  37: 'Botswana',
  38: 'Bouvet Island',
  39: 'British Indian Ocean Territory',
  40: 'Brunei Darussalam',
  41: 'Bulgaria',
  42: 'Burkina Faso',
  43: 'Burundi',
  44: 'Cambodia',
  45: 'Cameroon',
  46: 'Canada',
  47: 'Cape Verde',
  48: 'Cayman Islands',
  49: 'Central African Republic',
  50: 'Chad',
  51: 'China',
  52: 'Christmas Island',
  53: 'Cocos (Keeling) Islands',
  54: 'Comoros',
  55: 'Congo',
  56: 'Congo, The Democratic Republic of the',
  57: 'Cook Islands',
  58: 'Costa Rica',
  59: "Cote D'Ivoire",
  60: 'Croatia',
  61: 'Cuba',
  62: 'Cyprus',
  63: 'Czech Republic',
  64: 'Denmark',
  65: 'Djibouti',
  66: 'Dominica',
  67: 'Dominican Republic',
  68: 'Egypt',
  69: 'El Salvador',
  70: 'Equatorial Guinea',
  71: 'Eritrea',
  72: 'Estonia',
  73: 'Ethiopia',
  74: 'Falkland Islands (Malvinas)',
  75: 'Faroe Islands',
  76: 'Fiji',
  77: 'Finland',
  78: 'France',
  79: 'French Guiana',
  80: 'French Polynesia',
  81: 'French Southern Territories',
  82: 'Gabon',
  83: 'Gambia',
  84: 'Georgia',
  85: 'Germany',
  86: 'Ghana',
  87: 'Gibraltar',
  88: 'Greece',
  89: 'Greenland',
  90: 'Grenada',
  91: 'Guadeloupe',
  92: 'Guam',
  93: 'Guatemala',
  94: 'Guernsey',
  95: 'Guinea',
  96: 'Guinea-Bissau',
  97: 'Guyana',
  98: 'Haiti',
  99: 'Heard Island and Mcdonald Islands',
  100: 'Holy See (Vatican City State)',
  101: 'Honduras',
  102: 'Hong Kong',
  103: 'Hungary',
  104: 'Iceland',
  105: 'India',
  106: 'Indonesia',
  107: 'Iran, Islamic Republic Of',
  108: 'Iraq',
  109: 'Ireland',
  110: 'Isle of Man',
  111: 'Israel',
  112: 'Italy',
  113: 'Jamaica',
  114: 'Japan',
  115: 'Jersey',
  116: 'Jordan',
  117: 'Kazakhstan',
  118: 'Kenya',
  119: 'Kiribati',
  120: "Korea, Democratic People'S Republic of",
  121: 'Korea, Republic of',
  122: 'Kuwait',
  123: 'Kyrgyzstan',
  124: "Lao People'S Democratic Republic",
  125: 'Latvia',
  126: 'Lebanon',
  127: 'Lesotho',
  128: 'Liberia',
  129: 'Libyan Arab Jamahiriya',
  130: 'Liechtenstein',
  131: 'Lithuania',
  132: 'Luxembourg',
  133: 'Macao',
  134: 'Macedonia, The Former Yugoslav Republic of',
  135: 'Madagascar',
  136: 'Malawi',
  137: 'Malaysia',
  138: 'Maldives',
  139: 'Mali',
  140: 'Malta',
  141: 'Marshall Islands',
  142: 'Martinique',
  143: 'Mauritania',
  144: 'Mauritius',
  145: 'Mayotte',
  146: 'Micronesia, Federated States of',
  147: 'Moldova, Republic of',
  148: 'Monaco',
  149: 'Mongolia',
  150: 'Montserrat',
  151: 'Morocco',
  152: 'Mozambique',
  153: 'Myanmar',
  154: 'Namibia',
  155: 'Nauru',
  156: 'Nepal',
  157: 'Netherlands',
  158: 'Netherlands Antilles',
  159: 'New Caledonia',
  160: 'New Zealand',
  161: 'Nicaragua',
  162: 'Niger',
  163: 'Nigeria',
  164: 'Niue',
  165: 'Norfolk Island',
  166: 'Northern Mariana Islands',
  167: 'Norway',
  168: 'Oman',
  169: 'Pakistan',
  170: 'Palau',
  171: 'Palestinian Territory, Occupied',
  172: 'Panama',
  173: 'Papua New Guinea',
  174: 'Peru',
  175: 'Philippines',
  176: 'Pitcairn',
  177: 'Poland',
  178: 'Portugal',
  179: 'Puerto Rico',
  180: 'Qatar',
  181: 'Reunion',
  182: 'Romania',
  183: 'Russian Federation',
  184: 'Rwanda',
  185: 'Saint Helena',
  186: 'Saint Kitts and Nevis',
  187: 'Saint Lucia',
  188: 'Saint Pierre and Miquelon',
  189: 'Saint Vincent and the Grenadines',
  190: 'Samoa',
  191: 'San Marino',
  192: 'Sao Tome and Principe',
  193: 'Saudi Arabia',
  194: 'Senegal',
  195: 'Serbia and Montenegro',
  196: 'Seychelles',
  197: 'Sierra Leone',
  198: 'Singapore',
  199: 'Slovakia',
  200: 'Slovenia',
  201: 'Solomon Islands',
  202: 'Somalia',
  203: 'South Africa',
  204: 'South Georgia and the South Sandwich Islands',
  205: 'Spain',
  206: 'Sri Lanka',
  207: 'Sudan',
  208: 'Suriname',
  209: 'Svalbard and Jan Mayen',
  210: 'Swaziland',
  211: 'Sweden',
  212: 'Switzerland',
  213: 'Syrian Arab Republic',
  214: 'Taiwan, Province of China',
  215: 'Tajikistan',
  216: 'Tanzania, United Republic of',
  217: 'Thailand',
  218: 'Timor-Leste',
  219: 'Togo',
  220: 'Tokelau',
  221: 'Tonga',
  222: 'Trinidad and Tobago',
  223: 'Tunisia',
  224: 'Turkey',
  225: 'Turkmenistan',
  226: 'Turks and Caicos Islands',
  227: 'Tuvalu',
  228: 'Uganda',
  229: 'Ukraine',
  230: 'United Arab Emirates',
  231: 'United Kingdom',
  232: 'United States',
  233: 'United States Minor Outlying Islands',
  234: 'Uzbekistan',
  235: 'Vanuatu',
  236: 'Viet Nam',
  237: 'Virgin Islands, British',
  238: 'Virgin Islands, U.S.',
  239: 'Wallis and Futuna',
  240: 'Western Sahara',
  241: 'Yemen',
  242: 'Zambia',
  243: 'Zimbabwe',
};

export default countries;
