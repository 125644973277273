import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { fontsAvenir } from './fonts/avenirFonts';

const palette = {
  primary: {
    main: '#000000',
    dark: '#2E2E2E',
    light: '#D2D2D6',
    lighterGrey: '#F8F8F8',
    mediumGrey: '#757575',
  },
  secondary: {
    main: '#ffff01',
    light: '#FFFF77',
    dark: '#FFD600',
  },
  error: {
    main: '#FF345C',
  },
  warning: {
    main: '#FF8933',
  },
  success: {
    main: '#18E152',
  },
  info: {
    main: '#D500DF',
  },
} as const;

let baseTheme = createMuiTheme({ palette });

baseTheme = createMuiTheme({
  palette,
  typography: {
    fontFamily: '"Avenir Roman", "Avenir Book", Arial, sans-serif',
    h1: { fontFamily: '"Avenir Black", "Avenir Heavy", Arial, sans-serif' },
    h2: { fontFamily: '"Avenir Black", "Avenir Heavy", Arial, sans-serif' },
    h3: { fontFamily: '"Avenir Black", "Avenir Heavy", Arial, sans-serif' },
    h4: { fontFamily: '"Avenir Black", "Avenir Heavy", Arial, sans-serif' },
    h5: { fontFamily: '"Avenir Heavy", "Avenir Black", Arial, sans-serif' },
    h6: { fontFamily: '"Avenir Black", "Avenir Heavy", Arial, sans-serif' },
    body1: { fontFamily: '"Avenir Roman", "Avenir Book", Arial, sans-serif' },
    body2: { fontFamily: '"Avenir Roman", "Avenir Book", Arial, sans-serif' },
    button: { fontFamily: '"Avenir Black", "Avenir Heavy", Arial, sans-serif' },
    caption: { fontFamily: '"Avenir Book", "Avenir Roman", Arial, sans-serif' },
    subtitle1: {
      fontFamily: '"Avenir Book", "Avenir Roman", Arial, sans-serif',
    },
    subtitle2: {
      fontFamily: '"Avenir Book", "Avenir Roman", Arial, sans-serif',
    },
    overline: {
      fontFamily: '"Avenir Book", "Avenir Roman", Arial, sans-serif',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [...fontsAvenir],
        '*': {
          scrollbarColor: '#d5d5d5 transparent',
          scrollbarWidth: 'thin',
        },
        '*::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
        },
        '*::-webkit-scrollbar:vertical': {
          width: '10px',
        },
        '*::-webkit-scrollbar-button:increment, *::-webkit-scrollbar-button': {
          display: 'none;',
        },
        '*::-webkit-scrollbar:horizontal': {
          height: '10px',
        },
        '*::-webkit-scrollbar-thumb': {
          cursor: 'move',
          backgroundColor: '#d5d5d5',
          borderRadius: '20px',
          border: 'none',
        },
        '*::-webkit-scrollbar-track': {
          background: 'transparent',
          borderRadius: '10px',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 8,
        padding: '12px 40px',
        fontWeight: 750,
        fontSize: 16,
      },
      outlined: {
        padding: '12px 40px',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#2e2e2d',
        },
        '&:active': {
          backgroundColor: '#2e2e2d',
          color: '#757575',
        },
        '&:disabled': {
          color: baseTheme.palette.common.white,
        },
      },
      sizeLarge: {
        padding: '16px',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'black',
        color: 'white',
      },
    },
    MuiAvatar: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiCircularProgress: {
      svg: {
        maxWidth: '100px',
        maxHeight: '100px',
      },
      colorSecondary: {
        color: '#D2D2D6',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiAccordionSummary: {
      root: {
        '&$expanded': {
          transition:
            'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
      expandIcon: {
        transform: 'rotate(0deg)',
        '&$expanded': {
          transform: 'rotate(90deg)',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        width: '100%',
        height: 8,
        borderRadius: 4,
      },
      colorPrimary: {
        backgroundColor: baseTheme.palette.grey['50'],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
      },
      barColorPrimary: {
        backgroundColor: palette.secondary.dark,
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: '56px',
      },
      notchedOutline: {
        borderColor: baseTheme.palette.common.black,
        '& span': {
          fontWeight: 900,
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        '&.MuiInputLabel-shrink': {
          fontWeight: 900,
          fontSize: '21px',
          lineHeight: '19px',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        padding: '9px 15px',
        backgroundColor: palette.primary.dark,
        borderRadius: 8,
        fontWeight: 900,
      },
      arrow: {
        color: palette.primary.dark,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 100,
      xsMinHeight: 75,
    },
  },
});

const theme = responsiveFontSizes(baseTheme);

export default theme;
