import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    deleteNotificationIcon: {
      [theme.breakpoints.down('xs')]: {
        right: 28,
      },
      right: 0,
    },
  })
);
