import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    containerAndFooter: {
      overflow: 'auto',
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      [theme.breakpoints.down('xs')]: {
        height: `calc(100vh - ${theme.mixins.toolbar.xsMinHeight}px)`,
        // @ts-ignore
        backgroundColor: theme.palette.primary.lighterGrey,
      },
    },
    container: {
      // TODO move footer height to theme
      minHeight: `calc(calc(100vh - ${theme.mixins.toolbar.minHeight}px) - 50px)`,
      [theme.breakpoints.down('xs')]: {
        padding: '0 8px',
        minHeight: `calc(100vh - ${theme.mixins.toolbar.xsMinHeight}px)`,
      },
    },
  })
);
