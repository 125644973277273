import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';

const style = (theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      margin: 'auto',
      position: 'absolute',
      marginTop: theme.mixins.toolbar.minHeight,
      marginBottom: 70,
      height: 'auto',
      maxHeight: 'auto',
      [theme.breakpoints.down('xs')]: {
        // TODO move footer height to theme
        height: `calc(100% - ${theme.mixins.toolbar.xsMinHeight}px)`,
        marginTop: theme.mixins.toolbar.xsMinHeight,
      },
    },
  });

export default style;
