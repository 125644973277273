import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { TableData } from '../Table/interfaces';
import useStyles from './styles';

export interface TableCellProps {
  children: React.ReactNode;
  onClick?: () => void;
  order?: 'asc' | 'desc' | null | 'disabled';
}

export function TableCell({ onClick, order, children }: TableCellProps) {
  const classes = useStyles();
  return (
    <MuiTableCell
      onClick={onClick}
      className={clsx([
        classes.tableCellHeader,
        order === 'disabled' && classes.disableReorder,
      ])}
      align="center"
    >
      <Box display="flex" justifyContent="center" flexWrap="noWrap">
        {children}
        {order !== 'disabled' && (
          <IconButton style={{ marginLeft: 2 }}>
            {order === 'asc' ? (
              <ExpandMore style={{ fontSize: 15 }} />
            ) : (
              <ExpandLess style={{ fontSize: 15 }} />
            )}
          </IconButton>
        )}
      </Box>
    </MuiTableCell>
  );
}

function TableHeader<Data = unknown>({
  columns,
  onOrderChange,
  localization,
}: TableData<Data>) {
  const [orderBy, setOrderBy] = useState<{ [x: number]: 'asc' | 'desc' }>(
    columns.reduce(
      (res, _h, i) => ({
        ...res,
        [i]: 'asc',
      }),
      {}
    )
  );

  const handleOrderChange = (i: number) => {
    const newOrderBy = orderBy[i] === 'asc' ? 'desc' : 'asc';
    setOrderBy({
      ...Object.keys(orderBy).reduce(
        (res, i) => ({
          ...res,
          [i]: 'asc',
        }),
        {}
      ),
      [i]: newOrderBy,
    });
    onOrderChange?.(i, newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ title, sort }, i) => (
          <TableCell
            key={title}
            order={sort ? orderBy[i] : 'disabled'}
            onClick={sort ? () => handleOrderChange(i) : undefined}
          >
            {title}
          </TableCell>
        ))}
        {localization?.actions !== undefined && (
          <TableCell order="disabled">
            <>{localization.actions}</>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
