import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Send from '@material-ui/icons/Send';
import { AnySchema } from 'yup';
import * as yup from 'yup';
import useErrorHandler from '../../services/ErrorHandler';
import {
  getDeclarationById,
  useGetGraduateDeclarationHistory,
} from '../../services/collections.service';
import { useMatchRouteModal } from '../../common/functions';
import { getNeccesaryFields } from '../DDJJReview/functions';
import { submitDeclaration } from '../GraduateDashboard/functions';
import { DeclarationStatus, DeclarationType } from '../../common/enums';
import { DeclarationInstance } from '../../common/interfaces';
import { FieldValue } from '../QuestionItem/interfaces';
import { useAuth } from '../../providers/Auth';
import { useGeneralContext } from '../../providers/GeneralContext';
import { useGraduateContext } from '../../providers/GraduateContext';
import useIsThisScreen from '../../utils/useIsThisScreen';
import { useQuestions } from '../DDJJForm/questions';
import DeclarationHistoryButton from '../DeclarationHistory/Button';
import FeedbackSection from '../FeedbackSection';
import Modal from '../Modal';
import Spinner from '../Spinner';
import Header from './Header';

const DetailPanel = function ({ match }: RouteComponentProps<{ id: string }>) {
  const { getAccessToken } = useAuth();
  const { isModal } = useGeneralContext();
  const { setSuccessAlert, refreshTable } = useGraduateContext();
  const { apiCatcher } = useErrorHandler();
  const questions = useQuestions();
  const isSmScreen = useIsThisScreen('sm');
  const history = useHistory();
  const location = useLocation();

  const [declaration, setDeclaration] = useState<DeclarationInstance>(null);
  const [loadingDeclaration, setLoadingDeclaration] = useState<boolean>(true);

  const id = Number(match.params.id);
  const { data: declarationHistory, loading: loadingDeclarationHistory } =
    id && useGetGraduateDeclarationHistory(id);

  const [selectedIndexHistory, setSelectedIndexHistory] = useState(0);

  const closeDeclarationDetail = useCallback(() => {
    history.replace('/', {
      modal: false,
    });
  }, [history]);

  useMatchRouteModal(location.pathname);

  useEffect(() => {
    if (!Number.isFinite(Number(match.params.id))) {
      history.replace('/');
      return;
    }
    getDeclarationById(getAccessToken, Number(match.params.id))
      .then((response) => {
        if (
          [DeclarationStatus.EMPTY, DeclarationStatus.DRAFT].includes(
            response.data.status
          )
        ) {
          history.replace(`/declaration/${response.data.id}/form`);
        }
        setDeclaration(response.data);
        setLoadingDeclaration(false);
      })
      .catch((error) => {
        closeDeclarationDetail();
        apiCatcher('severe')(error);
      });
  }, [
    match.params.id,
    getAccessToken,
    apiCatcher,
    closeDeclarationDetail,
    history,
    isModal,
  ]);

  const getCorrections = useCallback(() => {
    const needCorrectionQuestions =
      declaration?.reviewData &&
      Object.entries(declaration?.reviewData)
        .filter(
          ([_questionName, review]) =>
            typeof review === 'object' &&
            (review.value === 'rejected' || review.value === 'pending')
        )
        .map(([questionName]) => questionName);
    return needCorrectionQuestions?.reduce(
      (acc, questionName) => ({
        ...acc,
        [questionName]: '',
      }),
      {}
    );
  }, [declaration]);

  const [corrections, setCorrections] = useState<{
    [questionName: string]: FieldValue;
  }>(null);

  const [errors, setErrors] = useState<{ [questionName: string]: string }>({});
  const [validations, setValidations] = useState<{
    [questionName: string]: AnySchema;
  }>({});

  useEffect(() => {
    const corrections = getCorrections();
    const validations =
      corrections &&
      Object.keys(corrections).reduce((validations, key) => {
        return {
          ...validations,
          [key]: questions[key].validations,
        };
      }, {});
    setCorrections(corrections);
    setValidations(validations);
  }, [getCorrections]);

  const selectedDeclarationItem =
    declarationHistory?.history?.[selectedIndexHistory];

  const handleCorrections = async (questionName: string, value: FieldValue) => {
    const validator = validations[questionName];
    try {
      if (validator) {
        await validator.validate(value, { strict: true });
      }
      const newErrors = { ...errors };
      delete newErrors[questionName];
      setErrors(newErrors);
    } catch (error) {
      setErrors({ ...errors, [questionName]: error.message });
    }
    setCorrections({
      ...corrections,
      [questionName]: value,
    });
  };

  const questionsResolved =
    corrections &&
    (declaration?.status === DeclarationStatus.ACCEPTED_WITH_PENDINGS ||
      declaration?.status === DeclarationStatus.REJECTED) &&
    !Object.keys(errors).length &&
    Object.values(corrections).every((value) => value);

  const renderPresentButton =
    declaration?.status === DeclarationStatus.REJECTED ||
    declaration?.status === DeclarationStatus.ACCEPTED_WITH_PENDINGS;

  const isRejectedStayUnemploymentType =
    declaration?.reviewData?.status === DeclarationStatus.REJECTED &&
    declaration?.reviewData?.type === DeclarationType.STAY_UNEMPLOYMENT;

  const handleSubmitCorrection = () => {
    const newDeclaredData = { ...declaration?.declaredData };

    if (declaration?.reviewData) {
      Object.entries(declaration.reviewData).forEach(([field, review]) => {
        if (review.correction) {
          newDeclaredData[field] = review.correction;
        }
      });
    }

    submitDeclaration(
      {
        id: declaration?.id,
        periodId: declaration?.period.id,
        ...(newDeclaredData || declaration?.declaredData),
        ...corrections,
      },
      DeclarationStatus.PRESENTED,
      declaration?.type,
      getAccessToken
    )
      .then(() => {
        refreshTable();
        setSuccessAlert(true);
        closeDeclarationDetail();
      })
      .catch(apiCatcher('severe'));
  };

  const redirectToForm = () => {
    history.push(`/declaration/${declaration?.id}/form`);
  };

  const loading = loadingDeclaration || loadingDeclarationHistory;

  return (
    <Modal open onClose={closeDeclarationDetail}>
      <Container
        maxWidth="md"
        style={{
          height: 'fit-content',
          padding: isSmScreen && 0,
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Paper
            style={{
              padding: '20px 25px',
              height: isSmScreen && '100%',
            }}
          >
            <Grid item xs={12}>
              <Header onClose={closeDeclarationDetail} />
            </Grid>
            <Grid item xs={12} container>
              <FeedbackSection
                onChange={handleCorrections}
                corrections={corrections}
                errors={errors}
                declaration={{
                  ...declaration,
                  status: (
                    selectedDeclarationItem?.reviewData ||
                    selectedDeclarationItem?.declaredData
                  )?.status,
                  reviewData: selectedDeclarationItem?.reviewData,
                  declaredData: selectedDeclarationItem?.declaredData,
                }}
                isWatchingHistory={selectedIndexHistory !== 0}
                fieldsToRender={getNeccesaryFields(declaration?.type)}
              />
            </Grid>
            <Grid container item xs={12}>
              {declarationHistory?.history && (
                <Box
                  width="100%"
                  mt={2}
                  justifyContent="flex-end"
                  display="flex"
                >
                  <DeclarationHistoryButton
                    declarationHistory={declarationHistory}
                    selectedIndex={selectedIndexHistory}
                    onClickItem={(selectedIndex: number) => {
                      setSelectedIndexHistory(selectedIndex);
                    }}
                  />
                </Box>
              )}
            </Grid>
            {renderPresentButton && (
              <Grid container item xs={12}>
                <Box
                  width="100%"
                  mt={2}
                  justifyContent="flex-end"
                  display="flex"
                >
                  <Button
                    disabled={!questionsResolved}
                    onClick={
                      isRejectedStayUnemploymentType
                        ? redirectToForm
                        : handleSubmitCorrection
                    }
                    endIcon={!isRejectedStayUnemploymentType && <Send />}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {isRejectedStayUnemploymentType
                      ? 'Completar nuevamente'
                      : 'Volver a presentar'}
                  </Button>
                </Box>
              </Grid>
            )}
          </Paper>
        )}
      </Container>
    </Modal>
  );
};

export default DetailPanel;
