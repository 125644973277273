import React from 'react';
import Box from '@material-ui/core/Box';
import { Input } from '@soyhenry/commons';
import { useQuery } from '../../../../common/functions';
import { useBackofficeContext } from '../../../../providers/BackofficeContext';
import FilterLabel from '../FilterLabel';

export default function CompanyNameFilter() {
  const { DDJJContext } = useBackofficeContext();
  const { query, changeQuery } = useQuery();

  return (
    <>
      {DDJJContext?.companyName && (
        <Box mb={0.5}>
          <FilterLabel>Empleador</FilterLabel>
          <Input
            type="autocomplete"
            variant="outlined"
            style={{ width: '100%' }}
            InputProps={{ style: { borderRadius: 10 } }}
            name="companyName"
            disableNewOptions
            options={DDJJContext.companyName}
            value={query.companyName || ''}
            onChange={({ value }) =>
              changeQuery({
                name: 'companyName',
                value: String(value),
              })
            }
          />
        </Box>
      )}
    </>
  );
}
