import React, { useState } from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Alert from '@material-ui/lab/Alert';
import useErrorHandler from '../../services/ErrorHandler';
import {
  sendReviewDeclaration,
  useGetDeclarationById,
} from '../../services/collections.service';
import { DeclarationStatus, Role } from '../../common/enums';
import { ReviewValue } from '../Field/interfaces';
import { FieldValue } from '../QuestionItem/interfaces';
import { useAuth } from '../../providers/Auth';
import { useGeneralContext } from '../../providers/GeneralContext';
import Spinner from '../Spinner';
import Review from './Review';

function DDJJReview(props: RouteComponentProps<{ id: string }>) {
  const history = useHistory();
  const { apiCatcher } = useErrorHandler();
  const { getAccessToken } = useAuth();
  const { selectedRole } = useGeneralContext();
  const {
    data: declaration,
    loading,
    error,
    refresh: refreshDeclaration,
  } = useGetDeclarationById(Number(props.match.params.id));

  const [alert, setAlert] = useState<{ isOpen: boolean; message: string }>({
    isOpen: false,
    message: '',
  });

  const handleBack = () => {
    history.goBack();
  };

  const closeAlert = () => {
    setAlert({
      isOpen: false,
      message: '',
    });
  };

  const getAlertMessage = (action: DeclarationStatus) => {
    const isAccepted =
      action === DeclarationStatus.ACCEPTED ||
      action === DeclarationStatus.ACCEPTED_WITH_CORRECTIONS ||
      action === DeclarationStatus.ACCEPTED_WITH_PENDINGS;
    const isSaved = action === DeclarationStatus.IN_REVIEW;
    return `Declaración ${
      isSaved ? 'guardada' : isAccepted ? 'aceptada' : 'rechazada'
    } correctamente.`;
  };

  const openSuccessAlert = (action: DeclarationStatus) => {
    setAlert({
      isOpen: true,
      message: getAlertMessage(action),
    });
  };

  const handleSubmit = (
    values: {
      id: number;
      backofficeNote: string | undefined;
      [x: string]:
        | {
            value: ReviewValue;
            correction?: FieldValue;
          }
        | string
        | number;
    },
    action: DeclarationStatus
  ) => {
    const { id } = values;
    // eslint-disable-next-line no-param-reassign
    delete values.id;
    return sendReviewDeclaration(
      typeof id === 'number' && id,
      {
        status: action,
        ...values,
      },
      getAccessToken
    )
      .then(() => {
        openSuccessAlert(action);
        return refreshDeclaration();
      })
      .catch((error) => {
        apiCatcher('warning')(error);
        throw error;
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error || (!loading && !declaration) || selectedRole !== Role.backoffice) {
    if (error) {
      apiCatcher('severe')(error);
    }
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="xl">
      <Box
        style={{
          padding: '1rem 1rem 2.5rem 1rem',
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Button onClick={handleBack} startIcon={<ArrowBackIos />}>
              Volver
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Review declaration={declaration} onSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={alert.isOpen}
        autoHideDuration={5000}
        onClose={closeAlert}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <Alert onClose={closeAlert} severity="success">
          {alert.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default DDJJReview;
