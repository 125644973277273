import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';

const style = (theme: Theme) =>
  createStyles({
    rootContainer: {
      minHeight: '100vh',
      minWidth: '100%',
      background: 'black',
      textAlign: 'center',
    },
    title: {
      color: theme.palette.common.white,
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      },
    },
    button: {
      textTransform: 'none',
      backgroundColor: '#FFFF01',
      color: theme.palette.common.black,
      width: '60%',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });

export default style;
