import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { ThemeProvider as SoyHenryThemeProvider } from '@soyhenry/commons';
import theme from '../src/utils/theme';
import App from './App';
import { AuthProvider } from './providers/Auth';
import { BackofficeContextProvider } from './providers/BackofficeContext';
import { GeneralContextProvider } from './providers/GeneralContext';
import { GraduateContextProvider } from './providers/GraduateContext';

const { REACT_APP_AUTH_URL } = process.env;

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider authUrl={REACT_APP_AUTH_URL}>
      <MuiThemeProvider theme={theme}>
        <SoyHenryThemeProvider>
          <BrowserRouter>
            <GeneralContextProvider>
              <BackofficeContextProvider>
                <GraduateContextProvider>
                  <App />
                </GraduateContextProvider>
              </BackofficeContextProvider>
            </GeneralContextProvider>
            <CssBaseline />
          </BrowserRouter>
        </SoyHenryThemeProvider>
      </MuiThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
