import React, {
  createContext,
  useContext,
  useState,
  ProviderProps,
} from 'react';
import { AxiosError } from 'axios';
import SevereErrors from './SevereErrors';
import WarningErrors from './WarningErrors';

type Error = string | string[];
interface IndexedError {
  index: number;
  message: string;
  isHtmlError: boolean;
}
type Level = 'warning' | 'severe';
type ErrorCatcher = (
  level?: Level
) => (errors: Error, isHtmlError: boolean) => Promise<void>;
type ApiCatcher = (level?: Level) => (error: AxiosError) => Promise<void>;

interface Context {
  errors: Record<Level, IndexedError[]>;
  errorCatcher: ErrorCatcher;
  apiCatcher: ApiCatcher;
}

const ErrorsHandlerContext = createContext<Context>({
  errors: { warning: [], severe: [] },
  errorCatcher: () => () => Promise.resolve(),
  apiCatcher: () => () => Promise.resolve(),
});

const indexes: Record<Level, number> = {
  warning: 1,
  severe: 1,
};
const promise: Promise<void> = Promise.resolve();
const resolveStack: Array<(value: void | Promise<void>) => void> = [];
export function ErrorHandlerProvider({
  children,
  ...props
}: Partial<ProviderProps<Context>>) {
  const [errors, setErrors] = useState<Record<Level, IndexedError[]>>({
    warning: [],
    severe: [],
  });
  const errorCatcher: ErrorCatcher =
    (level = 'warning') =>
    (error, isHtmlError = false) => {
      setErrors((errors) => ({
        ...errors,
        [level]: errors[level].concat(
          error instanceof Array
            ? error.map((error) => ({
                message: error,
                index: indexes[level] + 1,
                isHtmlError,
              }))
            : { message: error, index: indexes[level] + 1, isHtmlError }
        ),
      }));
      return level === 'warning'
        ? Promise.resolve()
        : promise.then(
            () =>
              new Promise((resolve) => {
                resolveStack.push(resolve);
              })
          );
    };
  const apiCatcher: ApiCatcher =
    (level = 'warning') =>
    (error) => {
      const errorData: AxiosError<
        { message: string | string[] } | string
      >['response']['data'] = error.response?.data;
      if (errorData) {
        const isHtmlError =
          error.response?.headers['content-type'].includes('text/html');
        return errorCatcher(isHtmlError ? 'severe' : level)(
          isHtmlError && typeof errorData === 'string'
            ? errorData
            : typeof errorData === 'object'
            ? errorData.message
            : '',
          isHtmlError
        );
      }
      if (error.message) {
        return errorCatcher(level)(error.message, false);
      }
      return Promise.resolve();
    };
  return (
    <ErrorsHandlerContext.Provider
      {...props}
      value={{
        errors,
        errorCatcher,
        apiCatcher,
      }}
    >
      <WarningErrors
        errors={errors.warning}
        onClose={(index) => {
          setErrors((errors) => ({
            ...errors,
            warning: errors.warning.filter((err) => err.index !== index),
          }));
        }}
      />
      <SevereErrors
        errors={errors.severe}
        onClose={() => {
          while (resolveStack.length) {
            resolveStack.shift()();
          }
          setErrors((errors) => ({
            ...errors,
            severe: [],
          }));
        }}
      />
      {children}
    </ErrorsHandlerContext.Provider>
  );
}

const useErrorHandler = () => useContext(ErrorsHandlerContext);

export default useErrorHandler;
