import moment from 'moment';
import { sendDeclaration } from '../../services/collections.service';
import { DeclarationStatus, DeclarationType } from '../../common/enums';
import { DeclaredData } from '../../common/interfaces';

export const modalityKeys = {
  'Empleado Full Time': 'fullTime',
  'Empleado Part Time': 'partTime',
  Contractor: 'contractor',
  Otro: 'other',
};

// TODO: move to /commons
export const statusNames = {
  draft: 'Borrador',
  presented: 'Presentada',
  rejected: 'Rechazada',
  accepted: 'Aceptada',
  acceptedWithCorrections: 'Aceptada con corrección',
  acceptedWithPendings: 'Aceptada con pendientes',
  inReview: 'En revisión',
  empty: 'Pendiente',
  resubmitted: 'Presentada corregida',
  notPresented: 'No presentada',
};

const completedItemBgColor = '#c8e6c9';
const nonCompletedItemBgColor = '#ffe0b2';

export const getStatusName = (status: DeclarationStatus) => statusNames[status];

export const setDetailItemBgColor = (item) =>
  item.description || (item.link && item.text)
    ? completedItemBgColor
    : nonCompletedItemBgColor;

// TODO: move to /commons
export const formatDate = (date: Date | string, withHour = false) =>
  date && moment(date).format(`DD/MM/YYYY${withHour ? ' hh:mm a' : ''}`);

export type DeclarationPayload = Omit<
  DeclaredData,
  'date' | 'incomeDocumentFile' | 'status' | 'type'
> & {
  periodId: number;
  id?: number;
  incomeDocumentFile?: (File & { key: string; url: string }) | string;
};

export const getPayload = (
  answers: DeclarationPayload,
  type: DeclarationType
) => {
  const payload = { ...answers };
  if (
    !(
      type === DeclarationType.CHANGE_EMPLOYMENT_UNEMPLOYMENT ||
      type === DeclarationType.UNEMPLOYMENT
    )
  ) {
    delete payload.endDate;
  }
  if (payload.incomeDocumentFile) {
    payload.incomeDocumentFile =
      typeof payload.incomeDocumentFile === 'string'
        ? payload.incomeDocumentFile
        : payload.incomeDocumentFile.key;
  } else {
    // if not upload incomeDocumentFile, should not send incomeDocumentType
    payload.incomeDocumentType = undefined;
  }
  return payload;
};

export const submitDeclaration = (
  answers: DeclarationPayload,
  status: DeclarationStatus,
  type: DeclarationType,
  getAccessToken?
) => {
  const payload = getPayload(answers, type);
  const urlData = {
    type,
    status,
  };
  return sendDeclaration(payload, urlData, getAccessToken);
};
