import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircle from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import MaterialTable, { Action, Column, Options } from 'material-table';
import NumberFormat from 'react-number-format';
import useErrorHandler from '../../../services/ErrorHandler';
import {
  getBackofficeDeclarations,
  sendBulkApproval,
  sendReviewDeclaration,
} from '../../../services/collections.service';
import { getDeclarationTypeName, useQuery } from '../../../common/functions';
import { getNeccesaryFields } from '../../DDJJReview/functions';
import { DeclarationStatus, DeclarationType } from '../../../common/enums';
import { DeclarationInstance } from '../../../common/interfaces';
import { ReviewValue } from '../../Field/interfaces';
import { AcceptConfirmation, Data } from './interfaces';
import { useAuth } from '../../../providers/Auth';
import DeclarationStatusChip from '../../DeclarationStatusChip';
import DialogComponent from '../../Dialog';
import IncomeDocumentButton from '../../IncomeDocumentButton';
import { IncomeDocumentViewModal } from '../../IncomeDocumentView';
import TableToolbar from './TableToolbar';
import {
  defaultPageSize,
  defaultPageSizeOptions,
  isPendingState,
  isReviewedState,
  isStayState,
  tableOptions,
  tableIcons,
  tableLocalization,
} from './constants';
import useStyles from './styles';

function TableComponent() {
  const history = useHistory();
  const location = useLocation();
  const { query } = useQuery();
  const classes = useStyles();
  const { apiCatcher } = useErrorHandler();
  const { getAccessToken } = useAuth();
  const [incomeDocumentFile, setIncomeDocumentFile] = useState(null);
  const [acceptConfirmation, setAcceptConfirmation] =
    useState<AcceptConfirmation>(null);
  const [bulkSelected, setBulkSelected] = useState<number[]>([]);
  const [paginationSize, setPaginationSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [showBulkModal, setShowBulkModal] = useState(false);

  const tableRef = useRef(null);

  const renderBulkSelect = useMemo(
    () => query.type === DeclarationType.STAY_UNEMPLOYMENT,
    [query.type]
  );

  const notRenderAcceptButton = useMemo(
    () => location.pathname.includes('/graduates') || renderBulkSelect,
    [location.pathname, renderBulkSelect]
  );

  const handleSelection = useCallback(
    (rows: Data[]) => {
      let startFrom = 0;
      let endOn = rows.length;
      if (rows.length > paginationSize) {
        startFrom = currentPage * paginationSize;
        endOn = currentPage === 0 ? paginationSize : paginationSize * 2;
      }

      const ids = rows.slice(startFrom, endOn).map((opt) => opt.id);
      setBulkSelected(ids);
    },
    [currentPage, paginationSize, tableRef.current]
  );

  const handleSelectionReset = useCallback(() => {
    tableRef.current.onAllSelected(false);
  }, [tableRef.current]);

  const handleChangePage = useCallback(
    (newPage: number) => {
      handleSelectionReset();
      setCurrentPage(newPage);
    },
    [tableRef.current]
  );

  const handleChangePageSize = useCallback(
    (size: number) => {
      setPaginationSize(size);
    },
    [tableRef.current]
  );

  const parseData = useCallback((data: DeclarationInstance[]): Data[] => {
    return data.map(
      ({ id, period, type, status, graduate, declaredData, reviewData }) => {
        const incomeDocument =
          reviewData?.incomeDocumentFile?.correction ||
          declaredData?.incomeDocumentFile;
        const incomeCurrency =
          reviewData?.incomeCurrency?.correction ||
          declaredData?.incomeCurrency;
        const incomeAmount = String(
          reviewData?.incomeAmount?.correction || declaredData?.incomeAmount
        );
        const incomeAmountFormatted = (
          <NumberFormat
            value={incomeAmount}
            displayType="text"
            thousandSeparator
          />
        );
        const companyName =
          reviewData?.companyName?.correction || declaredData?.companyName;
        const localStatus =
          status === DeclarationStatus.DRAFT ? DeclarationStatus.EMPTY : status;
        const declarationType = getDeclarationTypeName(type);

        return {
          id,
          fullName: (
            <Tooltip arrow title={graduate.fullName ?? ''}>
              <Typography align="center" noWrap variant="body2">
                {graduate.fullName}
              </Typography>
            </Tooltip>
          ),
          type: (
            <Tooltip arrow title={declarationType ?? ''}>
              <Typography align="center" noWrap variant="body2">
                {declarationType}
              </Typography>
            </Tooltip>
          ),
          notParsedType: type,
          period: period?.text,
          status: (
            <DeclarationStatusChip
              status={localStatus}
              style={{ width: '100%' }}
            />
          ),
          notParsedStatus: localStatus,
          declaredData,
          incomeDocument: (
            <IncomeDocumentButton
              onClick={() => setIncomeDocumentFile(incomeDocument)}
              disabled={!incomeDocument}
              tooltip={
                incomeDocument ? 'Ver comprobante' : 'Comprobante no disponible'
              }
            />
          ),
          incomeCurrency,
          incomeAmount: (
            <Tooltip arrow title={incomeAmountFormatted}>
              {incomeAmountFormatted}
            </Tooltip>
          ),
          companyName: (
            <Tooltip arrow title={companyName ?? ''}>
              <Typography align="center" noWrap variant="body2">
                {companyName}
              </Typography>
            </Tooltip>
          ),
        };
      }
    );
  }, []);

  const openBulkModal = useCallback(() => {
    setShowBulkModal(true);
  }, []);

  const closeBulkModal = useCallback(() => {
    setShowBulkModal(false);
  }, []);

  const bulkModalTitle = useMemo(() => {
    return `¿Estás seguro de aceptar las ${bulkSelected.length} declaraciones seleccionadas?`;
  }, [bulkSelected]);

  const handleOpenAcceptConfirmation = useCallback(
    ({ id, notParsedType, declaredData }: Data) => {
      const neccesaryFields = getNeccesaryFields(notParsedType);
      const pendingFields = neccesaryFields.filter((key) => !declaredData[key]);
      const status = pendingFields.length
        ? DeclarationStatus.ACCEPTED_WITH_PENDINGS
        : DeclarationStatus.ACCEPTED;

      setAcceptConfirmation({
        id,
        status,
        type: notParsedType,
        pendingFields,
      });
    },
    []
  );

  const toggleIncomeDocument = useCallback(
    () => setIncomeDocumentFile(null),
    []
  );

  const confirmationModalTitle = useMemo(() => {
    const hasPendings =
      acceptConfirmation?.status === DeclarationStatus.ACCEPTED_WITH_PENDINGS;
    if (hasPendings) {
      return `La DJ #${acceptConfirmation?.id} tiene campos pendientes ¿Estás seguro de aceptarla con pendientes?`;
    }
    return `¿Estás seguro de aceptar la DJ #${acceptConfirmation?.id}?`;
  }, [acceptConfirmation]);

  const closeAcceptConfirmation = useCallback(() => {
    setAcceptConfirmation(null);
  }, []);

  const rowAction = useCallback(
    (rowData: Data): Action<Data> => {
      if (notRenderAcceptButton) {
        return null;
      }

      const isPending = isPendingState.includes(rowData.notParsedStatus);
      const isReviewed = !isReviewedState.includes(rowData.notParsedStatus);
      const isStay = !isStayState.includes(rowData.notParsedType);
      const disabledAcceptButton = isStay || isReviewed || isPending;

      const tooltipTitle = isReviewed
        ? 'Esta DJ ya fue revisada'
        : disabledAcceptButton
        ? 'No puedes aceptar este tipo de DJ'
        : 'Aceptar DJ';

      return {
        icon: memo(() => (
          <CheckCircle
            style={{ fontSize: 28 }}
            className={clsx([
              !disabledAcceptButton && classes.acceptButtonTable,
            ])}
          />
        )),
        tooltip: tooltipTitle,
        onClick: () => handleOpenAcceptConfirmation(rowData),
        disabled: disabledAcceptButton,
      };
    },
    [notRenderAcceptButton]
  );

  const handleOnRowClick = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: Data) => {
      const isNotPending = !isPendingState.includes(data.notParsedStatus);

      if (isNotPending) {
        const { tagName } = event.target as HTMLElement;

        // prevent open dj review when click on incomeDocumentButton
        if (['svg', 'span', 'path'].includes(tagName.toLowerCase())) {
          return;
        }

        history.push(`/declaration/${data.id}/review`);
      }
    },
    []
  );

  const tableColumns: Column<Data>[] = useMemo(
    () => [
      {
        field: 'fullName',
        title: 'Graduado',
      },
      {
        field: 'id',
        title: 'DJ #',
        headerStyle: { maxWidth: 70, width: 70 },
        cellStyle: {
          textAlign: 'center',
          maxWidth: 70,
          width: 70,
        },
      },
      {
        field: 'period',
        title: 'Período',
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'companyName',
        title: 'Empleador',
      },
      {
        field: 'type',
        title: 'Tipo DJ',
        headerStyle: { minWidth: 150 },
        cellStyle: { maxWidth: 112 },
      },
      {
        field: 'incomeDocument',
        title: 'Comprobante',
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'incomeCurrency',
        title: 'Moneda',
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'incomeAmount',
        title: 'Ingresos',
        headerStyle: { maxWidth: 112 },
        cellStyle: { maxWidth: 112, textAlign: 'center' },
      },
      {
        field: 'status',
        title: 'Status',
        cellStyle: {
          textAlign: 'center',
        },
      },
    ],
    [incomeDocumentFile]
  );

  const tableOpt: Options<Data> = useMemo(
    () => ({
      ...tableOptions,
      pageSize: paginationSize,
      selection: renderBulkSelect,
      pageSizeOptions: defaultPageSizeOptions,
    }),
    [renderBulkSelect, paginationSize, defaultPageSizeOptions]
  );

  const reload = useCallback(() => {
    setBulkSelected([]);
    tableRef.current.onQueryChange();
  }, [tableRef.current]);

  const getTableData = ({
    pageSize,
    page,
  }: {
    pageSize: number;
    page: number;
  }) => {
    return getBackofficeDeclarations(getAccessToken, {
      pageSize,
      page,
      ...query,
    }).then(({ data, ...pagination }) => {
      return {
        data: parseData(data),
        ...pagination,
      };
    });
  };

  const handleAcceptDeclaration = useCallback(() => {
    const { id, type, status, pendingFields } = acceptConfirmation;

    sendReviewDeclaration(
      id,
      {
        ...getNeccesaryFields(type).reduce(
          (res, field) => ({
            ...res,
            [field]: {
              value: pendingFields.includes(field)
                ? ReviewValue.PENDING
                : ReviewValue.ACCEPTED,
            },
          }),
          {}
        ),
        status,
      },
      getAccessToken
    )
      .then(() => {
        tableRef.current.onQueryChange();
        closeAcceptConfirmation();
      })
      .catch(apiCatcher('severe'));
  }, [acceptConfirmation]);

  const handleBulkApproval = useCallback(() => {
    sendBulkApproval(bulkSelected, getAccessToken)
      .then(() => {
        closeBulkModal();
        reload();
      })
      .catch(apiCatcher('severe'));
  }, [bulkSelected]);

  useEffect(() => {
    reload();
  }, [query]);

  return (
    <>
      <TableToolbar
        bulkSelection={renderBulkSelect && !!bulkSelected.length}
        onClickBulkApproval={openBulkModal}
      />
      <Container maxWidth="xl" disableGutters className={classes.table}>
        <MaterialTable
          data={getTableData}
          tableRef={tableRef}
          columns={tableColumns}
          icons={tableIcons}
          onSelectionChange={handleSelection}
          onChangeRowsPerPage={handleChangePageSize}
          onChangePage={handleChangePage}
          onRowClick={handleOnRowClick}
          actions={[rowAction]}
          options={tableOpt}
          localization={tableLocalization}
        />
      </Container>
      <IncomeDocumentViewModal
        src={incomeDocumentFile}
        open={!!incomeDocumentFile}
        onClose={toggleIncomeDocument}
      />
      <DialogComponent
        title={confirmationModalTitle}
        isOpen={!!acceptConfirmation}
        close={closeAcceptConfirmation}
        buttons={[
          {
            action: closeAcceptConfirmation,
            text: 'Cancelar',
            color: 'default',
          },
          {
            action: handleAcceptDeclaration,
            text: 'Aceptar',
            color: 'primary',
          },
        ]}
      />
      {renderBulkSelect && (
        <DialogComponent
          title={bulkModalTitle}
          isOpen={showBulkModal}
          close={closeBulkModal}
          buttons={[
            {
              action: closeBulkModal,
              text: 'Cancelar',
              color: 'default',
            },
            {
              action: handleBulkApproval,
              text: 'Aceptar',
              color: 'primary',
            },
          ]}
        />
      )}
    </>
  );
}

export default TableComponent;
