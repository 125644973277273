import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  createRef,
} from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { submitDeclaration } from '../components/GraduateDashboard/functions';
import {
  useGetDDJJContext,
  useGetUserInfo,
} from '../services/collections.service';
import {
  DeclarationStatus,
  DeclarationType,
  PaymentMethod,
  Role,
} from '../common/enums';
import {
  DeclarationInstance,
  Graduate,
  IDDJJContext,
} from '../common/interfaces';
import { useAuth } from './Auth';
import { useGeneralContext } from './GeneralContext';

const Context = createContext(null);

const pendingDeclarationStatuses = [
  DeclarationStatus.EMPTY,
  DeclarationStatus.DRAFT,
];

let selectedRoleRef: Role;

type JoyrideName = 'graduateDashboard';

export function GraduateContextProvider(props) {
  const { selectedRole } = useGeneralContext();

  useEffect(() => {
    selectedRoleRef = selectedRole;
  }, [selectedRole]);

  const { getAccessToken } = useAuth();

  const {
    data: DDJJContext,
    loading: loadingDDJJContext,
    error: errorDDJJContext,
  } = useGetDDJJContext();

  const tableRef: {
    current: {
      onQueryChange: () => void;
    };
  } = createRef();
  const refreshTable = useCallback(
    () => tableRef.current?.onQueryChange(),
    [tableRef]
  );

  const [declarations, setDeclarations] = useState<DeclarationInstance[]>([]);

  const [successAlert, setSuccessAlert] = useState<boolean>(false);

  const [hasPendingDeclarations, setHasPendingDeclarations] =
    useState<boolean>(null);

  const [runJoyride, setRunJoyride] = useState<{ [x in JoyrideName]: boolean }>(
    {
      graduateDashboard: false,
    }
  );

  const [
    hasPendingUnemploymentDeclarations,
    setHasPendingUnemploymentDeclarations,
  ] = useState<boolean>(null);

  useEffect(() => {
    if (DDJJContext && declarations.length) {
      const currentPeriodId = DDJJContext.period?.[0]?.value;
      const currentPeriodDeclarations = declarations.filter(
        (declaration) => declaration.period?.id === currentPeriodId
      );
      setHasPendingDeclarations(
        !currentPeriodDeclarations.length ||
          currentPeriodDeclarations.some((declaration) =>
            pendingDeclarationStatuses.includes(declaration.status)
          )
      );
      setHasPendingUnemploymentDeclarations(
        declarations.some(
          ({ type, deletable, status }) =>
            type === DeclarationType.UNEMPLOYMENT &&
            deletable &&
            [DeclarationStatus.DRAFT, DeclarationStatus.EMPTY].includes(status)
        )
      );
    }
  }, [declarations, DDJJContext]);

  const createNewDeclaration = useCallback(
    (status: DeclarationStatus, type: DeclarationType) => {
      let periodId = DDJJContext.period[0].value;
      periodId = typeof periodId === 'number' ? periodId : null;
      return submitDeclaration(
        {
          periodId,
        },
        status,
        type,
        getAccessToken
      );
    },
    [DDJJContext?.period, getAccessToken]
  );

  const createNewJobDeclaration = useCallback(() => {
    createNewDeclaration(
      DeclarationStatus.DRAFT,
      DeclarationType.NEW_EMPLOYMENT
    ).then(() => {
      refreshTable();
    });
  }, [refreshTable, createNewDeclaration]);

  const createUnemploymentDeclaration = useCallback(() => {
    createNewDeclaration(
      DeclarationStatus.DRAFT,
      DeclarationType.UNEMPLOYMENT
    ).then(() => {
      refreshTable();
    });
  }, [refreshTable, createNewDeclaration]);

  const handleRunJoyride = useCallback(
    ({ name, run }: { name: JoyrideName; run: boolean }) => {
      setRunJoyride(
        (prev) =>
          (Object.keys(prev) as JoyrideName[]).reduce(
            (acc, key) => ({ ...acc, [key]: run && key === name }),
            {}
          ) as typeof prev
      );
    },
    []
  );

  const {
    data: graduateData,
    loading: loadingGraduateData,
    error: errorGraduateData,
    refresh: refreshGraduateData,
  } = useGetUserInfo();

  const areMissingProfileData = graduateData
    ? (graduateData.residenceCountry === 'Argentina' && !graduateData.cuil) ||
      !graduateData.paymentMethod ||
      (graduateData.paymentMethod === PaymentMethod.Paypal &&
        !graduateData.paypalUsername) ||
      !graduateData.residenceCountry
    : null;

  const value = useMemo(() => {
    return {
      handleRunJoyride,
      runJoyride,
      refreshTable,
      setSuccessAlert,
      setDeclarations,
      setHasPendingDeclarations,
      createNewJobDeclaration,
      createUnemploymentDeclaration,
      tableRef,
      successAlert,
      declarations,
      hasPendingDeclarations,
      loadingDDJJContext,
      DDJJContext,
      errorDDJJContext,
      graduateData,
      loadingGraduateData,
      errorGraduateData,
      refreshGraduateData,
      areMissingProfileData,
      hasPendingUnemploymentDeclarations,
    };
  }, [
    handleRunJoyride,
    runJoyride,
    successAlert,
    tableRef,
    declarations,
    DDJJContext,
    hasPendingDeclarations,
    loadingDDJJContext,
    errorDDJJContext,
    createNewJobDeclaration,
    createUnemploymentDeclaration,
    refreshTable,
    refreshGraduateData,
    errorGraduateData,
    graduateData,
    loadingGraduateData,
    areMissingProfileData,
    hasPendingUnemploymentDeclarations,
  ]);

  return (
    <Context.Provider value={value} {...props}>
      {props.children}
    </Context.Provider>
  );
}

export function useGraduateContext(
  { ignoreWarnings }: { ignoreWarnings?: boolean } = { ignoreWarnings: false }
) {
  const context: {
    handleRunJoyride: (RunJoyrideInput: {
      name: JoyrideName;
      run: boolean;
    }) => void;
    runJoyride: { [x in JoyrideName]: boolean };
    refreshTable: () => void;
    tableRef: {
      current: {
        onQueryChange: () => void;
      };
    };
    setSuccessAlert: (open: boolean) => void;
    setHasPendingDeclarations: (hasPendingDeclarations: boolean) => void;
    setDeclarations: (declarations: DeclarationInstance[]) => void;
    createNewJobDeclaration: () => Promise<void>;
    hasPendingDeclarations: boolean;
    successAlert: boolean;
    declarations: DeclarationInstance[];
    loadingDDJJContext: boolean;
    DDJJContext: IDDJJContext;
    errorDDJJContext: AxiosError;
    graduateData: Graduate;
    loadingGraduateData: boolean;
    errorGraduateData: AxiosError;
    refreshGraduateData: () => Promise<AxiosResponse<Graduate>>;
    areMissingProfileData: boolean;
    createUnemploymentDeclaration: () => Promise<void>;
    hasPendingUnemploymentDeclarations: boolean;
  } = useContext(Context);
  if (!context) {
    throw new Error('useGraduateContext has to be inside a ContextProvider');
  }
  if (
    !!selectedRoleRef &&
    selectedRoleRef !== Role.graduate &&
    !ignoreWarnings
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'You are accessing to GraduateContext with non graduate selected role'
    );
  }
  return context;
}
