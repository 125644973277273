import React from 'react';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

function ListItem({
  text,
  onClick,
  Icon,
  SecondaryIcon,
}: {
  text: string;
  onClick: () => void;
  Icon: (props) => JSX.Element;
  SecondaryIcon?: (props) => JSX.Element;
}) {
  return (
    <MuiListItem onClick={onClick} disableGutters button>
      <ListItemIcon style={{ minWidth: 'auto', padding: '12px 8px' }}>
        <Icon style={{ fill: 'black' }} />
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
      {SecondaryIcon && (
        <ListItemSecondaryAction>
          <SecondaryIcon />
        </ListItemSecondaryAction>
      )}
    </MuiListItem>
  );
}

export default ListItem;
