import React from 'react';
import Box from '@material-ui/core/Box';
import { DeclarationType } from '../../common/enums';
import { DeclaredData } from '../../common/interfaces';
import FeedbackSection from '../FeedbackSection';
import Header from './Header';

function FinalConfirm({
  values,
}: {
  values: Omit<DeclaredData, 'date'> & {
    id: number;
    periods: { value: number; text: string }[];
    periodId: number;
    type: DeclarationType;
  };
}) {
  return (
    <Box px={1} width="100%">
      <Box mb={3}>
        <Header declarationType={values.type} />
      </Box>
      <Box mb={2} width="100%">
        <FeedbackSection
          corrections={null}
          onChange={null}
          declaration={{
            declaredData: values,
          }}
        />
      </Box>
    </Box>
  );
}

export default FinalConfirm;
