export enum DeclarationType {
  STAY_UNEMPLOYMENT = 'stayUnemployment',
  NEW_EMPLOYMENT = 'newEmployment',
  STAY_EMPLOYED = 'stayEmployed',
  STAY_EMPLOYED_INCOME_UNCHANGED = 'stayEmployedIncomeUnchanged',
  STAY_EMPLOYED_INCOME_CHANGED = 'stayEmployedIncomeChanged',
  UNEMPLOYMENT = 'unemployment',
  CHANGE_EMPLOYMENT_UNEMPLOYMENT = 'changeEmploymentUnemployment',
  CHANGE_EMPLOYMENT_NEW_EMPLOYMENT = 'changeEmploymentNewEmployment',
  EMPTY = 'empty',
  NOT_PRESENTED = 'notPresented',
}

export enum Role {
  backoffice = 'collections-admin',
  graduate = 'school-lead',
}

export enum IncomeDocumentTypes {
  ReciboDeSueldo = 'Recibo de sueldo',
  ContratoDeServiciosProfesionales = 'Contrato de servicios profesionales',
  FacturaDeServicios = 'Factura de servicios',
}

export enum JobStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TO_VALIDATE = 'toValidate',
}

export enum GraduateStatus {
  NOT_DECLARED = 'notDeclared',
  EMPLOYMENT = 'employment',
  MULTIEMPLOYMENT = 'multiemployment',
  UNEMPLOYMENT = 'unemployment',
  CHANGING_EMPLOYMENT = 'changingEmployment',
}

export const graduateStatusNames: { [x in GraduateStatus]: string } = {
  [GraduateStatus.NOT_DECLARED]: 'Sin declaraciones',
  [GraduateStatus.EMPLOYMENT]: 'Empleado',
  [GraduateStatus.MULTIEMPLOYMENT]: 'Multiempleado',
  [GraduateStatus.UNEMPLOYMENT]: 'Desempleado',
  [GraduateStatus.CHANGING_EMPLOYMENT]: 'Cambiando de empleo',
};

export const paymentMethodNames = {
  CreditCard: 'Tarjeta de crédito',
  DebitCard: 'Tarjeta de débito',
  BankAccount: 'Cuenta bancaria',
  Paypal: 'Paypal',
};

export enum PaymentMethod {
  CreditCard = 'CreditCard',
  DebitCard = 'DebitCard',
  BankAccount = 'BankAccount',
  Paypal = 'Paypal',
}

export enum JobModalities {
  EMPLEADO = 'empleado',
  FREELANCER = 'freelancer',
  PROVEEDOR = 'proveedor',
  OTRO = 'otro',
}

export enum DeclarationStatus {
  DRAFT = 'draft',
  EMPTY = 'empty',
  PRESENTED = 'presented',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  ACCEPTED_WITH_CORRECTIONS = 'acceptedWithCorrections',
  ACCEPTED_WITH_PENDINGS = 'acceptedWithPendings',
  IN_REVIEW = 'inReview',
  RESUBMITTED = 'resubmitted',
  NOT_PRESENTED = 'notPresented',
}
