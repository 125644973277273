import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { InputProps } from '@soyhenry/commons';
import Item from '../Item';

const Items = ({
  title,
  items,
  isEditing,
  onChangeData,
}: {
  title: string;
  onChangeData: InputProps['onChange'];
  isEditing: boolean;
  items: {
    title: string;
    value: string;
    edit?: {
      label: string;
      name: string;
      value: string | number;
      error: string;
      type: InputProps['type'];
      options?: InputProps['options'];
      disableNewValues?: boolean;
    };
    render?: boolean;
  }[];
}) => {
  return (
    <>
      <Typography style={{ fontFamily: 'Avenir Heavy' }} variant="h6">
        {title}:
      </Typography>
      {items.map(({ title, value, edit, render = true }, i) => {
        if (!render) {
          return null;
        }
        return (
          <Box key={title} mt={i === 0 ? 1 : 0.5}>
            <Item
              edit={
                edit && {
                  ...edit,
                  isEditing,
                  onChange: onChangeData,
                }
              }
              title={title}
              value={value}
            />
          </Box>
        );
      })}
    </>
  );
};

export default Items;
