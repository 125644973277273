import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';

interface StepperProps {
  step: number;
  totalSteps: number;
  onClose?: () => void;
}

export default function Stepper({ step, totalSteps, onClose }: StepperProps) {
  return (
    <>
      <LinearProgress
        variant="determinate"
        value={Math.round(((step + 1) * 100) / totalSteps)}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        width="100%"
      >
        {onClose && (
          <IconButton
            onClick={onClose}
            style={{ padding: '6px', margin: '12px' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}
